import Utils from '@/utils';
export type TopicLayoutConfType = {
  alias: string;
  name: string;
  icon: string;
  content: any;
};
export type TopicMapConfType = {
  [propName: string]: TopicLayoutConfType;
};

// export type TopicMapType = {
//   cate: string; //分类名
//   map: TopicMapConfType; //列举
// }[];
export const initLayoutConf = {
  alias: '',
  name: '',
  icon: '',
  content: {},
};
export const initTopicConf = {
  //默认的公共配置
  title: '',
  bg: '#ffffff',
  bgImg: '',
  color: '#000000',
  datas: [],
};

export const topicMap: TopicMapConfType = {
  //组件列表
  'op-image': {
    alias: '图片',
    name: 'op-image',
    icon: 'icon-image',
    content: {
      lrPad: 0,
      rects: [],
      img: '',
    },
  },
  'rich-text': {
    alias: '富文本',
    name: 'rich-text',
    icon: 'icon-doc',
    content: {
      bg: '#fff',
      padding: 0,
      text: '',
    },
  },
  'notice-bar': {
    alias: '通知栏',
    name: 'notice-bar',
    icon: 'icon-pinglun',
    content: {
      link: {},
      icon: 'volume-o',
      text: 'hello notice-bar',
      bg: '#fff',
      textColor: '#000',
      iconColor: '#000',
    },
  },
  space: {
    alias: '空白',
    name: 'space',
    icon: 'icon-zoomin',
    content: {
      bg: '',
      rects: [],
      height: 10,
    },
  },
  banner: {
    alias: '轮播图',
    name: 'banner',
    icon: 'icon-material',
    content: {
      bg: '#fff',
      list: [],
      loop: true,
      duration: 3000,
      vertical: false,
      numberType: false, //是否使用数字指示点
      indicatorColor: '#1989fa',
      radius: 0,
      lrPad: 0,
    },
  },
  'form-input': {
    alias: '输入框',
    name: 'form-input',
    icon: 'icon-edit',
    content: {
      required: false,
      label: '',
      type: 'text', //text,number,url,textarea,email,password,tel,digit(微信小程序可用)
      placeholder: '请填入信息',
      maxlength: 50,
      minlength: 1,
      min: '',
      max: '',
      formKey: Utils.randStr(5),
    },
  },
  'form-sms-code': {
    alias: '手机验证码',
    name: 'form-sms-code',
    icon: 'icon-xiaoxi',
    content: {
      required: false,
      btnText: '发送验证码',
      phoneId: '',
      label: '验证码',
      duration: 60,
      placeholder: '请填入验证码',
      formKey: Utils.randStr(5),
    },
  },
  'form-multi-check': {
    alias: '多选',
    name: 'form-multi-check',
    icon: 'icon-list',
    content: {
      min: 0,
      max: 0,
      required: false,
      label: '多选题',
      serial: 0, //是否在选项前面显示序号，0：无序号，1：字母, 2：数字
      formKey: Utils.randStr(5),
      checkList: [], //{ value: '',appendInput: false,inputText: '',}
    },
  },
  'form-radio': {
    alias: '单选',
    name: 'form-radio',
    icon: 'icon-correct',
    content: {
      required: false,
      label: '单选题',
      formKey: Utils.randStr(5),
      checkList: [], // { value: '',appendInput: false,inputText: '',}
    },
  },
  'form-picker': {
    alias: '下拉框',
    name: 'form-picker',
    icon: 'icon-more-a',
    content: {
      required: false,
      label: '下拉框',
      formKey: Utils.randStr(5),
      checkList: [], // { value: '',appendInput: false,inputText: '',}
    },
  },
  'form-submit': {
    alias: '提交按钮',
    name: 'form-submit',
    icon: 'icon-gou',
    content: {
      label: '提交',
      serial: 0, //是否在选项前面显示序号，0：无序号，1：字母, 2：数字
      dataKeys: '',
      successTip: '感谢您的参与',
      apiUrl: '',
    },
  },
  'form-date-time': {
    alias: '日期选择',
    name: 'form-date-time',
    icon: 'icon-time',
    content: {
      required: false,
      label: '日期选择',
      placeholder: '请选择',
      formKey: Utils.randStr(5),
      type: 'datetime', //	类型，可选值为datetime date time year-month
      maxDate: new Date().getTime(), // 可选的最大时间，精确到分钟
      minDate: new Date('2010-01-01').getTime(), // 可选的最小时间，精确到分钟
    },
  },
  'form-area': {
    alias: '省市区',
    name: 'form-area',
    icon: 'icon-location-fill',
    content: {
      required: false,
      label: '省市区',
      placeholder: '请选择',
      columnsNum: 3,
      formKey: Utils.randStr(5),
    },
  },
  'form-upload': {
    alias: '上传文件',
    name: 'form-upload',
    icon: 'icon-upload',
    content: {
      required: false,
      label: '上传图片',
      formKey: Utils.randStr(5),
      max: 9, // 最多九张图片
    },
  },
  'con-list': {
    alias: '内容列表',
    name: 'con-list',
    icon: 'icon-content',
    content: {
      bg: '',
      style: 1, // 1: 默认样式 2: 瀑布流样式
      topicIds: [],
    },
  },
  'photo-list': {
    alias: '图片列表',
    name: 'photo-list',
    icon: 'icon-mode',
    content: {
      bg: '',
      columnCount: 1, // 列数
      photos: [],
    },
  },
};
export const topicCateMap: { name: string; list: string[] }[] = [
  {
    name: '基础',
    list: [
      'op-image',
      'rich-text',
      'notice-bar',
      'space',
      'banner',
      'con-list',
      'photo-list',
    ],
  },
  {
    name: '表单',
    list: [
      'form-input',
      'form-sms-code',
      'form-multi-check',
      'form-radio',
      'form-submit',
      'form-date-time',
      'form-area',
      'form-picker',
      'form-upload',
    ],
  },
];
