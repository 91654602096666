export const enum StorageEnum { //本地存储的变量名字
  lang = 'ck-lang',
  global = 'ck-sys',
  dark = 'ck-dark',
  routeCache = 'ck-route-cache',
  leftMenuOpen = 'ck-left-menu-open',
  theme = 'ck-theme',
}
export const enum LangEnum { //国际化
  zhCn = 'zh-cn',
  en = 'en',
}
export const enum CookieEnum {
  userInfo = 'ck-user',
  token = 'ck-token',
}
export const enum AppEnum { //系统
  title = '切图喵',
}
export const UserRsaPubKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC4Jn/9/JweHVPq/4j+3o1KRG4a
0SPyJhVWa0yePam4niwV/GOqSnTMJ61cOvT8bPKrs7n/nhBaIgABWNeM1Fi0mBf/
2Ct1U0+qHZIZ637pOkorJ/oXebAUAVGXJAmIFogc0iowv3uTz8qIPAdgP4pQi97J
K9VNQ2xikoCmPGgTUwIDAQAB
-----END PUBLIC KEY-----`;
export const layoutStoreInject = Symbol();
export const userStoreInject = Symbol();
export const routeStoreInject = Symbol();
