import type { IDomEditor, IEditorConfig } from '@wangeditor/editor';
type InsertImageFnType = (url: string, alt: string, href: string) => void;
export interface NewIDomEditor extends IDomEditor {
  onOpenCodeSource(): void;
  onOpenMaterial(): void;
}
interface IButtonMenu {
  readonly title: string;
  readonly iconSvg?: string;
  readonly hotkey?: string;
  readonly alwaysEnable?: boolean;
  readonly tag: string;
  readonly width?: number;
  getValue: (editor: NewIDomEditor) => string | boolean;
  isActive: (editor: NewIDomEditor) => boolean;
  isDisabled: (editor: NewIDomEditor) => boolean;
  exec: (editor: NewIDomEditor, value: string | boolean) => void;
}
//定义自定义的编辑器菜单-查看源代码
class CodeSourceMenu implements IButtonMenu {
  title: string;
  tag: string;
  iconSvg: string;
  constructor() {
    this.title = '查看源代码'; // 自定义菜单标题
    this.iconSvg =
      '<svg t="1667807286852" class="icon" viewBox="0 0 1027 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3674" width="200" height="200"><path d="M152.32 213.12h725.76V128H152.32v85.12z m725.76 0a21.76 21.76 0 0 1 21.12 21.76h85.12A106.24 106.24 0 0 0 878.08 128v85.12z m21.12 21.76v554.24h85.12V234.88z m0 554.24a21.76 21.76 0 0 1-21.12 21.76V896a106.24 106.24 0 0 0 106.24-106.88z m-21.12 21.76H152.32V896h725.76v-85.12z m-725.76 0a21.76 21.76 0 0 1-21.12-21.76H46.08A106.24 106.24 0 0 0 152.32 896v-85.12z m-21.12-21.76V234.88H46.08v554.24z m0-554.24a21.76 21.76 0 0 1 21.12-21.76V128A106.24 106.24 0 0 0 46.08 234.88z" fill="#445365" p-id="3675" data-spm-anchor-id="a313x.7781069.0.i3" class="selected"></path><path d="M942.08 426.88v42.24h42.24v-42.24z m-853.76 0H46.08v42.24h42.24v-42.24z m42.88-192a21.76 21.76 0 0 1 21.12-21.76V128A106.24 106.24 0 0 0 46.08 234.88z m21.12-21.76h725.76V128H152.32v85.12z m725.76 0a21.76 21.76 0 0 1 21.12 21.76h85.12A106.24 106.24 0 0 0 878.08 128v85.12z m21.12 21.76v192h85.12v-192zM942.08 384H88.32v85.12h853.76V384z m-810.88 42.88v-192H46.08v192zM174.08 298.88A42.24 42.24 0 0 1 216.32 256a42.88 42.88 0 1 1 0 85.12 42.24 42.24 0 0 1-42.24-42.24zM302.08 298.88A42.24 42.24 0 0 1 344.32 256a42.88 42.88 0 1 1 0 85.12 42.24 42.24 0 0 1-42.24-42.24z" fill="#445365" p-id="3676"></path></svg>'; // 可选
    this.tag = 'button';
  }
  getValue(): string {
    return '';
  }
  isDisabled(): boolean {
    return false;
  }
  isActive(): boolean {
    return false;
  }
  exec(editor: NewIDomEditor) {
    if (this.isDisabled()) return;
    editor.onOpenCodeSource();
  }
}
//定义自定义的编辑器菜单-调用素材组件
class MaterialMenu implements IButtonMenu {
  title: string;
  tag: string;
  iconSvg: string;
  constructor() {
    this.title = '素材'; // 自定义菜单标题
    this.iconSvg =
      '<svg t="1667961193955" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4343" width="200" height="200"><path d="M975.238095 97.52381h-48.761905V48.761905a48.761905 48.761905 0 0 0-97.523809 0v48.761905h-48.761905a48.761905 48.761905 0 0 0 0 97.523809h48.761905v48.761905a48.761905 48.761905 0 0 0 97.523809 0V195.047619h48.761905a48.761905 48.761905 0 0 0 0-97.523809z" fill="" p-id="4344"></path><path d="M316.952381 414.47619m-85.333333 0a85.333333 85.333333 0 1 0 170.666666 0 85.333333 85.333333 0 1 0-170.666666 0Z" fill="" p-id="4345"></path><path d="M877.714286 414.47619a48.761905 48.761905 0 0 0-48.761905 48.761905v292.571429l-32.182857-33.158095L666.087619 585.142857l-7.314286-6.339047a102.4 102.4 0 0 0-138.483809 19.99238L430.08 707.047619l-48.761905-38.034286a83.870476 83.870476 0 0 0-115.078095-9.752381L97.52381 807.009524V243.809524a48.761905 48.761905 0 0 1 48.761904-48.761905h426.179048a48.761905 48.761905 0 0 0 0-97.523809H146.285714a146.285714 146.285714 0 0 0-146.285714 146.285714v633.904762a146.285714 146.285714 0 0 0 146.285714 146.285714h633.904762a146.285714 146.285714 0 0 0 146.285714-146.285714V463.238095a48.761905 48.761905 0 0 0-48.761904-48.761905z" fill="" p-id="4346"></path></svg>'; // 可选
    this.tag = 'button';
  }
  getValue(): string {
    return '';
  }
  isDisabled(): boolean {
    return false;
  }
  isActive(): boolean {
    return false;
  }
  exec(editor: NewIDomEditor) {
    if (this.isDisabled()) return;
    editor.onOpenMaterial();
  }
}
export const codeSourceConf = {
  key: 'codesource', // 定义 menu key ：要保证唯一、不重复（重要）
  factory: () => {
    return new CodeSourceMenu();
  },
};
export const materailConf = {
  key: 'materail', // 定义 menu key ：要保证唯一、不重复（重要）
  factory: () => {
    return new MaterialMenu();
  },
};
export const toolbarConfig = {
  insertKeys: {
    index: -2, // 插入的位置，基于当前的 toolbarKeys
    keys: ['materail', 'codesource'],
  },
  excludeKeys: ['fullScreen', 'uploadImage', 'uploadVideo'],
};
export const editorConfig: Partial<IEditorConfig> = {
  placeholder: '请输入内容...',
  MENU_CONF: {
    uploadImage: {},
  },
};
