<script lang="ts">
export default {
  meta: {
    path: '/stext',
    title: '您收到敏感信息',
    enTitle: 'Got sensitive test ',
    auth: false,
    cache: false,
  },
  name: 'stext',
};
</script>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import AesFn from '@/utils/aesFn';
import { useRoute } from 'vue-router';
import { getText } from '@/apis/sys';

const result = ref('');
let encryptText = '';
let keyIndex = '';
const tips = ref('');
const $route = useRoute();
if (!$route.query.t) {
  ElMessage.error('no params');
} else {
  const params = ($route.query.t as any)?.split('/');
  if (params.length > 1) {
    keyIndex = ($route.query.t as any)?.split('/')[0];
    encryptText = ($route.query.t as any)?.split('/')[1];
  }
}
const getResult = async () => {
  const res = await getText({ key: keyIndex });
  const decryptText = AesFn.decrypt(encryptText, res);
  if (!decryptText) {
    ElMessage.error('解码错误');
    result.value = '';
  } else {
    result.value = decryptText;
  }
  console.warn('res', res);
};
onMounted(async () => {
  tips.value = '';
  try {
    await getText({ key: keyIndex, check: true, _silent: true });
  } catch (error: any) {
    //console.warn('error', error,error.message);
    tips.value = error.message;
  }
});
</script>

<template>
  <ck-page class="page" footer header>
    <!-- <h2>安全地发送文本</h2> -->
    <h2 style="margin-bottom: 10px">
      {{ tips ? '错误' : '您收到了一条敏感信息' }}
    </h2>
    <p
      v-if="result"
      style="
        margin-bottom: 10px;
        border: 1px dashed #999;
        padding: 10px;
        border-radius: 10px;
      "
    >
      {{ result }}
    </p>
    <p
      v-if="tips"
      style="
        margin: 10px 0;
        padding: 10px;
        color: #eeeeee;
        border-radius: 10px;
        background-color: rgb(150, 150, 150);
      "
    >
      {{ tips }}
    </p>
    <el-button @click="getResult" type="primary" v-if="!result && !tips"
      >获取内容</el-button
    >
    <el-button type="success"
      ><RouterLink to="/send">重新生成内容</RouterLink></el-button
    >
  </ck-page>
</template>

<style lang="scss" scoped>
.page {
  max-width: 600px;
  margin: 0 auto;
}
</style>
