<template>
  <ck-page class="cate-page">
    <el-form :inline="true" @submit.prevent>
      <el-form-item label="关键字">
        <el-input @keyup.enter="() => search()" clearable v-model="keyword" />
      </el-form-item>
      <el-form-item label="分类">
        <el-select
          v-model="cateid"
          placeholder="请选择分类"
          @change="() => search()"
        >
          <el-option value="" label="不限"></el-option>
          <el-option
            v-for="item in cateList"
            :key="item.id"
            :value="item.id"
            :label="`${item.name}[${item.show ? 'show' : 'hide'}]`"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="平台">
        <el-select
          @change="() => search()"
          clearable
          v-model="plat"
          placeholder="请选择"
        >
          <el-option
            v-for="item in platList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-radio-group @change="() => search()" v-model="publishStatus">
          <el-radio :label="''">全部状态</el-radio>
          <el-radio :label="1">已发布</el-radio>
          <el-radio :label="0">未发布</el-radio>
        </el-radio-group>
      </el-form-item></el-form
    >
    <el-table center v-loading="loading" :data="list" style="width: 100%">
      <el-table-column label="id" prop="id" width="60px"></el-table-column>
      <!-- <el-table-column width="110px" label="标题">
        <template v-slot="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column> -->
      <el-table-column label="封面" width="100px">
        <template v-slot="scope">
          <el-image
            v-if="scope.row.cover"
            fit="contain"
            :preview-src-list="[scope.row.cover]"
            :src="scope.row.cover"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="内容" prop="content">
        <template v-slot="scope">
          <h3 style="margin-bottom: 10px; color: rgb(82, 148, 82)">
            {{ scope.row.title
            }}<span style="font-size: 12px; padding-left: 10px">{{
              Utils.dateFormat(scope.row.create_time)
            }}</span>
          </h3>
          <div v-html="scope.row.content" style="margin-bottom: 20px"></div>
          <el-button type="primary" @click="toPost(scope.row)">查看</el-button
          >&nbsp;&nbsp;<el-tag
            round
            :type="!!scope.row.publish ? 'success' : 'danger'"
            >{{ !!scope.row.publish ? '已发布到flarum' : '未发布' }}</el-tag
          >
          &nbsp;&nbsp;
          <el-select
            v-model="scope.row.cateid"
            placeholder="请选择分类"
            @change="(e) => updateCate(e, scope.row)"
          >
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :value="item.id"
              :label="`${item.name}[${item.show ? 'show' : 'hide'}]`"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
    <Post @success="refresh" ref="$postContent" />
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/crawler',
    title: '爬虫资源列表',
    enTitle: 'Crawler List',
    auth: false,
    cache: false,
  },
  name: 'crawler',
};
</script>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import CrawlerApi from '@/apis/crawler';
import ResourceApi from '@/apis/resource';
import Utils from '@/utils';
import Post from './components/Publish.vue';
import type { Ref } from 'vue';
import { ElMessage } from 'element-plus';

const $postContent = ref(null) as any;
const $pagination = ref(null) as any;
const loading = ref(false);
const list = ref([]);
const publishStatus = ref('') as any;
const keyword = ref('');
const cateid = ref('') as any;
const plat = ref('');
const cateList: Ref<{ show: boolean; id: number; name: string }[]> = ref([]);
const platList = [
  {
    label: '不限',
    value: '',
  },
  {
    label: '阿里云盘',
    value: 'alipan',
  },
  {
    label: '夸克云盘',
    value: 'quark',
  },
];
const getCate = async () => {
  const res = await ResourceApi.frontGetV2({ code: 'panso_cate' });
  cateList.value = res || [];
};
const updateCate = async (e: any, item: any) => {
  await CrawlerApi.updateCate({ id: item.id, cateid: e });
  ElMessage.success('修改成功');
  refresh();
};
const search = () => {
  $pagination.value.fetch(1);
};
const toPost = async (item: any) => {
  $postContent.value.init(item);
};
const refresh = () => {
  $pagination.value.fetch();
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res = await CrawlerApi.list({
    offset: (index - 1) * size,
    publish: publishStatus.value,
    keyword: keyword.value,
    plat: plat.value,
    cateid: cateid.value,
    limit: size,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
onMounted(() => {
  search();
  getCate();
});
</script>

<style scoped>
.btn {
  display: block;
  width: 50%;
  margin: 20px auto;
}
</style>
