import { promisefy } from '@/utils/req';
export default <any>{
  get(data = {}) {
    return promisefy(data, '/ajax/cate/get', 'get');
  },
  del(data = {}) {
    return promisefy(data, '/ajax/cate/del');
  },
  add(data = {}) {
    return promisefy(data, '/ajax/cate/add');
  },
  edit(data = {}) {
    return promisefy(data, '/ajax/cate/edit');
  },
};
//接口返回的数据字段类型
export type CateType = {
  name: string;
  weight?: number;
  id: number | string;
  updatetime?: number | string;
  createtime?: number | string;
};
