<script setup lang="ts">
import { computed } from 'vue';
export interface Props {
  size: number;
}
const props = withDefaults(defineProps<Props>(), {
  size: 200,
});
const scale = computed(() => props.size / 200);
const width = computed(() => props.size);
</script>

<template>
  <div
    class="logo-wrapper"
    :style="{
      paddingTop: `${scale * 20}px`,
      width: `${width}px `,
      height: `${props.size}px `,
    }"
  >
    <div
      class="logo"
      :style="{
        transform: `scale(${scale || 1})`,
      }"
    >
      <div class="head">
        <section class="l-ear ear"></section>
        <section class="r-ear ear"></section>
      </div>
      <div class="face">
        <div class="face-inner"></div>
        <div class="l-eye eye">Q</div>
        <div class="r-eye eye">T</div>
        <div class="nose-mouth">
          <div class="nose"></div>
          <div class="mouth">
            <div class="l-mouth"></div>
            <div class="r-mouth"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo-wrapper {
  overflow: hidden;
  padding-top: 20px;
}
.logo {
  width: 200px;
  height: 200px;
  --logo-border-width: 5px;
  --logo-main-color: #928a97;
  --logo-border-radius: 20px;
  --logo-main-bg: #928a97;
  --logo-senses-color: #fff;
  transform: scale(0.5);
  transform-origin: left top;
}
.head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}
.ear {
  position: relative;
  border-width: var(--logo-border-width) var(--logo-border-width) 0 0;
  border-color: var(--logo-main-color);
  background-color: var(--logo-main-bg);
  border-style: solid;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 0 5px 0 0;
}
.l-ear {
  transform: scaleY(1.3) rotate(-45deg);
}
.r-ear {
  transform: scaleY(1.3) rotate(-45deg);
}
.face {
  position: relative;
  top: -33px;
  border-radius: 80px;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 8px var(--logo-main-color) solid;
  border-top-width: 8px;
  border-left-width: 5px;
  border-right-width: 5px;
  background-color: var(--logo-main-bg);
  height: 150px;
  &-inner {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 10;
    background-color: var(--logo-main-bg);
    height: 100px;
  }
}
.eye {
  z-index: 12;
  position: absolute;
  width: 15px;
  top: 20%;
  font-size: 0;
  height: 15px;
  border-radius: 100%;
  background-color: var(--logo-senses-color);
}
.nose-mouth {
  z-index: 12;
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
}
.mouth {
  display: flex;
  align-items: center;
  transform: scaleY(1) scaleX(1.2);
}
.l-mouth {
  position: relative;
  right: -2px;
}
.r-mouth {
  left: -2px;
}
.l-mouth,
.r-mouth {
  width: 20px;
  height: 30px;
  border-radius: 80%;
  border-width: 0 0 5px 0;
  border-style: solid;
  border-color: var(--logo-senses-color);
}
.nose {
  display: none;
  margin: 0 auto;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--logo-senses-color);
}
.l-eye {
  left: 35%;
}
.r-eye {
  right: 35%;
}
</style>
