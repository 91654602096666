import { promisefy } from '@/utils/req';
export default <any>{
  list(data = {}) {
    return promisefy(data, '/ajax/crawler/list', 'get');
  },
  publish(data = {}) {
    return promisefy(data, '/ajax/crawler/publish');
  },
  updateCate(data = {}) {
    ///ajax/crawler/updateCate
    return promisefy(data, '/ajax/crawler/updateCate');
  },
};
//接口返回的数据字段类型
export type CrawlerType = {
  title: string;
  content: string;
  share_url: string;
  desc?: string;
  cover?: string;
  id: number | string;
  publish: number;
  cateid?: number | null;
  create_time?: number | string;
};
export type CrawlerPostType = {
  title: string;
  content: string;
  id: number | string;
  publish: number;
};
