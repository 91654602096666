<template>
  <div
    @mouseover="onMoveChange"
    @mouseleave="onMoveChange"
    class="view-panel"
    :class="{ 'is-enter': enter }"
  >
    <div class="view-masker" v-if="props.dragItem"></div>
    <!--直接在iframe上拖动会卡，放个view-masker-->
    <iframe
      id="topicViewFrame"
      class="iframe"
      :disabled="props.dragItem ? true : false"
      :src="url"
    ></iframe>
  </div>
</template>
<script lang="ts" setup>
import {
  ref,
  type Ref,
  onMounted,
  onUnmounted,
  watch,
  getCurrentInstance,
} from 'vue';
import Utils from '@/utils';
import { useTopic } from '../service';
import { initLayoutConf } from '../conf';

export interface Props {
  selectIndex: number;
  cellPos: any;
  dragPos: object;
  clientX: number;
  clientY: number;
  itemList: Array<any>;
  dragItem: any;
}

const { Bridge } = useTopic();
let elObserver: ResizeObserver | null = null;
const emit = defineEmits(['getTopicData', 'enterChange']);
const ctx: any = getCurrentInstance()?.proxy;
const url: Ref<string> = ref('');
const enter: Ref<boolean> = ref(false);
let nowCellRect: { x: number; y: number; w: number; h: number } = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
};
const props = withDefaults(defineProps<Props>(), {
  selectIndex: 0,
  clientY: 0,
  clientX: 0,
  dragItem: () => null,
  itemList: () => [],
  item: Utils.clone(initLayoutConf),
});
watch(
  () => props.clientY,
  (y) => {
    if (!Bridge.hasInit) {
      return;
    }
    if (!y) {
      onDragOut();
      return;
    }
    //监听y的变化，判断是否触发是item插入事件
    Bridge.post({ key: 'clientY', value: y - 70 });
    if (
      enter.value &&
      props.dragItem &&
      Utils.onRange(
        y,
        nowCellRect.y - props.cellPos.y,
        nowCellRect.y - props.cellPos.y + nowCellRect.h
      )
    ) {
      Bridge.post({ key: 'eventType', value: 'insert' });
    } else {
      onDragOut();
    }
  }
);
const initData = () => {
  const rect = ctx.$el.getBoundingClientRect();
  nowCellRect = {
    x: rect.x,
    y: rect.y,
    w: rect.width,
    h: rect.height,
  };
  Bridge.post({ key: 'nowCellRect', value: Utils.clone2(nowCellRect) });
};
const onDragOut = () => {
  Bridge.post({
    name: 'topic',
    arrays: [
      { key: 'clientY', value: 0 },
      { key: 'eventType', value: null },
    ],
  });
};
const onMoveChange = (e: MouseEvent) => {
  enter.value = e.type === 'mouseleave' ? false : !!props.dragItem;
  if (!enter.value) {
    onDragOut();
  }
  emit('enterChange', enter.value);
};
onUnmounted(() => {
  elObserver?.disconnect();
});
onMounted(async () => {
  url.value = '';
  Bridge.hasInit = false;
  await Utils.sleep(0.5); //先清空iframe的内容，再赋值，不然postmessage会偶尔失败
  elObserver = new ResizeObserver(() => {
    initData();
  });
  elObserver.observe(document.querySelector('.ck-container') as any);
  url.value = import.meta.env.VITE_APP_TOPIC_IFRAME + Date.now();
  await Bridge.init();
  emit('getTopicData');
  initData();
});
</script>
<style lang="scss" scoped>
.view-panel {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  width: 100%;
  height: 620px;
  box-sizing: border-box;
}
.transition-item {
  animation: opacityChange 0.5s;
}
.view-masker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}
.iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
@keyframes opacityChange {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
</style>
