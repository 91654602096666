<script lang="ts">
export default {
  meta: {
    path: '/send',
    title: '安全的发送文本',
    enTitle: 'Send text securely',
    auth: false,
    cache: false,
  },
  name: 'send',
};
</script>
<script lang="ts" setup>
import { ref } from 'vue';
import AesFn from '@/utils/aesFn';
import { sendText } from '@/apis/sys';
const text = ref('');
const outputUrl = ref('');
const getLink = async () => {
  if (text.value) {
    const res = await sendText();
    const encryptText = AesFn.encrypt(text.value, res.val);
    // const decryptText = AesFn.decrypt(encryptText, res.val);
    text.value = '';
    outputUrl.value = `${location.origin}/stext?t=${res.key}/${encryptText}`;
  }
};
</script>

<template>
  <ck-page class="page" footer header>
    <!-- <h2>安全地发送文本</h2> -->
    <div class="t-tips">
      📢：本站不保存用户输入的内容，只生成解密钥，内容无法得知，链接请用户自行保存，丢失不可恢复
    </div>
    <h2>您可以安全地共享信息</h2>
    <el-form label-position="top" class="form">
      <el-form-item label="你要发送的信息，最多100长度">
        <el-input
          type="textarea"
          maxlength="100"
          :rows="5"
          v-model="text"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button @click="getLink" :disabled="!text" type="primary"
      >获取链接</el-button
    >
    <template v-if="outputUrl">
      <p
        style="margin-top: 10px; text-decoration: underline; font-weight: bold"
      >
        分享这条链接(可点击链接复制)，此链接在接下来的10分钟内或阅读之前有效：
      </p>
      <p class="t-pointer link" title="点击复制链接" v-copy="outputUrl">
        {{ outputUrl }}
      </p>
      <!-- <div class="t-tips">此链接在接下来的10分钟内或阅读之前有效</div> -->
    </template>
  </ck-page>
</template>

<style lang="scss" scoped>
.page {
  max-width: 600px;
  margin: 0 auto;
}
.link {
  padding: 10px 0;
  word-break: break-all;
}
</style>
