<template>
  <ck-page>
    <el-form @submit.prevent inline>
      <el-form-item label="兑换码">
        <el-input v-model="generateNum" placeholder="请输入数量"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="generateMore"
          >批量生成兑换码</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="list" stripe>
      <el-table-column prop="id" label="ID" width="70" />
      <el-table-column prop="nick_name" label="状态">
        <template v-slot="{ row }">
          {{ statusStr(row) }}
        </template>
      </el-table-column>
      <el-table-column prop="code" label="兑换码">
        <template v-slot="{ row }">
          {{ row.code || '-' }}
          <ck-icon
            color="#1890FF"
            class="t-pointer icon-publish"
            v-copy="row.code"
          ></ck-icon>
        </template>
      </el-table-column>
      <el-table-column prop="api_key" width="230" label="指令码">
        <template v-slot="{ row }">
          {{ row.api_key || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="uid" label="兑换uid" />
      <el-table-column prop="nick_name" label="创建时间">
        <template v-slot="scope">
          {{ dayjs(scope.row.createdAt).format('YYYY年MM月DD日 HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="nick_name" label="更新时间">
        <template v-slot="scope">
          {{ dayjs(scope.row.updatedAt).format('YYYY年MM月DD日 HH:mm:ss') }}
        </template>
      </el-table-column>
    </el-table>
    <CkPagination ref="$pagination" :api-handler="fetchList" />
  </ck-page>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import dayjs from 'dayjs';
import JzApi from '@/apis/jz';

defineOptions({
  name: 'JzCodes',
  meta: {
    title: '记账兑换码列表',
    enTitle: 'Jz Codes',
    path: '/jz/code',
    auth: true,
  },
});
const $pagination = ref(null) as any;
const list = ref([]);
const generateNum = ref('');
const loading = ref(false);
const statusStr = (row) => {
  return {
    1: '未兑换',
    2: '已兑换',
    3: '已失效',
  }[row.status];
};
const generateMore = async () => {
  if (generateNum.value) {
    const num = Number(generateNum.value);
    if (isNaN(num)) {
      ElMessage.error('请输入数字');
      return;
    }
    if (num < 1 || num > 100) {
      ElMessage.error('请输入1-100之间的数字');
      return;
    }
  }
  await JzApi.generateCode({
    num: generateNum.value,
  });
  ElMessage.success('生成成功');
  $pagination.value.fetch(1);
  generateNum.value = '';
};
const fetchList = async (index = 0, size = 0) => {
  loading.value = true;
  const res = await JzApi.getCodeList({
    offset: (index - 1) * size,
    limit: size,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const search = () => {
  $pagination.value.fetch(1);
};
const toIpInfo = (ip: string) => {
  window.open(`https://ipinfo.io/${ip}`);
};
onMounted(() => {
  search();
});
</script>
