import { promisefy } from '@/utils/req';
export default <any>{
  get(data = {}) {
    return promisefy(data, '/ajax/articles/get', 'get');
  },
  getAbout(data = {}) {
    return promisefy(data, '/ajax/getAbout');
  },
  del(data = {}) {
    return promisefy(data, '/ajax/articles/del');
  },
  add(data = {}) {
    return promisefy(data, '/ajax/articles/add');
  },
  edit(data = {}) {
    return promisefy(data, '/ajax/articles/update');
  },
  count(data = {}) {
    return promisefy(data, '/ajax/getArticleCount');
  },
  list(data = {}) {
    return promisefy(data, '/ajax/articles/list');
  },
  frontList(data = {}) {
    return promisefy(data, '/front/articles/list', 'get');
  },
  frontGet(data = {}) {
    return promisefy(data, '/front/articles/get', 'get');
  },
  frontPageview(data = {}) {
    return promisefy(data, '/front/articles/pageview', 'get');
  },
};
//接口返回的数据字段类型
export type ArticleType = {
  catename: string;
  content: string;
  cateid: string | number;
  pageview?: number;
  weight?: number;
  title: string;
  view?: number;
  id: number | string;
  updatetime?: number | string;
  createtime?: number | string;
  faceimg: string;
  subcon: string;
};
