<template>
  <ck-page>
    <div class="t-space-20"></div>
    <!-- <el-button round type="primary" @click="() => openEdit()"
      >新增用户</el-button
    > -->
    <div class="t-space-20"></div>
    <el-table v-loading="loading" :data="list" border>
      <el-table-column label="id" prop="id" width="70px"></el-table-column>
      <el-table-column prop="name" label="昵称" width="120px"></el-table-column>
      <el-table-column
        prop="account"
        label="账号"
        width="220px"
      ></el-table-column>
      <el-table-column label="头像" width="100px">
        <template v-slot="scope">
          <div class="user-cell">
            <el-avatar
              fit="cover"
              size="large"
              :src="scope.row.avatar"
              class="avatar"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="个性背景" width="200px">
        <template v-slot="scope">
          <img
            v-if="scope.row.bg"
            style="width: 150px"
            :src="getQnImg(scope.row.bg, ['thumbnail/200x'])"
          />
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column
        label="权限值"
        prop="type"
        width="70px"
      ></el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          {{ dateFormat(scope.row.createdtime) }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template v-slot="scope">
          {{ dateFormat(scope.row.updatetime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button
            type="primary"
            @click="
              () => {
                openEdit(Utils.clone(scope.row));
              }
            "
            size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
    <el-dialog
      :width="layoutStore.isMiniScreen ? '100%' : ''"
      :title="isEdit ? '用户信息' : '添加用户'"
      v-model="editVisible"
    >
      <Edit @submit="search" :item="editItem" />
    </el-dialog>
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/usermanage',
    title: '用户管理',
    enTitle: 'User Manage',
    auth: true,
    cache: true,
  },
  name: 'userManage',
};
</script>
<script lang="ts" setup>
import { ref, onMounted, inject, computed, type Ref } from 'vue';
import { layoutStoreInject } from '@/contants';
import UserApi from '@/apis/user';
import { initUserItem, type UserType } from '@/apis/user';
import Utils from '@/utils';
import Edit from './components/Edit.vue';

const layoutStore = inject(layoutStoreInject) as any;
const { dateFormat, getQnImg } = Utils;
const editVisible = ref(false);
const loading = ref(false);
const list = ref([]);

const editItem: Ref<UserType> = ref(Utils.clone(initUserItem));
const isEdit = computed(() => {
  return !!editItem.value.id;
});
const $pagination = ref(null) as any;
const search = () => {
  editVisible.value = false;
  $pagination.value.fetch(1);
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res: any = await UserApi.list({
    offset: (index - 1) * size,
    limit: size,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const openEdit = (item: UserType = Utils.clone(initUserItem)) => {
  editItem.value = item;
  editVisible.value = true;
};
onMounted(() => {
  search();
});
</script>
<style lang="scss" scoped>
.audio {
  margin-right: 10px;
  height: 35px;
}
</style>
