import { promisefy } from '@/utils/req';
export default <any>{
  get(data = {}) {
    return promisefy(data, '/ajax/material/get', 'get');
  },
  delete(data = {}) {
    return promisefy(data, '/ajax/material/delete');
  },
  add(data = {}) {
    return promisefy(data, '/ajax/material/add');
  },
  update(data: { id: string | number; name: string }) {
    return promisefy(data, '/ajax/material/update');
  },
  getAcgList(data = {}) {
    return promisefy(data, '/ajax/acg/getlist', 'get');
  },
};
export type MaterialType = {
  id: string | number;
  link: string;
  createtime?: string | number;
  name?: string;
  size?: string;
  type?: number;
};
