<template>
  <section class="chat-box">
    <div class="list t-flex t-pointer"></div>
    <section class="chat-form">
      <el-input
        :rows="3"
        :autosize="{ minRows: 3, maxRows: 8 }"
        maxlength="200"
        type="textarea"
        v-model="question"
        placeholder="输入你想提问的，按enter发送"
        autocomplete="off"
        clearable
      >
      </el-input>
      <el-button link>提交</el-button>
    </section>
  </section>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import type { Ref } from 'vue';
import { chatCreate } from '@/apis/sys';

const question = ref('');
const list = ref([]);
const cnf: Ref<{ remain: number; id?: ''; code?: '' }> = ref({ remain: 0 });
</script>

<style lang="scss" scoped>
.list {
  flex-direction: column;
}
.chat-box {
  height: 100%;
}
.chat-form {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: flex-end;
}
</style>
