<template>
  <ck-page>
    <div class="t-space-20"></div>
    <el-form :inline="true" :model="params" @submit.prevent>
      <el-form-item label="关键字">
        <el-input @keyup.enter="search" v-model="params.content" clearable />
      </el-form-item>
      <el-form-item>
        <el-button round type="success" @click="search">查询</el-button>
        <el-button
          round
          type="primary"
          @click="
            () => {
              editVisible = true;
            }
          "
          >添加</el-button
        >
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="list" border>
      <el-table-column label="id" prop="id" width="50px"></el-table-column>
      <el-table-column
        label="标题"
        prop="content"
        width="200px"
      ></el-table-column>
      <el-table-column label="音频" width="370px">
        <template v-slot="scope">
          <div class="t-flex t-flex-center">
            <audio class="audio" :src="scope.row.url" controls />
            <ck-icon
              class="icon-publish t-point"
              v-copy="scope.row.url"
              title="复制音频链接"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          {{ dateFormat(scope.row.createdtime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-popconfirm
            v-if="scope.row.id > 0"
            @confirm="toDel(scope.row)"
            title="确定删除?"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
    <el-dialog
      :width="layoutStore.isMiniScreen ? '100%' : ''"
      title="添加"
      v-model="editVisible"
    >
      <el-input
        v-model="editText"
        placeholder="输入你想转为语音的文本"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        :rows="2"
      ></el-input>
      <div class="t-flex" style="margin-top: 15px; justify-content: flex-end">
        <el-button @click="addItem" round type="primary">提交</el-button>
      </div>
    </el-dialog>
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/textaudios',
    title: '文本转为语音',
    enTitle: 'Text Audios',
    auth: true,
    cache: false,
  },
  name: 'textAudios',
};
</script>
<script lang="ts" setup>
import { ref, onMounted, inject } from 'vue';
import { ElMessage } from 'element-plus';
import { layoutStoreInject } from '@/contants';
import { textToAudioLog, textToAudioRemove, textToAudio } from '@/apis/sys';
import Utils from '@/utils';
const layoutStore = inject(layoutStoreInject) as any;
const { dateFormat } = Utils;
const params = ref({
  content: '',
});
const editVisible = ref(false);
const editText = ref('');
const loading = ref(false);
const list = ref([]);
const $pagination = ref(null) as any;
const search = () => {
  $pagination.value.fetch(1);
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res: any = await textToAudioLog({
    offset: (index - 1) * size,
    limit: size,
    content: params.value.content,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const toDel = async (item: any) => {
  await textToAudioRemove({ id: item.id });
  ElMessage.success('删除成功');
  $pagination.value.fetch();
};
const addItem = async () => {
  await textToAudio({
    text: editText.value,
  });
  editText.value = '';
  ElMessage.success('添加成功');
  search();
};
onMounted(() => {
  search();
});
</script>
<style lang="scss" scoped>
.audio {
  margin-right: 10px;
  height: 35px;
}
</style>
