<template>
  <ck-page>
    <Edit mine @submit="updateCb" :item="myConf" />
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/my',
    title: '我的',
    enTitle: 'My Config',
    auth: true,
    cache: true,
  },
  name: 'my',
};
</script>
<script lang="ts" setup>
import { ref, onMounted, inject, computed, type Ref } from 'vue';
import Utils from '@/utils';
import Edit from './components/Edit.vue';
import { initUserItem, type UserType } from '@/apis/user';
import { userStoreInject } from '@/contants';
const userStore = inject(userStoreInject) as any;
const myConf: Ref<UserType> = ref(Utils.clone(initUserItem));
onMounted(async () => {
  myConf.value = await userStore.value.fetchUser();
});
const updateCb = () => {
  userStore.value.fetchUser();
};
</script>
