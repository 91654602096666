<template>
  <el-dialog width="350" title="查看链接" v-model="visible">
    <p style="text-align: center"><el-image :src="imgUrl"></el-image></p>
    <p style="text-align: center">
      <a target="_blank" :href="link">{{ link }}</a>
    </p>
  </el-dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import QRCode from 'qrcode';

const link = ref('');
const imgUrl = ref('');
const visible = ref(false);
const open = (url: string) => {
  link.value = url;
  visible.value = !visible.value;
  QRCode.toDataURL(url)
    .then((img) => {
      imgUrl.value = img;
    })
    .catch((err) => {
      console.error(err);
    });
};
defineExpose({ open });
</script>
<style lang="scss" scoped></style>
