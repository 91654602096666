import { promisefy } from '@/utils/req';
export default <any>{
  list(data = {}) {
    return promisefy(data, '/ajax/comment/list');
  },
  del(data = {}) {
    return promisefy(data, '/ajax/comment/del');
  },
};
//接口返回的数据字段类型
export type CommentType = {
  aid: number;
  id: number | string;
  atitle?: number;
  content: string;
  user: string;
  userObj?: object | null;
  avatar?: string;
  createtime?: number | string;
};
