<script setup lang="ts">
import { ref } from 'vue';
import { ftnnPost } from '@/apis/sys';
const visible = ref(false);
const title = ref('文章');
const id = ref(0) as any;
const detail = ref({
  text: '',
});
const fetchData = async () => {
  const res = await ftnnPost({
    postid: id.value,
  });
  res.text = res.text.replaceAll('data-src=', 'src=');
  detail.value = res;
};
const init = async (item: any) => {
  visible.value = true;
  if (item.id !== id.value) {
    id.value = item.postid;
    title.value = item.title;
    fetchData();
  }
};
defineExpose({
  init,
});
</script>

<template>
  <el-dialog width="70%" :title="title" v-model="visible">
    <div v-if="detail">
      <!-- <p class="t">
        {{ detail.author }}
      </p>
      <p v-if="detail.subdesc" style="margin-bottom: 10px">
        简介：{{ detail.subdesc }}
      </p> -->
      <div v-if="!detail.text" class="tips">空无内容</div>
      <div v-else class="html-content" v-html="detail.text"></div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.t {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    padding: 0 20px 0 0;
    color: #999;
  }
}
.tips {
  color: #999;
  text-align: center;
  padding: 10px;
}
.cover {
  max-width: 200px;
  margin: 10px auto;
}
</style>
