<template>
  <div class="ck-aside" :class="{ fold: !layoutStore.leftMenuOpen }">
    <ck-icon
      class="icon-fold phone-fold t-active-feedback"
      size="20"
      v-if="layoutStore.leftMenuOpen"
      @click="layoutStore.switchLeftMenu"
    />
    <div class="ck-logo">
      <Logo :size="50" />
      <div class="ck-logo-text" v-if="layoutStore.leftMenuOpen">
        {{ VITE_APP_TITLE }}
      </div>
    </div>
    <div class="ck-aside-inner" v-loading="loading">
      <el-menu
        class="ck-aside-menu"
        :collapse="!layoutStore.leftMenuOpen"
        :router="true"
        :collapse-transition="false"
        :default-active="layoutStore.asideMenuActive"
      >
        <AsideMenuItem :menus="layoutStore.asideMenu" />
      </el-menu>
      <el-empty
        :image-size="layoutStore.leftMenuOpen ? 100 : 50"
        v-if="layoutStore.asideMenu.length === 0"
        description="无菜单"
      />
      <section class="login-btn" v-if="!userStore.isLogin">
        <el-button @click="goLogin" link size="large" type="success">{{
          layoutStore.leftMenuOpen ? '登录，发现更多精彩' : '登录'
        }}</el-button>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue';
import { layoutStoreInject, userStoreInject } from '@/contants';
import { getDataByCode } from '@/apis/sys';
import AsideMenuItem from './AsideMenuItem.vue';
import Utils from '@/utils';
export default defineComponent({
  components: { AsideMenuItem },
  setup() {
    return {
      VITE_APP_TITLE: import.meta.env.VITE_APP_TITLE,
      loading: ref(false),
      layoutStore: inject(layoutStoreInject) as any,
      userStore: inject(userStoreInject) as any,
    };
  },
  mounted(): void {
    // this.fetchData();
  },
  methods: {
    // toggleMenu() {
    //   this.layoutStore.switchLeftMenu();
    // },
    goLogin() {
      Utils.goLogin();
    },
    async fetchData() {
      this.loading = true;
      const res = await getDataByCode({ code: 'clay-menu' }).finally(() => {
        this.loading = false;
      });
      this.layoutStore.setAsideMenu(JSON.parse(res.data));
    },
  },
});
</script>

<style lang="scss">
.el-menu--collapse {
  width: 100% !important;
}
.ck {
  &-logo {
    display: flex;
    align-items: center;
    position: relative;
    width: var(--ck-logo-width);
    height: var(--ck-logo-height);
    margin: 0 auto;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, 微软雅黑, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    &-text {
      margin-left: 10px;
    }
  }
  &-aside {
    --el-menu-bg-color: transparent;
    --el-menu-border-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding: 10px 0;
    overflow-y: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 210;
    width: var(--ck-header-left-width);
    background-color: var(--ck-aside-bg-color, #fff);
    flex-direction: column;
    .phone-fold {
      display: none;
    }
    &-menu {
      width: 100%;
    }
    &-inner {
      padding-top: 10px;
      width: 100%;
      overflow-x: hidden;
    }
    .login-btn {
      text-align: center;
    }
    &.fold {
      width: var(--ck-header-left-width-fold);
      .ck-logo {
        // background-image: var(--ck-logo-bg-fold, transparent);
        width: var(--ck-logo-width-fold);
        height: var(--ck-logo-height-fold);
      }
    }
  }
  &-aside-inner {
    height: calc(100% - var(--ck-logo-height));
    overflow-y: auto;
  }
}
@media (max-width: 500px) {
  .ck {
    &-aside {
      &.fold {
        width: 0px;
        overflow-x: hidden;
      }
      .phone-fold {
        display: block;
        position: absolute;
        top: var(--ck-logo-height);
        transform: translateY(-100%);
        right: 10px;
      }
    }
    &-aside-inner {
      height: calc(100% - var(--ck-logo-height-fold));
    }
  }
}
</style>
