<template>
  <ck-page>
    <el-table width="200" v-loading="loading" :data="roleList" border>
      <el-table-column label="id" prop="id" width="70px"></el-table-column>
      <el-table-column
        prop="intro"
        label="说明"
        width="120px"
      ></el-table-column>
      <el-table-column
        label="权限值"
        prop="type"
        width="70px"
      ></el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button
            type="primary"
            @click="
              () => {
                openEdit(Utils.clone(scope.row));
              }
            "
            size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :width="layoutStore.isMiniScreen ? '100%' : ''"
      title="角色信息"
      v-model="editVisible"
    >
      <RoleEdit @submit="getRoleList" :item="editItem" />
    </el-dialog>
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/rolemanage',
    title: '角色管理',
    enTitle: 'Role Manage',
    auth: true,
    cache: true,
  },
  name: 'rolemanage',
};
</script>
<script lang="ts" setup>
import { ref, inject } from 'vue';
import RoleApi, { initRoleItem } from '@/apis/role';
import type { RoleType } from '@/apis/role';
import Utils from '@/utils';
import { layoutStoreInject } from '@/contants';
import RoleEdit from './components/RoleEdit.vue';

const layoutStore = inject(layoutStoreInject) as any;
const roleList = ref<RoleType[]>([]);
const loading = ref(false);
const editVisible = ref(false);
const editItem = ref<RoleType>(initRoleItem);
/**
 * 获取角色列表
 */
async function getRoleList() {
  loading.value = true;
  editVisible.value = false;
  const res = await RoleApi.list();
  loading.value = false;
  roleList.value = res;
}
function openEdit(item: RoleType) {
  editItem.value = item;
  editVisible.value = true;
}

getRoleList();
</script>
