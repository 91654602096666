<template>
  <ck-page class="posts-page">
    <el-table v-loading="loading" :data="list" border>
      <el-table-column label="id" prop="id" width="70px"></el-table-column>
      <el-table-column label="文章" width="200px">
        <template v-slot="scope">
          <div
            @click="toPost(scope.row.aid)"
            style="cursor: pointer; color: #5a9cf8"
          >
            {{ decodeURIComponent(scope.row.atitle) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评论人信息" width="200px">
        <template v-slot="scope">
          <div class="user-cell">
            <img :src="scope.row.userObj.avatar" class="avatar" />
            <span class="nick">{{ scope.row.userObj.username }}</span>
          </div>
          <div class="user-cell" v-if="scope.row.userObj.email !== ''">
            <span class="email"
              ><b>email:</b>{{ scope.row.userObj.email }}</span
            >
          </div>
          <div class="user-cell" v-if="scope.row.userObj.blog !== ''">
            <span class="blog"
              ><b>blog:</b
              >{{ scope.row.userObj.blogproto + scope.row.userObj.blog }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评论" width="300px">
        <template v-slot="scope">
          {{ decodeURIComponent(scope.row.content) }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="200px">
        <template v-slot="scope">
          {{ Utils.dateFormat(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260px">
        <template v-slot="scope">
          <el-popconfirm
            v-if="scope.row.id > 0"
            @confirm="toDel(scope.row)"
            title="此操作将永久删除该评论?"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="200px">
        <template v-slot="scope">
          {{ dateFormat(scope.row.createtime) }}
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
  </ck-page>
</template>

<script lang="ts">
export default {
  meta: {
    path: '/comment',
    title: '评论列表',
    enTitle: 'Comment',
    auth: true,
    cache: false,
  },
  name: 'comment',
};
</script>
<script lang="ts" setup>
import { ref, onMounted, inject, watch } from 'vue';
import CommentApi from '@/apis/comment';
import type { CommentType } from '@/apis/comment';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
import { routeStoreInject } from '@/contants';
import Utils from '@/utils';
const { dateFormat } = Utils;
const $pagination = ref(null) as any;
const loading = ref(false);
const list = ref([]);
const $route = useRoute();
const routeStore = inject(routeStoreInject) as any;
const search = () => {
  $pagination.value.fetch(1);
};
const toPost = (id: number | string) => {
  Utils.goPage(`/editpost?id=${id}`);
};
const toDel = (item: CommentType) => {
  CommentApi.del({ id: item.id }).then(() => {
    ElMessage.success('删除成功');
    $pagination.value.fetch();
  });
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res = await CommentApi.list({
    offset: (index - 1) * size,
    limit: size,
    articleid: $route.query.postid || '-1',
  });
  res.list.map((item: CommentType) => {
    item.userObj = JSON.parse(decodeURIComponent(item.user));
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
watch(
  () => $route.query,
  () => {
    search();
  }
);
onMounted(() => {
  search();
  if ($route.query.postid) {
    routeStore.value.updateCache({
      title: `评论-:${$route.query.title || $route.query.postid}`,
    });
  }
});
</script>
<style lang="scss" scoped>
.btn {
  margin: 10px auto;
  display: block;
  width: 100%;
}
.dialog-footer {
  text-align: left;
}
.user-cell {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: 10px;
  .avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
  }
}
</style>
