import { promisefy } from '@/utils/req';
export default <any>{
  getUserList(data = {}) {
    return promisefy(data, '/ajax/wxjz/user/list', 'post');
  },
  generateCode(data = {}) {
    return promisefy(data, '/ajax/wxjz/batchRedeem', 'post');
  },
  getCodeList(data = {}) {
    return promisefy(data, '/ajax/wxjz/code/list', 'post');
  },
};
//接口返回的数据字段类型
export type UserType = {
  nick_name: string;
  avatar: number;
  id: number | string;
  api_key: string | null;
  privacy?: number;
  ip?: string;
  sex: number;
  openid: string;
  create_at: string;
  update_at: string;
  mobile?: string | number;
  status: number;
};
export type CodeType = {
  id: number;
  code: string;
  status: number;
  uid?: number;
  api_key: string | null;
  create_at: string;
  update_at: string;
};
