import { defineAsyncComponent } from 'vue';

export default <any>{
  'op-image': defineAsyncComponent(() => import('./OpImage.vue')),
  'notice-bar': defineAsyncComponent(() => import('./NoticeBar.vue')),
  'rich-text': defineAsyncComponent(() => import('./RichText.vue')),
  space: defineAsyncComponent(() => import('./Space.vue')),
  banner: defineAsyncComponent(() => import('./Banner.vue')),
  'form-input': defineAsyncComponent(() => import('./FormInput.vue')),
  'form-sms-code': defineAsyncComponent(() => import('./FormSmsCode.vue')),
  'form-multi-check': defineAsyncComponent(
    () => import('./FormMultiCheck.vue')
  ),
  'form-radio': defineAsyncComponent(() => import('./FormRadio.vue')),
  'form-picker': defineAsyncComponent(() => import('./FormPicker.vue')),
  'form-area': defineAsyncComponent(() => import('./FormArea.vue')),
  'form-date-time': defineAsyncComponent(() => import('./FormDateTime.vue')),
  'form-submit': defineAsyncComponent(() => import('./FormSubmit.vue')),
};
