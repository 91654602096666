<template>
  <ck-page>
    <template v-if="!layoutStore.isMiniScreen">
      <div v-loading="!Bridge.hasInit || loading" class="box">
        <div class="l">
          <el-card>
            <template #header>组件库</template>
            <section
              class="topic-cate"
              v-for="(cate, idx) in topicCateMap"
              :key="cate.name"
            >
              <h5>{{ cate.name }}</h5>
              <ul class="list" v-if="cate.list.length > 0">
                <li v-for="(item, index) in cate.list" :key="index">
                  <div
                    draggable="false"
                    @mousedown="onDrag($event, topicMap[item])"
                    :id="topicMap[item].name"
                    class="topic-item"
                    :class="
                      nowItem && topicMap[item].name === nowItem.name
                        ? 'draging'
                        : ''
                    "
                  >
                    <div class="icon">
                      <ck-icon size="20" :class="topicMap[item].icon"></ck-icon>
                    </div>
                    <p>{{ topicMap[item].alias }}</p>
                    <!-- <p>{{ item.name }}</p> -->
                  </div>
                </li>
              </ul>
              <p v-else class="t-tips" style="text-align: left">暂无数据</p>
            </section>
          </el-card>
        </div>
        <div class="topic-wrapper">
          <div class="phone">
            <div
              class="phone-hair"
              :style="{
                'background-color': topicConf.bg,
                color: topicConf.color,
              }"
            >
              <div class="hair-cell"></div>
            </div>
            <div
              class="phone-header"
              :style="{
                'background-color': topicConf.bg,
                color: topicConf.color,
              }"
            >
              {{ topicConf.title }}
            </div>
            <ViewPanel
              v-if="isInit"
              ref="viewPanel"
              :dragItem="nowItem.alias ? nowItem : null"
              :clientX="clientX"
              :selectIndex="selectIndex"
              :dragPos="dragPos"
              :cellPos="cellPos"
              :clientY="clientY"
              :itemList="topicLayoutList"
              @enterChange="onEnterChange"
              @getTopicData="fetchTopicData"
            />
          </div>
        </div>
        <div class="topic-prop">
          <el-card class="box-card" style="margin-bottom: 10px">
            <template #header>
              页面全局设置
              <el-button
                style="float: right; padding: 3px 0"
                @click="toggleConfigShow"
                link
                >{{ showConfigMenus ? '隐藏内容' : '展开内容' }}</el-button
              >
            </template>
            <el-form
              @submit.prevent="saveTopic"
              v-show="showConfigMenus"
              label-width="100px"
            >
              <el-form-item label="页面标题">
                <el-input v-model="topicConf.title"></el-input>
              </el-form-item>
              <el-form-item label="整体色调">
                <el-radio v-model="topicConf.color" label="#ffffff"
                  >暗</el-radio
                >
                <el-radio v-model="topicConf.color" label="#000000"
                  >亮</el-radio
                >
                <!-- <el-color-picker v-model="topicConf.color"></el-color-picker> -->
              </el-form-item>
              <el-form-item label="页面背景色">
                <el-color-picker v-model="topicConf.bg"></el-color-picker>
                <el-form-item style="margin: 0 0 10px 0" label="设置背景">
                  <div @click="openImgModal" class="t-upload-img-cell">
                    <div v-if="topicConf.bgImg" class="upload-img-btns">
                      <span @click.stop="delBgImg">
                        <ck-icon class="icon-delete"></ck-icon>
                      </span>
                    </div>
                    <el-image fit="contain" :src="topicConf.bgImg">
                      <template #error>
                        <div class="t-image-slot">
                          <ck-icon class="icon-image" />
                        </div>
                      </template>
                    </el-image>
                  </div>
                </el-form-item>
              </el-form-item>
            </el-form>
            <el-form
              style="color: #999; font-size: 12px; padding: 10px 0"
              v-show="!showConfigMenus"
              >......点击右上角查看更多</el-form
            >
            <div class="btns">
              <el-button round @click="saveTopic" type="primary"
                >保存</el-button
              >
              <el-button round @click="toViewPage" type="success"
                >查看页面</el-button
              >
            </div>
          </el-card>
          <template v-if="editItem">
            <el-card class="box-card">
              <template #header
                >设置<span>({{ editItem.name }})</span></template
              >

              <div class="prop-wrapper">
                <Component
                  v-if="editItem.name"
                  ref="propCom"
                  @propChange="onPropChange"
                  :floor="selectIndex"
                  :cname="editItem.name"
                  :is="PropComponents[editComponentName]"
                  :opts="editItem.content"
                  :topicLayoutList="topicLayoutList"
                />
              </div>
            </el-card>
          </template>
          <div class="topic-prop-empty" v-else>请选择左侧挂件</div>
        </div>
      </div>
      <ViewDialog ref="$viewDialog" />
      <DragCell
        v-show="nowItem.alias"
        :item="nowItem"
        :status="dragStatus"
        :clientY="clientY"
        :clientX="clientX"
      />
    </template>
    <h2 v-else>请在电脑上操作</h2>
    <el-dialog width="80%" v-model="materialDialogVisible" title="素材库">
      <Material @choose="onSelectedBgImg" />
    </el-dialog>
  </ck-page>
</template>
<script lang="ts">
export default {
  name: 'topicedit',
  meta: {
    path: '/topicedit',
    auth: true,
    cache: false,
    title: 'Topic页面编辑',
    enTitle: 'Diy Topic Edit',
  },
};
</script>
<script lang="ts" setup>
import {
  getCurrentInstance,
  onMounted,
  computed,
  shallowRef,
  ref,
  watch,
  inject,
  type Ref,
  type ComputedRef,
  type ShallowRef,
} from 'vue';
import { useRoute } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import {
  routeStoreInject,
  layoutStoreInject,
  userStoreInject,
} from '@/contants';
import Material from '@/components/Material/index.vue';
import DragCell from './components/DragCell.vue';
import ViewPanel from './components/ViewPanel.vue';
import { initLayoutConf, type TopicLayoutConfType } from './conf';
import { useTopic } from './service';
import Utils from '@/utils';
import PropComponents from './topicPropComponents';
import ViewDialog from './components/ViewDialog.vue';
import type { MaterialType } from '@/apis/material';

const $route = useRoute();
const {
  fetchTopicData,
  saveTopic,
  Bridge,
  topicMap,
  topicCateMap,
  topicConf,
  topicLayoutList,
  loading,
} = useTopic();

const routeStore = inject(routeStoreInject) as any;
const layoutStore = inject(layoutStoreInject) as any;
const userStore = inject(userStoreInject) as any;
const $viewDialog = ref(null) as any;
let dragSign = false; // drag的防抖sign
let dragEnter = false; //是否托业进编辑区域
const materialDialogVisible = ref(false);
const isInit = ref(false);
const ctx: any = getCurrentInstance()?.proxy;
const nowItem: ShallowRef<TopicLayoutConfType> = shallowRef(
  Utils.clone(initLayoutConf)
);
const editItem: ComputedRef<TopicLayoutConfType | null> = computed(() => {
  if (selectIndex.value >= 0 && topicLayoutList.value.length > 0) {
    return topicLayoutList.value[selectIndex.value];
  } else {
    return null;
  }
});
const editComponentName: ComputedRef<string> = computed(() => {
  if (selectIndex.value >= 0 && topicLayoutList.value.length > 0) {
    return topicLayoutList.value[selectIndex.value].name;
  } else {
    return '';
  }
});
const showConfigMenus = ref(true);
const dragStatus: Ref<number> = ref(0); // 0:没有拖拽，1:释放拖拽，没有插入元素，2:释放拖拽，有插入
const clientY = ref(0); // 拖拽的Y坐标，相对于本容器来说
const clientX = ref(0); // 拖拽的X坐标，相对本容器来说
const selectIndex = ref(-1); // 选中的topic index
const cellPos = shallowRef({
  // 本容器的边界
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  sT: 0, // 滚动高度
});
const dragPos = shallowRef({
  x: 0, // 拖拽元素相对鼠标点的x偏移
  y: 0,
  h: 0,
});
const insertObj = shallowRef({
  // 监听插入状态时子组件传回的值
  index: 0,
  type: null,
});
watch(
  () => $route.query,
  async () => {
    isInit.value = false;
    await Utils.sleep(0.2); //放一个延时，等页面清空旧数据
    onInit();
  }
);
watch(selectIndex, () => {
  Bridge.post({ key: 'selectIndex', value: selectIndex.value });
});

const onPropChange = (value: any) => {
  const tempList = Utils.clone2(topicLayoutList.value);
  tempList[selectIndex.value].content = value;
  topicLayoutList.value = tempList;
  // Bridge.post({ key: 'topicList', value: Utils.clone2(tempList) });
};
const initDragEvent = () => {
  ctx.$el.removeEventListener('mouseup', onFreeDrag);
  ctx.$el.removeEventListener('mouseleave', onFreeDrag);
  ctx.$el.addEventListener('mouseup', onFreeDrag);
  ctx.$el.addEventListener('mouseleave', onFreeDrag);
  // window.removeEventListener('message', onGetMessage);
  window.onmessage = onGetMessage; // addEventListener会有多次触发message的问题，用onmessage
  // window.addEventListener('message', onGetMessage, false);
};
const initDragData = async (status = 0) => {
  let targetRect: any = null;
  if (nowItem.value.alias) {
    targetRect = document
      .querySelector(`#${nowItem.value.name}`)
      ?.getBoundingClientRect();
  }
  const scrollTop = ctx.$el.scrollTop;
  dragStatus.value = status;
  // 回复dragcell到拖动开始的地方
  clientY.value = targetRect ? targetRect.y - cellPos.value.y + scrollTop : 0;
  clientX.value = targetRect ? targetRect.x - cellPos.value.x : 0;
  await Utils.sleep(0.3);
  dragStatus.value = 0;
  nowItem.value = Utils.clone(initLayoutConf);
  // moveEnter = false;
  // this.insertObj.type = null;
};
const onEnterChange = (e: boolean) => {
  dragEnter = e;
  console.warn('onEnterChange', dragEnter);
};
const onGetMessage = (event: MessageEvent) => {
  if (event.data.name == 'topic-operation') {
    // console.warn('message from iframe, with topic-operation', event.data);
    switch (event.data.key) {
      case 'topic-delete':
        onDeleteItem(event.data.value.index);
        break;
      case 'topic-sort':
        onSortItem(event.data.value);
        break;
      case 'topic-rects':
        topicLayoutList.value[event.data.value.index].content.rects =
          event.data.value.rects;
        break;
      case 'topic-setrect':
        //console.warn('prop-com', this.$refs['prop-com']);
        break;
    }
  } else {
    Bridge.parseData(event.data, { insertObj, selectIndex });
  }
};
const onDrag = async (event: MouseEvent, item: any) => {
  if (dragSign) {
    return;
  }
  dragSign = true;
  await Utils.sleep(0.2); // 防止点击过快
  dragSign = false;
  ctx.$el.removeEventListener('mousemove', onDraging);
  const elRect = ctx.$el.getBoundingClientRect();
  const targetRect: any = document
    .querySelector(`#${item.name}`)
    ?.getBoundingClientRect();
  const scrollTop: number = ctx.$el.scrollTop || 0;
  clientX.value = targetRect.x - elRect.x;
  clientY.value = targetRect.y - elRect.y + scrollTop;
  dragPos.value = {
    x: event.clientX - targetRect.x,
    y: event.clientY - targetRect.y - scrollTop,
    h: targetRect.height,
  };
  cellPos.value = {
    sT: scrollTop,
    x: elRect.x,
    y: elRect.y,
    w: elRect.width,
    h: elRect.height,
  };
  nowItem.value = Utils.clone(item); // 一定要复制这个对象不然会改变topicMap
  ctx.$el.addEventListener('mousemove', onDraging, false);
};
const onFreeDrag = () => {
  if (dragEnter && nowItem.value.alias) {
    // this.selectIndex = -1
    if (insertObj.value.type) {
      let index = 0;
      if (insertObj.value.type === 'before') {
        index = insertObj.value.index;
        if (index < 0) {
          index = 0;
        }
      }
      if (insertObj.value.type === 'after') {
        index = insertObj.value.index + 1;
      }
      topicLayoutList.value.splice(index, 0, nowItem.value);
      selectIndex.value = index;
    } else {
      topicLayoutList.value.push(nowItem.value);
      selectIndex.value = topicLayoutList.value.length - 1;
    }
    dragStatus.value = 2;
    Bridge.post({
      arrays: [{ key: 'insertObj', value: { index: 0, type: null } }],
    });
  }

  initDragData(1);
  ctx.$el.removeEventListener('mousemove', onDraging);
};
const onDraging = (event: MouseEvent) => {
  clientX.value = event.clientX - dragPos.value.x - cellPos.value.x;
  clientY.value = event.clientY - dragPos.value.y - cellPos.value.y;
};
const onSortItem = (opts: { index: number; offset: number }) => {
  const { index, offset } = opts;
  if (index === 0 && offset === -1) {
    ElMessage.info('不能再往上移啦～');
    return;
  }
  if (index == topicLayoutList.value.length - 1 && offset == 1) {
    ElMessage.info('不能再往下移啦～');
    return;
  }
  const tempArray = new Array(topicLayoutList.value.length);
  const tempTopicList: any = Utils.clone2(topicLayoutList.value);
  for (let i = 0; i < tempArray.length; i++) {
    let result = tempTopicList[i];
    if (i === index) {
      result = tempTopicList[i + offset];
    }
    if (i === index + offset) {
      result = tempTopicList[index];
    }
    tempArray[i] = result;
  }
  topicLayoutList.value = tempArray;
  selectIndex.value = index + offset;
  // Bridge.post({
  //   arrays: [
  //     // { key: 'topicList', value: tempArray },
  //     { key: 'selectIndex', value: selectIndex.value },
  //   ],
  // });
};
const onDeleteItem = (index: number) => {
  ElMessageBox.confirm('此操作会删除当前组件，不可恢复, 确定要继续?', '提示', {
    type: 'warning',
  }).then(() => {
    topicLayoutList.value.splice(index, 1);
    selectIndex.value = -1;
  });
};
const toViewPage = () => {
  $viewDialog.value.open(
    `${import.meta.env.VITE_APP_TOPIC_HOST}?q=${userStore.value.id}+${
      $route.query.pageid
    }&t=${Date.now()}`
  );
};
const toggleConfigShow = () => {
  showConfigMenus.value = !showConfigMenus.value;
};
const onInit = () => {
  selectIndex.value = -1;
  if ($route.query.pageid) {
    routeStore.value.updateCache({ title: `Topic编辑-${$route.query.pageid}` });
  }
  if (!layoutStore.isMiniScreen) {
    initDragData();
    initDragEvent();
  }
  isInit.value = true;
};
const openImgModal = () => {
  materialDialogVisible.value = true;
};
const delBgImg = () => {
  topicConf.value.bgImg = '';
};
const onSelectedBgImg = (item: MaterialType) => {
  console.warn('onSelectedBgImg', item);
  topicConf.value.bgImg = item.link;
  materialDialogVisible.value = false;
};
onMounted(() => {
  onInit();
  console.warn('ddd ctx', ctx.$el);
});
</script>
<style lang="scss" scoped>
@import './edit';
</style>
