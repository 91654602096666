<template>
  <ck-page class="cate-page">
    <el-table center v-loading="isLoadingList" :data="list" style="width: 100%">
      <el-table-column label="id" prop="id" width="50px"></el-table-column>
      <el-table-column
        label="排序值"
        prop="weight"
        width="70px"
      ></el-table-column>
      <el-table-column label="标题">
        <template v-slot="scope">
          <div>
            {{ decodeURIComponent(scope.row.name) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          <div>
            {{ Utils.dateFormat(scope.row.createtime) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template v-slot="scope">
          <div>
            {{ Utils.dateFormat(scope.row.updatetime) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150px" label="操作">
        <template v-slot="scope">
          <el-button size="small" @click="toEdit(scope.row)">编辑</el-button>
          <el-popconfirm
            v-if="scope.row.id > 0"
            @confirm="toDel(scope.row)"
            title="确定删除该类别，该类别下的文章将会归类到其他?"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-button round block class="btn" type="success" @click="toAdd"
      >新增</el-button
    >
    <el-dialog
      width="350px"
      v-model="editVisible"
      :title="nowItem ? '编辑' : '新增'"
    >
      <div class="content">
        <el-form :inline="true">
          <el-form-item label="名称">
            <el-input v-model="nowItem.name"></el-input>
          </el-form-item>
          <el-form-item label="排序值">
            <el-input v-model="nowItem.weight"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button round @click="editVisible = false">取消</el-button>
          <el-button round type="primary" @click="saveChange">保存</el-button>
        </div>
      </template>
    </el-dialog>
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/cate',
    title: '分类管理',
    enTitle: 'Cate',
    auth: true,
    cache: false,
  },
  name: 'cate',
};
</script>
<script lang="ts" setup>
import { ref } from 'vue';
import { useCate } from '@/services/cate';
import type { CateType } from '@/apis/cate';
import Utils from '@/utils';
import { ElMessage } from 'element-plus';
const {
  list,
  isLoadingList,
  fetchList: fetchCateList,
  update: editCate,
  remove: delCate,
  insert: insertCate,
} = useCate();
const editVisible = ref(false);
const nowItem = ref(null) as any;
function toEdit(item: CateType): void {
  editVisible.value = true;
  nowItem.value = Utils.clone(item);
}
function saveChange() {
  let saveFunc: any = editCate;
  if (!nowItem.value.hasOwnProperty('id')) {
    saveFunc = insertCate;
  }
  saveFunc(nowItem.value)
    .then(() => {
      ElMessage.success('保存成功');
      editVisible.value = false;
      fetchCateList();
    })
    .catch((err: any) => {
      ElMessage.error(err.msg);
    });
}
function toDel(item: CateType): void {
  delCate(item)
    .then(() => {
      ElMessage.success('删除成功');
      fetchCateList();
    })
    .catch((err) => {
      ElMessage.error(err.msg);
    });
}
function toAdd(): void {
  nowItem.value = { name: '', weight: 1 };
  editVisible.value = true;
}
fetchCateList();
</script>

<style scoped>
.btn {
  display: block;
  width: 50%;
  margin: 20px auto;
}
</style>
