import { promisefy } from '@/utils/req';
export default <any>{
  cateList(data = {}) {
    return promisefy(data, '/ajax/wallpaper/cate/list', 'get');
  },
  cateDel(data = {}) {
    return promisefy(data, '/ajax/wallpaper/cate/delete');
  },
  cateAdd(data = {}) {
    return promisefy(data, '/ajax/wallpaper/cate/insert');
  },
  cateEdit(data = {}) {
    return promisefy(data, '/ajax/wallpaper/cate/update');
  },
  itemInsert(data = {}) {
    ///ajax/wallpaper/item/insert
    return promisefy(data, '/ajax/wallpaper/item/insert');
  },
  itemUpdate(data = {}) {
    return promisefy(data, '/ajax/wallpaper/item/update');
  },
  itemList(data = {}) {
    return promisefy(data, '/ajax/wallpaper/item/list', 'get');
  },
  itemDelete(data = {}) {
    return promisefy(data, '/ajax/wallpaper/item/delete');
  },
  getUserList(data = {}) {
    return promisefy(data, '/ajax/wallpaper/user/list', 'get');
  },
};
//接口返回的数据字段类型
export type CateType = {
  name: string;
  weight?: number;
  id: number | string;
  visible: number;
};
export type WallpaperType = {
  id?: number;
  link: string;
  type: number;
  cname?: string; //类别名称
  weight?: number;
  name: string;
  authorid?: number;
  cid: number;
  size: number;
  keywords: string;
  width?: number;
  height?: number;
  createtime?: number;
  updatetime?: number;
};
