import { debounce } from 'lodash';
import Viewer from 'viewerjs';

export function getTitleTree($el: HTMLElement | null) {
  if (!$el) return;
  const tree: any = [];
  const className = 't-hash';
  const titleLevel = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  $el.querySelectorAll(titleLevel.join(',')).forEach((el: Element, index) => {
    const $self: HTMLElement = el as HTMLElement;
    const titleText = $self.innerText.trim();
    if (titleText) {
      const id = `${className}-${index}`;
      $self.setAttribute('id', id);
      $self.classList.add(className);
      tree.push({
        titleText,
        id,
        active: false,
        className,
        top: $self.offsetTop,
        level: Number($self.localName.replace('h', '')),
      });
    }
  });
  return tree;
}
export function scrollTo(id: string) {
  const y = document.getElementById(id)?.offsetTop;
  window.scrollTo({ top: y, behavior: 'smooth' });
}
export function setDomObserver(
  arr: HTMLElement[] | null,
  cb: (id: string) => void
) {
  const observer: IntersectionObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        // Add 'active' class if observation target is inside viewport
        if (entry.intersectionRatio > 0) {
          // console.warn('entry', entry.target.id);
          cb(entry.target.id);
        }
      });
    },
    {
      threshold: 1,
      rootMargin: `0px 0px 0px 0px`,
    }
  );
  arr?.forEach((el) => observer.observe(el));
  return observer;
}
export function rmDomObserver(observer: IntersectionObserver | null) {
  console.warn('rmDomObserver');
  observer && observer.disconnect();
}
export function setPostScroll(
  target: Window | HTMLElement | null,
  arr: HTMLElement[] | null,
  cb: any
) {
  if (!target) {
    return null;
  }
  const targetFn = debounce(() => {
    const scrollTop = document.documentElement.scrollTop;
    const arrLen = arr?.length;
    if (arrLen) {
      let out = arr[0];
      for (let i = 0; i < arrLen; i++) {
        const el = arr[i];
        if (el.offsetTop <= scrollTop) {
          out = el;
        }
      }
      out && cb(out.id);
    }
  }, 10);
  target.addEventListener('scroll', targetFn);
  return targetFn;
}
export function rmPostScroll(
  target: Window | HTMLElement | null,
  targetFn: any
) {
  target && target.removeEventListener('scroll', targetFn);
}

export function viewPic() {
  return new Viewer(document.querySelector('.html-content'), {
    inline: false,
  });
}
