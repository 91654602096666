<template>
  <ck-page class="iot-page">
    <div class="list t-flex t-pointer">
      <el-card
        @click="toCar"
        :body-style="{ padding: '0px', textAlign: 'center' }"
      >
        <img src="https://cdn.zcxnb.cn/upload/nP7EpVh7d4.png" class="image" />
        <div style="padding: 14px">
          <h3>4G遥控小车</h3>
          <div class="bottom">
            <p class="t-tips">该项目只能在http环境下运行</p>
          </div>
        </div>
      </el-card>
    </div>
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/iot/dashbord',
    title: 'iot管理',
    enTitle: 'Iot Dashbord',
    auth: true,
    cache: false,
  },
  name: 'iotDashbord',
};
</script>
<script lang="ts" setup>
import Utils from '@/utils';
import { inject } from 'vue';
import { userStoreInject } from '@/contants';

const userStore = inject(userStoreInject) as any;

const toCar = () => {
  Utils.goPage(
    location.protocol === 'http:'
      ? '/mycar'
      : `http://mycar.70read.com/redirect.html?token=${
          userStore.value.token
        }&path=${encodeURIComponent('/mycar')}`
  );
};
</script>

<style lang="scss" scoped>
.list {
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: theme('screens.md')) {
  .list {
    justify-content: center;
  }
}
.image {
  max-width: 100%;
}
</style>
