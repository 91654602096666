import { promisefy } from '@/utils/req';
export default <any>{
  get(data = {}) {
    return promisefy(data, '/ajax/topic/get', 'get');
  },
  del(data = {}) {
    return promisefy(data, '/ajax/topic/delete');
  },
  addTopic(data = {}) {
    return promisefy(data, '/ajax/topic/add');
  },
  updateMeta(data = {}) {
    return promisefy(data, '/ajax/topic/updateMeta');
  },
  edit(data = {}) {
    return promisefy(data, '/ajax/topic/update');
  },
  list(data = {}) {
    return promisefy(data, '/ajax/topic/list', 'get');
  },
};
export const initTopicItem: TopicType = {
  config: '{}',
  name: '',
  id: 0,
  endtime: 0,
  status: 1, //0未启用，1：启用
  pageid: '',
  layout: [],
};
//接口返回的数据字段类型
export type LayoutType = {
  content: string;
  createtime?: number | string;
  updatetime?: number | string;
};
export type TopicType = {
  config: string;
  name: string;
  status?: 0 | 1;
  endtime?: number; //过期的时间戳，0：表示永不过期
  id: number | string;
  authorid?: number | string;
  pageid?: string;
  layout: Array<LayoutType>;
  createtime?: number | string;
  updatetime?: number | string;
};
