import AES from 'crypto-js/aes';
import ModeEcb from 'crypto-js/mode-ECB';
import PadPkcs7 from 'crypto-js/pad-pkcs7';
import EncUtf8 from 'crypto-js/enc-utf8';
import EncHex from 'crypto-js/enc-hex';
import EncBase64 from 'crypto-js/enc-base64';
const opts = {
  mode: ModeEcb,
  padding: PadPkcs7,
};
const initKey = 'laj1tjk9t5fhnf69xzwliuvqbc2yeij9';
export default {
  //aes加密
  encrypt(wordIn: string, keyIn = initKey) {
    const key = EncUtf8.parse(keyIn);
    const word = EncUtf8.parse(wordIn);
    return AES.encrypt(word, key, opts).ciphertext.toString();
  },
  // aes解密
  decrypt(ciphertextIn: string, keyIn = initKey) {
    // console.warn('decrypt key', keyIn);
    const key = EncUtf8.parse(keyIn);
    const ciphertext = EncHex.parse(ciphertextIn);
    const bytes = AES.decrypt(EncBase64.stringify(ciphertext), key, opts);
    return bytes.toString(EncUtf8);
  },
};
