import { promisefy } from '@/utils/req';
export default <any>{
  frontGet(data = {}) {
    return promisefy(data, '/front/resource/get', 'get', { noToken: true });
  },
  frontGetV2(data = {}) {
    return promisefy(data, '/front/resource/v2/get', 'get', { noToken: true });
  },
  get(data = {}) {
    return promisefy(data, '/ajax/resource/get', 'get');
  },
  del(data = {}) {
    return promisefy(data, '/ajax/resource/del');
  },
  add(data = {}) {
    return promisefy(data, '/ajax/resource/add');
  },
  edit(data = {}) {
    return promisefy(data, '/ajax/resource/update');
  },
  list(data = {}) {
    return promisefy(data, '/ajax/resource/list', 'get');
  },
};
//接口返回的数据字段类型
export type ResourceType = {
  code: string;
  desc?: string;
  data?: string;
  useable: number;
  id: number | string;
  updatetime?: number | string;
  createtime?: number | string;
};
