import WallpaperApi from '@/apis/wallpaper';

export const typeDict = [
  {
    label: '通用',
    value: 1,
    icon: '💻 📱',
  },
  {
    label: '手机',
    value: 2,
    icon: '📱',
  },
  {
    label: '电脑',
    value: 3,
    icon: '💻',
  },
];
export const getCateDict = async () => {
  const res: any = await WallpaperApi.cateList({
    page: 1,
    limit: 100,
  });
  return res.list;
};
export const getTypeStr = (type: number) => {
  const target = typeDict.filter((item) => item.value === type)[0];
  return `${target.icon}${target.label}`;
};
