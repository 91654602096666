<template>
  <ck-page class="posts-page" :errMsg="resErr">
    <p class="top-tip">
      {{ isEdit ? '您正在修改文章' : '您正在创建新的文章' }}
    </p>
    <div class="edit-wrap">
      <el-form ref="form" label-width="80px">
        <el-form-item label="标题">
          <el-input style="width: 400px" v-model="postRes.title" />
        </el-form-item>
        <el-form-item label="权重">
          <el-input
            type="number"
            style="width: 400px"
            v-model="postRes.weight"
          />
        </el-form-item>
        <template v-if="isEdit">
          <el-form-item label="创建时间">
            <p style="font-size: 12px">
              {{ Utils.dateFormat(postRes.createtime) }}
            </p>
          </el-form-item>
          <el-form-item label="更新时间">
            <p style="font-size: 12px">
              {{ Utils.dateFormat(postRes.updatetime) }}
            </p>
          </el-form-item>
        </template>
        <el-form-item label="隐私">
          <el-switch
            v-model="postRes.view"
            :active-value="1"
            :inactive-value="0"
            active-text="公开"
            inactive-text="私密"
          >
          </el-switch>
        </el-form-item>
        <el-form-item v-if="!isLog" label="文章分类">
          <el-select v-model="postRes.cateid" placeholder="请选择">
            <el-option
              v-for="item in cates"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="postRes" label="阅读量">
          <el-input
            readonly
            type="number"
            style="width: 170px"
            v-model="postRes.pageview"
          />
        </el-form-item>
        <el-form-item label="简语">
          <el-input
            rows="5"
            style="width: 500px"
            type="textarea"
            v-model="postRes.subcon"
          />
        </el-form-item>
      </el-form>
      <el-form label-width="80px">
        <el-form-item label="封面">
          <div @click="openUploadModal">
            <el-image
              :title="postRes.faceimg"
              fit="contain"
              :src="Utils.getQnImg(postRes.faceimg, ['thumbnail/300x'])"
              class="t-upload-img-cell"
            >
              <template #error>
                <div class="t-image-slot">
                  <CkIcon size="25" class="icon-image" />
                </div>
              </template>
            </el-image>
          </div>
          <el-button
            style="margin-left: 10px"
            @click="() => (postRes.faceimg = '')"
            link
            type="danger"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <RichEditor v-if="!isLog" v-model="postRes.content" />
      <div class="btns">
        <el-button round type="primary" @click="onSubmit">保存</el-button>
        <el-button round v-if="isEdit" @click="onReset">重置</el-button>
      </div>
    </div>
    <el-dialog width="80%" v-model="materialDialogVisible" title="素材库">
      <Material @choose="onChooseMaterial" />
    </el-dialog>
    <!-- <div v-else class="empty">{{ resErr }}，数据丢失或服务失效</div> -->
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/editpost',
    title: '创建文章',
    enTitle: 'Write Post',
    auth: true,
    cache: false,
  },
  name: 'editpost',
};
</script>
<script lang="ts" setup>
import { ref, onMounted, computed, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { ElLoading, ElMessage } from 'element-plus';
import ArticleApi from '@/apis/article';
import type { MaterialType } from '@/apis/material';
import RichEditor from '@/components/RichEditor.vue';
import { routeStoreInject } from '@/contants';
import { useCate } from '@/services/cate';
import Material from '@/components/Material/index.vue';
import Utils from '@/utils';
import Bus from '@/utils/bus';

class InitRes {
  content: string;
  faceimg: string;
  [x: string]: number | string | undefined;
  constructor() {
    this.content = '';
    this.cateid = '';
    this.catename = '';
    this.id = 0;
    this.title = '';
    this.pageview = 0;
    this.view = 1;
    this.subcon = '';
    this.faceimg = '';
    this.createtime = '';
    this.updatetime = '';
    this.weight = 0;
  }
}
const resErr = ref('');
const postRes = ref(new InitRes());
const routeStore = inject(routeStoreInject) as any;
const materialDialogVisible = ref(false);
const { list: cates, fetchList: fetchCateList } = useCate();
// const postHtml = ref('<section class="ck-editor">1212</section>');
const $route = useRoute();
const isEdit = computed(() => !!$route.query.id);
const isLog = computed(() => $route.query.type && $route.query.type === 'log');
const getPost = async () => {
  resErr.value = '';
  if ($route.query.id) {
    try {
      const res = await ArticleApi.get({ id: $route.query.id });
      postRes.value = res;
      routeStore.value.updateCache({ title: `编辑文章-${res.title}` });
    } catch (error: any) {
      resErr.value = `${error.message},数据缺失或者服务失效`;
      routeStore.value.updateCache({
        title: `文章加载失败-id:${$route.query.id}`,
      });
      postRes.value = new InitRes();
    }
  } else {
    // routeStore.updateCache({ title: '创建文章' });
    postRes.value = new InitRes();
  }
};
const openUploadModal = () => {
  materialDialogVisible.value = true;
};
const onChooseMaterial = (item: MaterialType) => {
  materialDialogVisible.value = false;
  postRes.value.faceimg = item.link;
};
const onReset = () => {
  getPost();
};
const onSubmit = async () => {
  // console.warn('this postRes', postRes);
  const { title, view, subcon, content, faceimg, weight = 0 } = postRes.value;

  let { cateid } = postRes.value;
  if (isLog.value) {
    cateid = '5';
  }
  if (cateid === '') {
    ElMessage.info('请选择分类');
    return;
  }
  const loading = ElLoading.service({
    lock: true,
    text: '正在请求服务器',
    background: 'rgba(0, 0, 0, 0.7)',
  });
  try {
    if (isEdit.value) {
      const id = $route.query.id;
      await ArticleApi.edit({
        id,
        title,
        weight,
        view,
        subcon,
        cateid,
        faceimg,
        content,
      });
      ElMessage.success('文章编辑成功');
    } else {
      await ArticleApi.add({
        title,
        view,
        subcon,
        weight,
        cateid,
        faceimg,
        content,
      });
      ElMessage.success('文章创建成功');
      setTimeout(() => {
        Utils.goPage('/posts');
      }, 1000);
    }
  } catch (error) {}
  loading.close();
  if (Number(cateid) === 5) {
    Bus.emit('updateMonent');
  }
};
watch(
  () => $route.query,
  () => {
    getPost();
  }
);

onMounted(() => {
  getPost();
  fetchCateList();
});
</script>
<style lang="scss" scoped>
.top-tip {
  padding: 10px 0;
  font-size: 12px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.btns {
  padding: 10px;
  text-align: left;
}
</style>
