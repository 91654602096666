<template>
  <div
    class="live2d"
    :style="{
      width: cWidth + 'px',
      height: cHeight + 'px',
      top: cTop + '%',
      left: cLeft + '%',
    }"
    @mouseup="stopDrag"
    v-show="visible"
  >
    <div class="live2d-btns">
      <i
        title="拖动"
        @mousedown="startDrag"
        style="cursor: pointer"
        class="btn"
      >
        <ck-icon size="22" class="icon-drag" />
      </i>
      <i title="收起" @click="hideLive2d" style="cursor: pointer" class="btn">
        <ck-icon size="20" class="icon-unview" />
      </i>
    </div>
    <canvas
      v-show="cVisible"
      id="live2d"
      :width="cWidth"
      :height="cHeight"
      class="live2d-canvas"
    ></canvas>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Utils from '@/utils';
export default defineComponent({
  data() {
    return {
      cVisible: true,
      dragOk: false,
      cWidth: 150,
      cHeight: 200,
      cTop: 100,
      cLeft: 80,
      visible: false,
    };
  },
  methods: {
    startDrag() {
      if (this.dragOk) {
        this.stopDrag();
        return;
      }
      this.dragOk = true;
      window.addEventListener('mousemove', this.draging, false);
    },
    draging(e: MouseEvent) {
      this.setPos(e.clientX, e.clientY);
    },
    stopDrag() {
      window.removeEventListener('mousemove', this.draging);
      this.dragOk = false;
    },
    setPos(x: number, y: number): void {
      const { clientWidth, clientHeight } = document.body;
      this.cTop = (y / clientHeight) * 100;
      this.cLeft = (x / clientWidth) * 100;
    },
    hideLive2d() {
      const { clientWidth, clientHeight } = document.body;
      this.setPos(clientWidth - 50, clientHeight - 50);
    },
    init() {
      const { clientWidth, clientHeight } = document.body;
      this.setPos(clientWidth - this.cWidth - 50, clientHeight - this.cHeight);
      // loadlive2d('live2d','https://unpkg.com/live2d-widget-model-koharu@1.0.5/assets/koharu.model.json')
      Utils.getJScript('https://cdn.zcxnb.cn/js/live2d.min.js', async () => {
        // loadlive2d('live2d','https://unpkg.com/live2d-widget-model-koharu@1.0.5/assets/koharu.model.json')
        window.loadlive2d &&
          window.loadlive2d(
            'live2d',
            'https://cdn.zcxnb.cn/live2d/model/live2d-widget-model-koharu/assets/koharu.model.json'
          );
        this.visible = true;
      });
    },
  },
  mounted() {
    this.init();
  },
});
</script>
<style lang="scss" scoped>
.live2d {
  box-sizing: content-box;
  user-select: none;
  position: fixed;
  z-index: 10000;
  font-size: 0;
  &:hover {
    .btn {
      display: block !important;
    }
  }
  // transition: all .3s ease-in-out;
  &-btns {
    position: absolute;
    color: #777;
    width: 30px;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 25px;
    font-weight: bold;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: start;
    .btn {
      margin-bottom: 5px;
      display: none;
    }
  }
}

#live2d {
  position: relative;
}
</style>
