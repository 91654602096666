<template>
  <ck-page>
    <h4>壁纸上传</h4>
    <div class="t-space-10"></div>
    <el-upload
      class="upload-btn"
      :data="uploadOpts"
      :action="uploadUrl"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleFailure"
    >
      <el-button round type="primary">上传</el-button>
      <template #tip>
        <div class="el-upload__tip">一次只能上传一个文件，且不超过50MB</div>
      </template>
    </el-upload>
    <p style="margin-top: 0" class="el-upload__tip">
      通过剪贴板上传（仅支持图片），点击下面区域，并把文件黏贴
    </p>
    <section class="paste-area" ref="$pasteArea" contenteditable></section>
    <el-dialog
      v-model="editVisible"
      :title="editItem?.id ? '编辑壁纸' : '新建壁纸'"
    >
      <template v-if="editItem">
        <el-image
          style="width: 100px; height: 100px"
          :preview-src-list="[editItem?.trueLink]"
          :src="editItem?.trueLink"
          alt=""
          fit="contain"
        >
          <template #error> {{ $t('noImage') }} </template>
        </el-image>
        <el-form-item label="分辨率">
          <el-input
            :value="editItem.width + 'x' + editItem.height"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="文件大小">
          <el-input
            disabled
            :value="Utils.byteConvert(editItem.size)"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <el-input disabled :value="editItem.link"></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="editItem.name"></el-input>
        </el-form-item>
        <el-form-item label="权重">
          <el-input type="number" v-model="editItem.weight"></el-input>
        </el-form-item>
        <el-form-item label="关键词，多个请用分号隔开">
          <el-input v-model="editItem.keywords"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select v-model="editItem.cid" placeholder="请选择">
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台">
          <el-select v-model="editItem.type" placeholder="请选择">
            <el-option
              v-for="item in typeDict"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button v-loading="loading" type="primary" @click="onSubmit"
          >确认提交</el-button
        >
      </template>
    </el-dialog>
    <el-form @submit.prevent inline>
      <el-form-item label="关键词">
        <el-input @keyup.enter="() => search()" clearable v-model="s.keyword" />
      </el-form-item>
      <el-form-item label="是否可见">
        <el-radio-group @change="() => search()" v-model="s.visible">
          <el-radio-button :label="''">全部</el-radio-button>
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分类">
        <el-select clearable v-model="s.cid" placeholder="请选择">
          <el-option
            v-for="item in cateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="平台">
        <el-select clearable v-model="s.type" placeholder="请选择">
          <el-option
            v-for="item in typeDict"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-card v-loading="loading">
      <div class="t-flex" style="flex-wrap: wrap">
        <section
          style="margin: 5px; width: 170px"
          v-for="(item, index) in list"
          :key="index"
        >
          <el-image
            v-if="item.link"
            :title="item.link"
            fit="contain"
            @click="() => choose(item)"
            :src="
              Utils.getQnImg(Utils.getWallpaperLink(item.link), [
                'thumbnail/200x',
              ])
            "
            style="width: 170px; height: 170px"
            class="t-upload-img-cell"
          >
            <template #error>
              <div class="t-image-slot">
                <CkIcon size="25" class="icon-doc" />
              </div>
            </template>
          </el-image>
          <div class="item-info">
            <p>ID:{{ item.id }}</p>
            <p>名称:{{ item.name }}</p>
            <p>{{ item.width }}x{{ item.height }}</p>
            <p>{{ getTypeStr(item.type) }}</p>
            <p :title="item.keywords">🔍：{{ item.keywords }}</p>
            <p>📃：{{ Utils.byteConvert(item.size) }}</p>
            <p>📂：{{ item.cname }}</p>
            <p>📅：{{ Utils.dateFormat(item.createtime) }}</p>
            <p style="margin-top: 5px">
              <el-button type="primary" size="small" @click="() => choose(item)"
                >编辑</el-button
              >
              <el-button size="small" type="danger" @click="() => del(item)"
                >删除</el-button
              >
            </p>
          </div>
        </section>
      </div>
    </el-card>
    <CkPagination ref="$pagination" :api-handler="fetchList" />
  </ck-page>
</template>
<script lang="ts" setup>
import { reactive, ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import Utils from '@/utils';
import { CookieEnum } from '@/contants';
import { typeDict, getCateDict, getTypeStr } from './dict';
import WallpaperApi from '@/apis/wallpaper';
import type { WallpaperType, CateType } from '@/apis/wallpaper';

interface PicItemType {
  trueLink?: string;
  id?: number;
  cid?: number | string;
  link: string;
  weight: number;
  cname?: string;
  keywords: string;
  size: number;
  name: string;
  visible?: number;
  width: number;
  height: number;
  type: number;
}
defineOptions({
  name: 'WallpaperList',
  meta: {
    title: '壁纸列表',
    enTitle: 'Wallpaper List',
    path: '/wallpaper/list',
    auth: true,
  },
});
const editVisible = ref(false);
const cateList = ref<CateType[]>([]);
const editItem = ref<PicItemType | null>(null);
const $pasteArea = ref(null) as any;
const $pagination = ref(null) as any;
const loading = ref(false);
const s = reactive({
  //列表搜索参数
  keyword: '',
  visible: '',
  cid: '',
  type: '',
});
const uploadUrl = `${import.meta.env.VITE_APP_API_HOST}ajax/qnupload?zone=gz`;
const uploadOpts = reactive({
  token: Utils.cookies.get(CookieEnum.token) || '',
});
const list = ref<WallpaperType[]>([]);
const fetchList = async (index = 0, size = 0) => {
  loading.value = true;
  const res = await WallpaperApi.itemList({
    offset: (index - 1) * size,
    limit: size,
    ...s,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const search = () => {
  $pagination.value.fetch(1);
};
const del = async (item: any) => {
  await WallpaperApi.itemDelete({ id: item.id });
  ElMessage.success('删除成功');
  $pagination.value.fetch();
};
const beforeUpload = async (file: File) => {
  if (!uploadOpts.token) {
    ElMessage.error('请稍后再试，正在初始化');
    return false;
  }
  // console.warn('beforeUpload', file);
  const limitSize = file.size / 1024 / 1024 < 50; // 最大上传100M
  if (!limitSize) {
    ElMessage.error('不得上传超过50M的文件');
    return false;
  }
  return true;
};
const handleSuccess = async (res: any, file: File) => {
  if (res.rcode !== 200) {
    ElMessage.error('上传失败', res.msg);
    return;
  }
  const link = `${
    Utils.testUrl(res.data.url) ? '' : import.meta.env.VITE_APP_UPLOAD_PREFIX_GZ
  }${res.data.key}`;
  const img = new Image();

  // 获取图片的宽高
  img.src = link;
  img.onload = function () {
    const picItem: PicItemType = {
      trueLink: link,
      link: res.data.url,
      size: file.size,
      name: file.name,
      keywords: file.name,
      width: img.width,
      weight: 1,
      cid: cateList.value[0].id,
      height: img.height,
      type: img.width / img.height < 0.8 ? 2 : 3,
    };
    editItem.value = Utils.clone2(picItem);
    editVisible.value = true;
  };
  ElMessage.success('图片上传成功');
  // await MaterialApi.add({
  //   size: Utils.byteConvert(file.size),
  //   name: file.name,
  //   link: `${
  //     Utils.testUrl(res.data.url)
  //       ? ''
  //       : import.meta.env.VITE_APP_UPLOAD_PREFIX
  //   }${res.data.url}`,
  // });
  // $pagination.fetch(1);
};
const handleFailure = async (error: any) => {
  console.log('error', error.status);
  ElMessage.error('上传失败,status:' + error.status);
};

const watchPasted = () => {
  $pasteArea.value.addEventListener('paste', async (e: ClipboardEvent) => {
    if (!e.clipboardData) {
      return;
    }
    const items = e.clipboardData.items;
    let file: any = null;
    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf('image') !== -1) {
          file = item.getAsFile();
          break;
        }
      }
    }
    if (!file) {
      ElMessage.error('请黏贴图片');
      return;
    }
    if (await beforeUpload(file)) {
      const $el: any = $pasteArea.value;
      loading.value = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('token', uploadOpts['token']);
      const xhr = new XMLHttpRequest();
      xhr.open('POST', uploadUrl);
      xhr.send(formData);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          ElMessage.info('上传成功');
          loading.value = false;
          setTimeout(() => ($el.innerHTML = ''), 1000);
          const res = JSON.parse(xhr.response);
          handleSuccess(res, file);
        }
      };
    }
  });
};
const choose = (item: any) => {
  item.trueLink = Utils.getWallpaperLink(item.link);
  editItem.value = Utils.clone2(item);
  editVisible.value = true;
};
const onSubmit = async () => {
  // console.log('submit!', editItem.value.id);
  loading.value = true;
  await WallpaperApi[editItem.value?.id ? 'itemUpdate' : 'itemInsert'](
    editItem.value
  ).finally(() => {
    loading.value = false;
  });
  ElMessage.success('提交成功');
  $pagination.value.fetch();
  editVisible.value = false;
};
onMounted(async () => {
  cateList.value = await getCateDict();
  search();
  watchPasted();
});
</script>
<style lang="scss" scoped>
.item-info {
  > p {
    font-size: 12px;
    width: 100%;
    @include ellipsis();
  }
}
.paste-area {
  padding: 10px;
  border: 1px solid #ccc;
  max-width: 600px;
  margin-bottom: 10px;
  img {
    display: inline-block;
    max-width: 100%;
  }
}
</style>
