<template>
  <section class="ck-pagination">
    <el-pagination
      :small="layoutStore.isMiniScreen"
      @current-change="fetch"
      @size-change="sizeChange"
      v-model:currentPage="index"
      :page-sizes="[size, size * 2]"
      v-model:page-size="pageSize"
      :pager-count="7"
      :layout="
        layoutStore.isMiniScreen
          ? 'total,prev, pager, next'
          : 'total, sizes, prev, pager, next, jumper'
      "
      :total="total"
    />
  </section>
</template>
<script lang="ts">
import { defineComponent, inject, ref } from 'vue';
import { layoutStoreInject } from '@/contants';
export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 20,
    },
    apiHandler: {
      type: Function,
      default: () => false,
    },
  },
  setup() {
    return {
      pageSize: ref(20),
      layoutStore: inject(layoutStoreInject) as any,
      total: ref(0),
      index: ref(1),
    };
  },
  watch: {
    size(val) {
      this.pageSize = val;
    },
  },
  mounted() {
    this.pageSize = this.size;
  },
  methods: {
    async sizeChange() {
      this.fetch(1);
    },
    async fetch(idx = 0) {
      //idx参数表示指定第几页，0：拿pages的
      if (idx) {
        this.index = idx;
      }
      const { index, pageSize } = this;
      const res = await this.apiHandler(index, pageSize);
      console.warn('fetch res', res);
      this.total = res.total;
    },
  },
});
</script>
<style lang="scss">
.ck-pagination {
  --el-fill-color-blank: transparent;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
