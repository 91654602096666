<template>
  <ck-page class="material-page acg">
    <Material preview />
  </ck-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Material from '@/components/Material/Acg.vue';
export default defineComponent({
  meta: {
    path: '/acg',
    title: 'ACG素材库',
    enTitle: 'Acg',
    auth: false,
    cache: false,
  },
  components: { Material },
  name: 'acg',
  setup() {
    return {};
  },
});
</script>

<style></style>
