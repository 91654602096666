<template>
  <ck-page>
    <div style="padding: 20px 0">
      <el-radio
        v-model="params.useable"
        @change="
          () => {
            search();
          }
        "
        :label="-1"
        >全部</el-radio
      >
      <el-radio
        v-model="params.useable"
        @change="
          () => {
            search();
          }
        "
        :label="1"
        >已开启</el-radio
      >
      <el-radio
        v-model="params.useable"
        @change="
          () => {
            search();
          }
        "
        :label="0"
        >未开启</el-radio
      >
    </div>
    <el-form :inline="true" :model="params">
      <el-form-item label="关键字Code">
        <el-input @keyup.enter="search" v-model="params.keyword" clearable />
      </el-form-item>
      <el-form-item>
        <el-button round type="success" @click="search">查询</el-button>
        <el-button round type="primary" @click="toAdd">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="list" border>
      <el-table-column label="id" prop="id" width="70px"></el-table-column>
      <el-table-column label="code" prop="code">
        <template v-slot="scope">
          <span style="padding-right: 10px">{{ scope.row.code }}</span
          ><ck-icon
            v-copy="scope.row.code"
            title="复制"
            size="13"
            class="icon-publish t-pointer"
          />
        </template>
      </el-table-column>
      <el-table-column label="描述">
        <template v-slot="scope">
          {{ scope.row.desc }}
        </template>
      </el-table-column>
      <el-table-column label="接口地址" width="220px">
        <template v-slot="{ row }">
          https://api.qtcat.cn/front/resource/v2/get?code={{ row.code }}&aid={{
            userStore.id
          }}
          <ck-icon
            v-copy="
              `https://api.qtcat.cn/front/resource/v2/get?code=${row.code}&aid=${userStore.id}`
            "
            title="复制"
            size="13"
            class="icon-publish t-pointer"
          />
        </template>
      </el-table-column>
      <el-table-column label="启用" width="60px">
        <template v-slot="scope">
          {{ scope.row.useable ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          {{ dateFormat(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template v-slot="scope">
          {{ dateFormat(scope.row.updatetime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px">
        <template v-slot="scope">
          <el-button size="small" @click="toEdit(scope.row)">编辑</el-button>
          <el-popconfirm
            v-if="scope.row.id > 0"
            @confirm="toDel(scope.row)"
            title="确定删除?"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
    <el-dialog
      :width="layoutStore.isMiniScreen ? '100%' : ''"
      v-model="detailVisible"
    >
      <Edit @success="onEditSuccess" :item="nowItem" />
    </el-dialog>
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/resource/list',
    title: '自定义数据',
    enTitle: 'Resource List',
    auth: true,
    cache: false,
  },
  name: 'resourceList',
};
</script>
<script lang="ts" setup>
import Edit from './components/Edit.vue';
import { ref, reactive, inject, onMounted } from 'vue';
import { layoutStoreInject, userStoreInject } from '@/contants';
import { ElMessage } from 'element-plus';
import ResourceApi from '@/apis/resource';
import Utils from '@/utils';

import type { ResourceType } from '@/apis/resource';

const { dateFormat } = Utils;
const layoutStore = inject(layoutStoreInject) as any;
const userStore = inject(userStoreInject) as any;
const detailVisible = ref(false);
const params = reactive({ useable: -1, keyword: '' });
const $pagination = ref(null) as any;
const nowItem = ref(null) as any;
const loading = ref(false);
const list = ref([]);

const search = () => {
  $pagination.value.fetch(1);
};

const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res = await ResourceApi.list({
    offset: (index - 1) * size,
    limit: size,
    useable: params.useable,
    keyword: params.keyword,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const toDel = (item: ResourceType) => {
  ResourceApi.del(item)
    .then(() => {
      ElMessage.success('删除成功');
      $pagination.value.fetch();
    })
    .catch((err: any) => {
      ElMessage.error(err.msg);
    });
};
const toEdit = (item: ResourceType) => {
  detailVisible.value = !detailVisible.value;
  nowItem.value = item;
};
const toAdd = () => {
  detailVisible.value = !detailVisible.value;
  nowItem.value = null;
};
const onEditSuccess = (item: ResourceType) => {
  $pagination.value.fetch(1);

  detailVisible.value = false;
};
onMounted(() => {
  search();
});
</script>
<style lang="scss" scope></style>
