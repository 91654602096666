<template>
  <ck-page>
    <div class="t-space-20"></div>
    <el-input
      clearable
      style="width: 200px; margin-right: 10px"
      v-model="searchForm.keyword"
      placeholder="pageid/名称"
      @keyup.enter="search"
    ></el-input>
    <el-button round type="success" @click="search">查询</el-button>
    <el-button round type="primary" @click="() => openEdit()"
      >新增Topic</el-button
    >
    <div class="t-space-20"></div>
    <el-table v-loading="loading" :data="list" border>
      <el-table-column label="id" prop="id" width="50px"></el-table-column>
      <el-table-column label="pageid" width="120px">
        <template v-slot="scope">
          <div
            class="t-pointer"
            copy-success-tip="已复制pageid"
            v-copy="scope.row.pageid"
          >
            {{ scope.row.pageid }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="name" width="200px">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.name"
            placeholder="请输入备注"
            @change="() => updateMeta(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="120px">
        <template v-slot="scope">
          <el-radio-group
            v-model="scope.row.status"
            size="small"
            @change="() => updateMeta(scope.row)"
          >
            <el-radio-button :label="1">开启</el-radio-button>
            <el-radio-button :label="0">关闭</el-radio-button>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="过期时间" width="250px">
        <template v-slot="scope">
          <span style="font-size: 12px">{{
            scope.row.endtime ? '' : '无限制'
          }}</span>
          <el-date-picker
            size="small"
            v-model="scope.row.endtime"
            @change="() => updateMeta(scope.row)"
            type="datetime"
            placeholder="选择日期"
          />
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          {{ dateFormat(scope.row.createdtime) }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template v-slot="scope">
          {{ dateFormat(scope.row.updatetime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button
            type="primary"
            @click="
              () => {
                toEdit(Utils.clone(scope.row));
              }
            "
            size="small"
            >编辑</el-button
          >
          <el-button
            type="success"
            @click="
              () => {
                toView(Utils.clone(scope.row));
              }
            "
            size="small"
            >查看</el-button
          >
          <el-popconfirm
            v-if="scope.row.id > 0"
            @confirm="toDel(scope.row)"
            title="确定删除?"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
    <el-dialog
      :width="layoutStore.isMiniScreen ? '100%' : ''"
      :title="'添加Topic'"
      v-model="editVisible"
    >
      <el-form label-width="80px">
        <el-form-item label="页面id">
          <el-input
            clearable
            placeholder="字母与数字组成,不填则自动生成"
            v-model="editItem.pageid"
          />
        </el-form-item>
        <el-form-item label="页面名称">
          <el-input
            placeholder="该专题的描述/说明"
            clearable
            v-model="editItem.name"
          />
        </el-form-item>
      </el-form>
      <el-button round size="medium" type="parimary" @click="addTopic"
        >提交</el-button
      >
    </el-dialog>
    <ViewDialog ref="$viewDialog" />
  </ck-page>
</template>
<script lang="ts">
export default {
  name: 'topiclist',
  meta: {
    path: '/topiclist',
    auth: true,
    cache: false,
    title: '自定义页面',
    enTitle: 'Diy Topic',
  },
};
</script>
<script lang="ts" setup>
import { ref, onMounted, inject, reactive, type Ref } from 'vue';
import { layoutStoreInject, userStoreInject } from '@/contants';
import { ElMessage } from 'element-plus';
import ViewDialog from './components/ViewDialog.vue';
import TopicApi, { initTopicItem, type TopicType } from '@/apis/topic';
import Utils from '@/utils';
const userStore = inject(userStoreInject) as any;
const layoutStore = inject(layoutStoreInject) as any;
const { dateFormat } = Utils;
const editVisible = ref(false);
const loading = ref(false);
const list = ref([]);
const $pagination = ref(null) as any;
const $viewDialog = ref(null) as any;
const searchForm = reactive({
  keyword: '',
});
const editItem: Ref<TopicType> = ref(Utils.clone(initTopicItem));
const search = () => {
  editVisible.value = false;
  $pagination.value.fetch(1);
};
const updateMeta = async (row: TopicType) => {
  // console.warn('row.endtime', typeof row.endtime);
  if (typeof row.endtime === 'object') {
    row.endtime = new Date(row.endtime).getTime();
  }
  await TopicApi.updateMeta(row);
  ElMessage.success('更新成功');
  $pagination.value.fetch();
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res: any = await TopicApi.list({
    offset: (index - 1) * size,
    limit: size,
    ...searchForm,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const openEdit = (item: TopicType = Utils.clone(initTopicItem)) => {
  editItem.value = item;
  editVisible.value = true;
};
const toEdit = (item: TopicType) => {
  Utils.goPage(`/topicedit?pageid=${item.pageid}`);
};
const toView = (item: TopicType) => {
  $viewDialog.value.open(
    `${import.meta.env.VITE_APP_TOPIC_HOST}?q=${userStore.value.id}+${
      item.pageid
    }&t=${Date.now()}`
  );
};
const addTopic = async () => {
  if (editItem.value.pageid && !/^\w+$/.test(editItem.value.pageid)) {
    ElMessage.error('pageid格式错误');
    return;
  }
  await TopicApi.addTopic(editItem.value);
  ElMessage.success('创建成功');
  editVisible.value = false;
  $pagination.value.fetch();
};
const toDel = async (item: TopicType = Utils.clone(initTopicItem)) => {
  await TopicApi.del({ pageid: item.pageid });
  ElMessage.success('删除成功');
  $pagination.value.fetch();
};
onMounted(() => {
  search();
});
</script>
