<script lang="ts">
export default {
  meta: {
    path: '/noauth',
    title: '无权限页面',
    enTitle: 'No Auth',
    auth: true,
    cache: false,
  },
  name: 'noauth',
};
</script>
<script lang="ts" setup>
import { RouterLink, useRoute } from 'vue-router';
const $route: any = useRoute();
</script>

<template>
  <ck-page footer header>
    <div class="ck-not-found">
      <h1>{{ decodeURIComponent($route.query.t || '') }}</h1>
      <p style="margin: 20px 0; font-size: 20px">无权限访问该页面</p>
      <div>
        <RouterLink :to="{ path: '/' }">返回首页</RouterLink>
      </div>
    </div>
  </ck-page>
</template>

<style lang="scss">
.ck-not-found {
  width: 100%;
  height: 100%;
  max-width: 700px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
