<script setup lang="ts">
import { ref, onMounted, nextTick, watch } from 'vue';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.min.css';
const props = defineProps<{
  jsonText: string;
}>();
const jsonEditorConf = {
  mode: 'code',
};
let editorCtx: any = null;
onMounted(() => {
  init(props.jsonText);
});
watch(props, async (props, prevProps) => {
  console.warn('props change', props, prevProps);
  await nextTick();
  init(props.jsonText);
});
const init = async (jsonText: string) => {
  const container = document.getElementById('jsoneditor') as HTMLElement;
  if (!editorCtx) {
    container.innerHTML = '';
    editorCtx = new JSONEditor(container, jsonEditorConf);
  }
  editorCtx.set(JSON.parse(jsonText));
};
const getJsonFromEditor = () => editorCtx.get();
defineExpose({
  getJsonFromEditor,
});
</script>
<template>
  <div id="jsoneditor" class="json-editor">json editor</div>
</template>
<style lang="scss" scoped>
.json-editor {
  width: 100%;
  height: 500px;
}
</style>
