<script lang="ts">
export default {
  meta: {
    path: '/picholder',
    title: '图片占位',
    enTitle: 'Placeholder Image',
    auth: false,
    cache: true,
  },
  name: 'picholder',
};
</script>
<script lang="ts" setup>
import { ref, computed, reactive } from 'vue';
const width = ref(200);
const height = ref(200);
const params = reactive({
  text: '',
  bg: '',
  color: '',
});
const translateQuery = (inParams: any) => {
  const queryStrs: string[] = [];
  Object.keys(inParams).map((item: any) => {
    const param = inParams[item as keyof typeof inParams];
    if (param) {
      queryStrs.push(`${item}=${encodeURIComponent(param)}`);
    }
  });
  return queryStrs.join('&');
};
const outputUrl = computed(() => {
  const query = translateQuery(params);
  let url = `${import.meta.env.VITE_APP_API_HOST}pic/${width.value}`;
  if (Number(width.value) !== Number(height.value)) {
    url += `x${height.value}`;
  }
  if (query) {
    url += `?${query}`;
  }
  return url;
});
</script>

<template>
  <ck-page class="page" footer header>
    <!-- <h2>安全地发送文本</h2> -->
    <h2>获取一个占位图片</h2>
    <el-form label-position="top" class="form">
      <el-form-item label="宽：">
        <input type="number" v-model.lazy="width" />
      </el-form-item>
      <el-form-item label="高">
        <input type="number" v-model.lazy="height" />
      </el-form-item>
      <el-form-item label="文本">
        <input type="text" maxlength="7" v-model.lazy="params.text" />
      </el-form-item>
      <el-form-item label="背景色（不要输入#，正式示例：f77）">
        <input type="text" v-model.lazy="params.bg" />
      </el-form-item>
      <el-form-item label="字体色（不要输入#，正式示例：f77）">
        <input type="text" v-model.lazy="params.color" />
      </el-form-item>
    </el-form>
    <p
      style="margin-bottom: 10px; word-break: break-all"
      v-copy="outputUrl"
      class="t-flex t-pointer"
    >
      {{ outputUrl }}
    </p>
    <el-image :src="outputUrl"></el-image>
  </ck-page>
</template>

<style lang="scss" scoped>
.page {
  max-width: 600px;
  margin: 0 auto;
}
.link {
  padding: 10px 0;
  word-break: break-all;
}
</style>
