<template>
  <el-card v-loading="loading" class="comment-box ck-blur-card-bg">
    <template #header>
      <h3>{{ props.title }}</h3>
    </template>
    <section class="comment-form">
      <div class="user-cell">
        <el-avatar :size="40" fit="cover" :src="userStore.avatar" />
        <span class="user-name">{{ userStore.userName }}</span>
      </div>
      <div class="form-cell" id="v-comment">
        <el-input
          ref="$formInp"
          :disabled="!userStore.isLogin"
          v-model="inputContent"
          @keyup="onKeyUp"
          type="textarea"
          class="form-inp"
          :placeholder="placeholder"
          :rows="3"
          :autosize="{ minRows: 3, maxRows: 8 }"
          maxlength="200"
        ></el-input>
        <blockquote v-if="replyId" class="reply-quote">
          <b>引用：</b>{{ replyContent }}
          <ck-icon
            @click="deleteReply"
            class="icon-close t-pointer"
            title="删除"
          ></ck-icon>
        </blockquote>
      </div>
    </section>
    <div class="comment-btns">
      <template v-if="userStore.isLogin">
        <span style="font-size: 12px; opacity: 0.6; margin-right: 10px"
          >{{ inputContent.length }}/200</span
        >
        <el-button v-if="inputContent.length" @click="onSubmit" type="success"
          >提交</el-button
        >
      </template>
      <el-button v-else @click="Utils.goLogin" type="info">登录</el-button>
    </div>
    <main class="comment-list">
      <h3>全部评论 {{ total }}</h3>
      <section
        v-for="(comment, index) in list"
        :key="index"
        class="comment-item"
        :data-comment="comment.id"
      >
        <el-avatar :size="40" fit="cover" :src="comment.avatar" />
        <div class="comment-cell">
          <div class="comment-header">
            <span class="user-name">{{ comment.userName }}</span>
            <span class="time">{{
              Utils.dateFormat(comment.createtime, 'yyyy年MM月dd日 hh:mm')
            }}</span>
          </div>
          <p class="comment-content">
            {{ comment.content }}
          </p>
          <blockquote v-if="comment.replyContent" class="reply-quote">
            <b>@{{ comment.replyUserName }}:</b> &nbsp;{{
              comment.replyContent
            }}
          </blockquote>
          <div @click="reply(comment)" class="comment-footer t-pointer">
            <ck-icon class="icon-pinglun" />&nbsp;回复
          </div>
        </div>
      </section>
    </main>
    <CkLoadMore
      :size="10"
      noMoreTip="已加载全部评论"
      ref="$pagination"
      :api-handler="fetchList"
    />
  </el-card>
</template>
<script setup lang="ts">
import { ref, computed, nextTick, watch, type Ref } from 'vue';
import { useUserStore } from '@/stores/user';
import Utils from '@/utils';
import CommentsApi, { type CommentsType } from '@/apis/comments';
import { ElMessage } from 'element-plus';

export interface Props {
  title?: string;
  articleId?: string | number;
}

const userStore = useUserStore();
const props = withDefaults(defineProps<Props>(), {
  title: '评论',
  articleId: 0,
});
const $pagination = ref(null) as any;
const $formInp = ref(null) as any;
const list: Ref<CommentsType[]> = ref([]);
const replyContent = computed(() => {
  let output = '';
  for (let index = 0; index < list.value.length; index++) {
    const element = list.value[index];
    if (Number(element.id) === replyId.value) {
      output = `@${element.userName}:${element.content}`;
      break;
    }
  }
  return output;
});
const placeholder = computed(() => {
  const output = userStore.isLogin ? '输入评论（Enter换行）' : '登录后可评论';
  return Utils.notMac ? output : `${output},（⌘ + Enter发送）`;
});
const loading = ref(false);
const replyId = ref(0);
const total = ref(0);
const inputContent = ref('');
const initComments = (rid = 0) => {
  replyId.value = rid;
  inputContent.value = '';
  $pagination.value.fetch(1);
};
const onSubmit = async () => {
  if (loading.value) {
    return;
  }
  if (inputContent.value) {
    const params: {
      articleid: number | string;
      content: string;
      ua: string;
      replyid?: number | string;
    } = {
      content: inputContent.value,
      ua: navigator.userAgent,
      articleid: props.articleId,
    };
    if (replyId.value) {
      params.replyid = replyId.value;
    }
    loading.value = true;
    await CommentsApi.insert(params);
    loading.value = false;
    ElMessage.success('评论成功');
    initComments();
  }
};
const onKeyUp = (e: any) => {
  if (e.key.toLowerCase() === 'meta') {
    onSubmit();
  }
};
const reply = (item: CommentsType) => {
  replyId.value = Number(item.id);
  const vCommentTop =
    (document.querySelector('#v-comment')?.getBoundingClientRect().top || 0) +
    (document.querySelector('html')?.scrollTop || 0);
  window.scrollTo({ top: vCommentTop - 100, behavior: 'smooth' });
  $formInp.value.focus();
};
const deleteReply = () => {
  replyId.value = 0;
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res = await CommentsApi.frontList({
    offset: (index - 1) * size,
    limit: size,
    articleid: props.articleId,
  });
  list.value = index <= 1 ? res.list : [...list.value, ...res.list];
  loading.value = false;
  total.value = res.total;
  return {
    ...res,
  };
};
watch(
  () => props.articleId,
  async () => {
    await nextTick();
    initComments();
  }
);
</script>
<style scoped lang="scss">
$max-width: 1000px;
.comment-box {
  margin: 10px 0;
}
.comment-form {
  width: 100%;
  margin: 10px auto;
  max-width: $max-width;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.form-cell {
  flex: 1;
}
.user-cell {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 20px;
}
.user-name {
  margin-left: 5px;
}
.is-phone .user-cell {
  width: 100%;
  margin-bottom: 10px;
}
.comment-btns {
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: $max-width;
}
.dark {
  .reply-quote {
    max-width: $max-width;
    color: #ddd;
    background-color: #807f7f;
  }
}
.reply-quote {
  margin: 5px auto;
  position: relative;
  border-radius: 5px;
  padding: 5px 30px 5px 5px;
  background-color: #ddd;
  line-height: 1.3;
  font-size: 12px;
  color: #807f7f;
  > b {
    font-size: 14px;
  }
  .icon-close {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}
.comment-list {
  margin: 10px auto;
  width: 100%;
  max-width: $max-width;
}
.comment-item {
  display: flex;
  margin: 10px 0 30px 0;
}
.comment-cell {
  width: calc(100% - 40px);
  padding: 0 10px;
  font-size: 14px;
  .user-name {
    font-weight: bold;
  }
}
.comment-header {
  display: flex;
  align-items: center;

  justify-content: space-between;
  .time {
    font-size: 12px;
    font-weight: 700;
    opacity: 0.5;
  }
}
.comment-footer {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  opacity: 0.5;
}
.comment-content {
  // display: inline-block;
  margin: 5px 0;
  line-height: 1.5;
  opacity: 0.8;
  font-size: 14px;
  // background-color: var(--el-color-primary);
  // color: var(--el-color-white);
  // border-radius: 5px;
}
</style>
