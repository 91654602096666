import { promisefy } from '@/utils/req';

export default <any>{
  list(data = {}) {
    return promisefy(data, '/ajax/user/list');
  },
  add(data = {}) {
    return promisefy(data, '/ajax/user/add');
  },
  update(data = {}) {
    return promisefy(data, '/ajax/user/update');
  },
  edit(data = {}) {
    return promisefy(data, '/ajax/user/editmine');
  },
};
export const initUserItem = {
  account: '',
  id: 0,
  menus: [],
  name: '',
  age: 24,
  sex: '男',
  intro: '',
  type: 50,
  notapis: '',
  apis: '',
  okpages: '',
  search: 0,
  bg: '',
  avatar: 'https://cdn.zcxnb.cn/upload/Z0vv66FmeD.png',
};
//接口返回的数据字段类型
export type UserType = {
  account?: string;
  password?: string;
  okpages?: string;
  name: string;
  intro?: string;
  avatar?: string;
  id?: string | number;
  bg?: string;
  age?: number;
  menus?: string | Array<any>;
  apis?: number | string | null;
  notapis?: number | string | null;
  search: number | string;
  sex: string;
  type?: number | string;
  updatetime?: number | string;
  createtime?: number | string;
};
