<template>
  <ck-page class="cate-page">
    <el-table center v-loading="loading" :data="list" style="width: 100%">
      <el-table-column
        label="postid"
        prop="postid"
        width="100px"
      ></el-table-column>
      <el-table-column width="310px" label="标题">
        <template v-slot="scope">
          <h3>
            {{ scope.row.title }}
          </h3>
        </template>
      </el-table-column>
      <el-table-column label="封面" width="120px">
        <template v-slot="scope">
          <el-image
            v-if="scope.row.pic"
            fit="contain"
            :src="scope.row.pic"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="简介" prop="abstract"></el-table-column>
      <el-table-column width="190px" label="创建时间">
        <template v-slot="scope">
          <div>
            {{ Utils.dateFormat(scope.row.time) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100px" label="时间表示">
        <template v-slot="scope">
          <div>
            {{ scope.row.time_str }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150px" label="操作">
        <template v-slot="scope">
          <el-button size="small" @click="toPost(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="20" ref="$pagination" :api-handler="fetchList" />
    <Post ref="$postContent" />
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/ftnn/list',
    title: 'ftnn列表',
    enTitle: 'Ftnn List',
    auth: false,
    cache: false,
  },
  name: 'ftnnList',
};
</script>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { ftnnList } from '@/apis/sys';
import Utils from '@/utils';
import Post from './components/Post.vue';

const $postContent = ref(null) as any;
const $pagination = ref(null) as any;

const loading = ref(false);
const list = ref([]);
const search = () => {
  $pagination.value.fetch(1);
};
const toPost = async (item: any) => {
  $postContent.value.init(item);
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res = await ftnnList({
    offset: (index - 1) * size,
    limit: size,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
onMounted(() => {
  search();
});
</script>

<style scoped>
.btn {
  display: block;
  width: 50%;
  margin: 20px auto;
}
</style>
