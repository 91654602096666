import { promisefy } from '@/utils/req';
export default <any>{
  frontList(data = {}) {
    return promisefy(data, '/front/comments/list', 'get');
  },
  insert(data = {}) {
    return promisefy(data, '/front/comments/insert');
  },
};
//接口返回的数据字段类型
export type CommentsType = {
  id: number | string;
  content: string;
  userName: string;
  avatar: string;
  status?: number;
  title: string; //文章title
  authorid?: number | string;
  replyid?: number | string;
  articleid: number | string;
  replyUserAvatar?: string;
  replyUserName?: string;
  replyContent?: string;
  ua?: string;
  createtime?: number | string;
};
