<template>
  <ck-page footer header>
    <section default class="card-wrapper">
      <div class="card card-row">
        <h3 class="t-flex">
          <span style="font-size: 20px">站长手记📚</span>
          <span style="margin-right: 10px" @click="() => getPostData(true)">
            <ck-icon
              size="20"
              class="icon-fresh fresh-btn"
              :class="{ 'fresh-ani': postLoading }"
              title="点击刷新"
            />
          </span>
          <span v-if="realData && realData.cpus" @click="toAddLog"
            ><ck-icon title="点击添加" size="20" class="icon-plus t-pointer"
          /></span>
        </h3>
        <el-form @submit.prevent class="search-form">
          <el-form-item>
            <el-input
              placeholder="Please input"
              v-model="postParams.keyword"
              type="text"
              clearable
              @keyup.enter="() => getPostData(true)"
            >
              <template #append>
                <div class="t-flex">
                  <span @click="() => getPostData(true)"
                    ><ck-icon
                      title="搜索"
                      size="20"
                      class="icon-search t-pointer"
                  /></span>
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <template v-if="postList.length">
          <!-- <section
            class="card-post"
            v-for="(post, index) in postList"
            :key="index"
          > -->
          <TransitionGroup name="list" class="post-list" tag="div">
            <el-card
              v-for="(post, index) in postList"
              :key="index"
              class="card-post ck-blur-card-bg"
            >
              <div @click="toPost(post)" class="title t-pointer">
                {{ post.title }}
              </div>
              <div
                v-if="post.faceimg || post.subcon"
                class="con"
                @click="toPost(post)"
              >
                <el-image
                  v-if="post.faceimg"
                  class="cover"
                  :title="post.faceimg"
                  fit="contain"
                  :src="getQnImg(post.faceimg, ['thumbnail/300x'])"
                >
                  <template #error>
                    <div class="error-img">
                      <ck-icon size="30" class="icon-unview"></ck-icon>
                    </div>
                  </template>
                </el-image>
                <div v-if="post.subcon" class="subcon">{{ post.subcon }}</div>
              </div>
              <section class="t-flex">
                <div title="创建时间" class="time-cell">
                  <ck-icon class="icon-time" size="12" />&nbsp;{{
                    dateFormat(post.createtime, 'yyyy年MM月dd日 hh:mm')
                  }}
                </div>
                <div title="浏览量" class="time-cell view-cell">
                  <ck-icon class="icon-view" size="12" />&nbsp;{{
                    post.pageview
                  }}
                </div>
              </section>
            </el-card>
          </TransitionGroup>
          <!-- </section> -->
        </template>
        <div class="empty" v-else>
          无数据<span v-if="postParams.keyword"
            >-关键字：{{ postParams.keyword }}</span
          >
        </div>
        <el-button
          v-if="!postAllLoaded"
          @click="() => getPostData()"
          :loading="postLoading"
          round
          type="primary"
          style="margin-left: 30px"
          >{{ postLoading ? '加载中' : '加载更多' }}</el-button
        >
      </div>
      <section class="card card-one">
        <h3 class="t-flex">
          <span style="font-size: 20px">小挂件</span>
          <!-- <span @click="toAddLog"
            ><ck-icon title="点击添加" size="20" class="icon-plus t-pointer"
          /></span> -->
        </h3>
        <div class="t-space-10"></div>
        <el-card>
          <!-- <h4>小工具</h4> -->
          <div class="t-space-10"></div>
          <RouterLink class="tool-link" :to="{ path: '/tool_mosi' }"
            ><el-button type="danger">摩斯密码翻译器</el-button></RouterLink
          >
          <RouterLink class="tool-link" :to="{ path: '/ip' }"
            ><el-button type="success">我的ip地址</el-button></RouterLink
          >
          <RouterLink class="tool-link" :to="{ path: '/send' }"
            ><el-button type="primary">安全发送文本</el-button></RouterLink
          >
          <RouterLink class="tool-link" :to="{ path: '/picholder' }"
            ><el-button type="primary">图片占位</el-button></RouterLink
          >
        </el-card>
        <div class="t-space-10"></div>
        <el-card class="sticky">
          <div class="my-pc-wrap" v-if="realData && realData.cpus">
            <div class="my-pc">
              <ck-icon class="icon-tree" />
              <b>主机情况</b>
              <span @click="getRealData">
                <ck-icon
                  size="18"
                  class="icon-fresh fresh-btn"
                  :class="{ 'fresh-ani': sysInfoLoading }"
                  title="点击刷新"
                />
              </span>
            </div>
            <div class="item">
              <b>CPU:</b>{{ realData.cpus[0].model }}(数量：{{
                realData.cpus.length
              }})
            </div>
            <div class="item">
              <b>CPU 频率:</b>
              <span class="j-cpu">{{ realData.cpus[0].speed }}</span
              >MHZ
            </div>
            <div class="item">
              <b>ARCH:</b>
              <span>{{ realData.arch }}</span>
            </div>
            <div class="item">
              <b>总内存:</b>
              <span>{{ realData.totalmem }}MB</span>
            </div>
            <div class="item">
              <b>剩余内存:</b>
              <span>{{ realData.freemem }}MB</span>
            </div>
            <div class="item">
              <b>总存储:</b>
              <span>{{ realData.alldisk }}</span>
            </div>
            <div class="item">
              <b>已使用存储:</b>
              <span>{{ realData.usedisk }}</span>
            </div>
            <div class="item">
              <b>系统:</b>
              <span>{{ realData.type }} {{ realData.release }}</span>
            </div>
            <div class="item">
              <b>系统时间:</b>
              <span>{{ dateFormat(realData.nowtime) }}</span>
            </div>
          </div>
          <div class="my-pc-wrap">
            <div class="my-pc">
              <ck-icon class="icon-shouji" /><b>浏览器信息</b>
            </div>
            <div class="item t-pointer" v-copy="ua" title="点击可复制">
              <b>UA:</b>{{ ua }}
            </div>
          </div>
        </el-card>
        <div class="t-space-10"></div>
      </section>
    </section>
  </ck-page>
</template>

<script lang="ts">
import { defineComponent, inject, ref, reactive } from 'vue';
import { userStoreInject, layoutStoreInject } from '@/contants';
import Utils from '@/utils';
import { one, getSysInfo } from '@/apis/sys';
import ArticleApi from '@/apis/article';
import Bus from '@/utils/bus';
import type { ArticleType } from '@/apis/article';
let timer: any;
const postList: Array<ArticleType> = [];
// const initPostListWaterFall: Array<ArticleType[]> = [[], [], []];
export default defineComponent({
  meta: {
    path: '/',
    title: '首页',
    enTitle: 'Home',
    auth: false,
    cache: true,
  },
  name: 'home',
  setup() {
    return {
      postParams: reactive({
        keyword: '',
        offset: 0,
        limit: 12,
      }),
      postList: ref(postList),
      // postListWaterFall: ref(Utils.clone2(initPostListWaterFall)),
      sysInfoLoading: ref(false),
      postLoading: ref(false),
      postAllLoaded: ref(false),
      layoutStore: inject(layoutStoreInject) as any,
      userStore: inject(userStoreInject) as any,
      // one: ref(null) as any,
      ua: navigator.userAgent,
      realData: ref(null) as any,
    };
  },
  methods: {
    toPost(post: ArticleType) {
      Utils.goPage('/post/' + post.id);
    },
    toAddLog() {
      Utils.goPage('/editpost');
    },
    getQnImg(url = '', params = [''], keepParams = true): string {
      return Utils.getQnImg(url, params, keepParams);
    },
    dateFormat(stamp: any, format = 'yyyy-MM-dd hh:mm:ss'): string {
      return Utils.dateFormat(stamp, format);
    },
    async getRealData() {
      if (this.sysInfoLoading || !this.userStore.isLogin) {
        return;
      }
      this.sysInfoLoading = true;
      const realDataRes = await getSysInfo();
      this.realData = realDataRes;
      await Utils.sleep(1);
      this.sysInfoLoading = false;
      clearInterval(timer);
      if (this.realData) {
        timer = setInterval(() => (this.realData.nowtime += 1000), 1000);
      }
    },
    async fetchData() {
      this.getRealData();
      this.getPostData();
      // const oneRes = await one();
      // this.one = oneRes;
    },
    async getPostData(init = false) {
      if (this.postLoading) {
        return;
      }
      this.postLoading = true;
      if (init) {
        this.postParams.offset = 0;
      }
      try {
        const res = await ArticleApi.frontList(this.postParams);
        this.postList = init ? res.list : this.postList.concat(res.list);
        this.postAllLoaded = res.total <= this.postList.length;
        this.postParams.offset += this.postParams.limit;
      } catch (error) {}
      await Utils.sleep(1);
      this.postLoading = false;
    },
    randomType(index: any): string {
      return ['danger', 'warning', 'info'][index % 3] || 'info';
    },
  },
  mounted() {
    this.fetchData();
    Bus.on('updateMonent', () => {
      this.getPostData(true);
    });
  },
  unmounted() {
    clearInterval(timer);
  },
});
</script>

<style lang="scss" scoped>
.search-form {
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
}
/* vue transition 动画*/
.list-enter-active {
  transition: opacity 0.25s ease-out;
}
.list-enter-from {
  opacity: 0;
}
.list-enter-to {
  opacity: 1;
}
.time-cell {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
.card-wrapper {
  --el-component-size: 40px;
  --el-border-radius-base: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .card {
    margin: 10px 0;
    max-width: 450px;
    min-width: 300px;
  }
  .card-row {
    max-width: 1800px;
    flex: 1;
  }
  .card-one {
    width: 25%;
  }
}
.con {
  display: flex;
  align-items: top;
}
.one,
.sticky {
  position: sticky;
  top: 100px;
}
@media (max-width: 1024px) {
  .one {
    position: static;
  }
}
.is-phone {
  .card-wrapper {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    .card-one {
      width: 100%;
    }
  }
  .post-list {
    display: block;
    transition: all 0.2 ease-in-out;
    .card-post {
      width: 100%;
    }
  }
  .card {
    margin: 10px auto;
    max-width: 100%;
    // min-width: 300px;
  }
  .card-row {
    max-width: 100%;
  }
}
.cover-big {
  display: block;
  width: 100%;
  min-height: 50px;
}

.error-img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fefefe;
  color: #999;
}
.one-context {
  color: #777;
  font-size: 14px;
  padding: 10px;
}

.fresh-btn {
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
}
.my-pc-wrap {
  padding: 10px 0 0 0;
  .my-pc {
    padding: 5px 0;
  }
  > .item {
    padding: 5px 0;
  }

  b {
    font-weight: bold;
  }
}
.fresh-ani {
  transform-origin: center center;
  animation-name: rotateX;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotateX {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.post-list {
  margin: 20px auto;
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, minmax(300px, 33.3%));
  .card-post {
    margin: 0 10px 10px 0;
  }
  .view-cell {
    margin-left: 10px;
  }
  .title {
    padding: 10px 0 10px 0;
    font-size: 20px;
    font-weight: bold;
    color: #666;
    line-height: 1.2;
    &:hover {
      text-decoration: underline;
    }
  }
  .subcon {
    margin-left: 10px;
    font-size: 13px;
    word-wrap: break-word;
    color: #888;
    width: 65%;
  }
  .cover {
    width: 35%;
    cursor: pointer;
  }
}
.dark {
  .title {
    color: #eee;
  }
  .subcon {
    color: #999;
  }
  .one-context {
    color: #ddd;
  }
  .error-img {
    background: #333;
    color: #fff;
  }
}
.tool-link {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
