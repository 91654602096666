<template>
  <ck-page class="material-page">
    <Material preview />
  </ck-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Material from '@/components/Material/index.vue';
export default defineComponent({
  meta: {
    path: '/material',
    title: '素材库',
    enTitle: 'Material',
    auth: true,
    cache: false,
  },
  components: { Material },
  name: 'material',
  setup() {
    return {};
  },
});
</script>

<style></style>
