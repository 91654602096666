import axios from 'axios';
import JSEncrypt from 'jsencrypt';

import { promisefy } from '@/utils/req';
import { UserRsaPubKey } from '@/contants';
function encryptData(data: any) {
  if (data.account) {
    const crypt = new JSEncrypt();
    crypt.setKey(UserRsaPubKey);
    data.account = crypt.encrypt(data.account);
  }
  if (data.psw) {
    const crypt = new JSEncrypt();
    crypt.setKey(UserRsaPubKey);
    data.psw = crypt.encrypt(data.psw);
  }
  return data;
}
export function getDataByCode(data: any): Promise<any> {
  return promisefy(data, '/front/resource/get', 'get', {
    noToken: true,
  });
}
export function sendRegisterCode(data: any): Promise<any> {
  data = encryptData(data);
  return promisefy(data, '/ajax/sendRegisterCode');
}

export function login(data: any): Promise<any> {
  data = encryptData(data);
  return promisefy(data, '/ajax/login');
}
export function resetPsw(data: any): Promise<any> {
  data = encryptData(data);
  return promisefy(data, '/ajax/resetPsw');
}
export function register(data: any): Promise<any> {
  data = encryptData(data);
  return promisefy(data, '/ajax/register');
}
export function getUserConf(): Promise<any> {
  return promisefy({}, '/ajax/userConf');
}
export function loginOut(): Promise<any> {
  return promisefy({}, '/ajax/loginout');
}

export function one(data = {}) {
  return promisefy(data, '/ajax/one', 'get');
}
export function hito(data: any) {
  return promisefy(data, '/ajax/hito', 'get');
}
export function getQiniuToken(data = {}): Promise<any> {
  return promisefy(data, '/ajax/qiniu/token');
}
export function getSysInfo() {
  return promisefy({}, '/ajax/sysinfo', 'get', { silent: true });
}
export function uploadQiniu(formdata: any) {
  return new Promise<any>(async (resolve) => {
    const res = await axios.post('https://up-z0.qiniup.com', formdata);
    resolve(import.meta.env.VITE_APP_QINIU_PREFIX + res.data.key);
  });
}
export function textToAudio(data = {}) {
  return promisefy(data, '/ajax/text2audio', 'get');
}
export function textToAudioLog(data = {}) {
  return promisefy(data, '/ajax/textaudios/log', 'get');
}
export function textToAudioRemove(data = {}) {
  return promisefy(data, '/ajax/textaudios/remove');
}

export function ftnnList(data = {}): Promise<any> {
  return promisefy(data, '/ajax/ftnn/list', 'get');
}
export function ftnnPost(data = {}): Promise<any> {
  return promisefy(data, '/ajax/ftnn/post', 'get');
}
export function getAgreement(data = {}): Promise<any> {
  return promisefy(data, '/ajax/agreement', 'get');
}
export function getMyIp(data = {}): Promise<any> {
  return promisefy(data, '/front/ip', 'get');
}
export function chatCreate(data = {}): Promise<any> {
  return promisefy(data, '/ajax/chat');
}
export function chatConf(data = {}): Promise<any> {
  //获取还有多少提问的次数等配置
  return promisefy(data, '/ajax/chat/conf');
}
//加密信息
export function sendText(data = {}): Promise<any> {
  return promisefy(data, '/ajax/stext/send');
}
//加密信息
export function getText(data = {}): Promise<any> {
  return promisefy(data, '/front/stext/get');
}
