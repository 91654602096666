<template>
  <ck-page>
    <el-table :data="list" stripe>
      <el-table-column prop="id" label="ID" width="70" />
      <el-table-column prop="nick_name" label="头像">
        <template v-slot="scope">
          <el-avatar :size="60" fit="cover" :src="scope.row.avatar" />
        </template>
      </el-table-column>
      <el-table-column prop="nick_name" label="用户名" />
      <el-table-column prop="api_key" label="指令码">
        <template v-slot="{ row }">
          {{ row.api_key || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="nick_name" label="创建时间">
        <template v-slot="scope">
          {{ dayjs(scope.row.createdAt).format('YYYY年MM月DD日 HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="nick_name" label="更新时间">
        <template v-slot="scope">
          {{ dayjs(scope.row.updatedAt).format('YYYY年MM月DD日 HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="ip" label="ip" width="150">
        <template v-slot="scope">
          <div v-if="scope.row.ip">
            <span
              class="t-pointer"
              title="跳转查看ip详细"
              style="padding-right: 5px"
              @click="toIpInfo(scope.row.ip)"
              >{{ scope.row.ip }}</span
            >
            <ck-icon
              class="t-pointer icon-publish"
              v-copy="scope.row.ip"
            ></ck-icon>
          </div>

          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
    <CkPagination ref="$pagination" :api-handler="fetchList" />
  </ck-page>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import JzApi from '@/apis/jz';

defineOptions({
  name: 'JzUsers',
  meta: {
    title: '记账小程序用户列表',
    enTitle: 'Jz Users',
    path: '/jz/user',
    auth: true,
  },
});
const $pagination = ref(null) as any;
const list = ref([]);
const loading = ref(false);
const fetchList = async (index = 0, size = 0) => {
  loading.value = true;
  const res = await JzApi.getUserList({
    offset: (index - 1) * size,
    limit: size,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const search = () => {
  $pagination.value.fetch(1);
};
const toIpInfo = (ip: string) => {
  window.open(`https://ipinfo.io/${ip}`);
};
onMounted(() => {
  search();
});
</script>
