const getViewFrame = function () {
  return document.getElementById('topicViewFrame');
};
export default <{ [propsName: string]: any }>{
  // iframe与父页面的通信
  hasInit: false,
  $child: null, // iframe对象
  init() {
    // 使用其他方法前先init
    const $child = getViewFrame();
    this.hasInit = false;
    return new Promise<void>((resolve) => {
      if (!$child) {
        return resolve();
      }
      $child.onload = (): void => {
        console.log('iframe is load over');
        this.$child = $child;
        this.hasInit = true;
        resolve();
      };
    });
  },
  post(msg: object): void {
    // msg是对象
    if (!this.hasInit) {
      return;
    }
    // console.warn('post msg', msg);
    this.$child.contentWindow.postMessage(
      Object.assign({ name: 'topic', arrays: [] }, msg),
      '*'
    );
  },
  parseData(data: any, ctx: any): void {
    if (data.name !== 'topic') {
      return;
    }

    if (data.arrays && data.arrays.length > 0) {
      data.arrays.map((item: any) => {
        ctx[item.key].value = item.value;
      });
    }
    if (data.key) {
      ctx[data.key].value = data.value;
    }
    // console.warn('message from iframe ', data);
  },
};
