import { createApp } from 'vue';
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import { Icon as VantIcon } from 'vant';
import Logo from '@/components/Logo.vue';
import CkIcon from '@/components/CkIcon.vue';
import CkPagination from '@/components/CkPagination.vue';
import CkPage from '@/components/CkPage.vue';
import CkLoadMore from '@/components/CkLoadMore.vue';
import CkWaterMark from '@/components/CkWaterMark.vue';
import i18n from '@/locale';
//引入 nprogress
import NProgress from 'nprogress'; // 进度条
import 'nprogress/nprogress.css'; // 引入样式
import '@/styles/tailwind.css'; // 引入tailwind样式
//引入自定义指令
import MyDirectives from '@/plugins/directives';
import App from './App.vue';
import setRouter from './router';
import Utils from './utils';
import { useLayoutStore } from '@/stores/layout';
import { useUserStore } from '@/stores/user';
import { useRouteStore } from '@/stores/route';
import { useLogStore, LogEnum } from '@/stores/log';
import { AppEnum } from '@/contants';
import 'vant/lib/icon/style';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import './styles/app.scss';
import './styles/iconfont/iconfont.css';
// import Vconsole from 'vconsole';

// new Vconsole();
(async () => {
  // 这里为啥要用async 我有必要说下：
  // 因为自动生成router的原因，是个异步操作获取相关信息

  window.CkUtils = Utils;
  const app = createApp(App);
  // app.config.unwrapInjectedRef = true;
  app.use(createPinia());

  const router = await setRouter();
  window.CkRouter = router;
  app.use(router);
  app.use(i18n);
  app.use(VantIcon);
  app.use(ElementPlus);
  MyDirectives(app); //注入自定义指令
  //注册全局组件
  app.component('CkIcon', CkIcon);
  app.component('CkPage', CkPage);
  app.component('CkLoadMore', CkLoadMore);
  app.component('CkPagination', CkPagination);
  app.component('CkWaterMark', CkWaterMark);
  app.component('Logo', Logo);
  app.mount('#app');
  const layoutStore = useLayoutStore();
  const logStore = useLogStore();
  const userStore = useUserStore();
  const routeStore = useRouteStore();
  routeStore.setAllRoutes(router.getRoutes());
  //userStore.fetchUser(); //获取用户信息
  // layoutStore.setAsideMenu(JSON.parse(res.menus));
  /**收集错误
   */
  app.config.errorHandler = (err: any, _instance, info) => {
    console.error('vue err', err);
    // 处理错误，例如：报告给一个服务
    logStore.add({
      info: err.message || '未知错误',
      err,
      type: LogEnum.Err,
      url: location.href,
      title: info,
    });
  };
  window.addEventListener('error', (event) => {
    console.error('window error', event);
  });
  // 简单配置NProgress
  NProgress.inc(0.2);
  NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });

  // 进度条开始
  router.beforeEach(async (to, from, next) => {
    NProgress.start();
    routeStore.setCurrentRoute(to);
    // // 根据用户信息判断那些页面可以访问
    if (!userStore.isFetch) {
      try {
        await userStore.fetchUser();
      } catch (e) {
        next();
      }
    }
    if (!!userStore.okpages && to.path !== '/noauth') {
      let hasAuth = false;
      userStore.okpages.split(';').map((page) => {
        if (new RegExp(page).test(to.path)) {
          hasAuth = true;
        }
      });
      if (!hasAuth) {
        return router.replace(`/noauth?t=${encodeURIComponent(to.fullPath)}`);
      }
    }
    next();
  });

  // 进度条结束
  router.afterEach(async (to) => {
    if (to.path !== '/noauth') {
      Utils.setTitle(`${AppEnum.title}-${to.meta.title || '未知页面'}`);
      routeStore.addCache(to);
      layoutStore.updateAsideMenuActive();
    }
    if (!to.meta.cache) {
      window.scrollTo({ top: 0, behavior: 'instant' });
    }
    NProgress.done();
  });
  // 设置一些页面布局的操作
  /** 设置暗黑还是浅色模式
   * 设置win滚动条
   */
  if (Utils.notMac) {
    document.querySelector('html')?.classList.add('ck-scrollbar');
  }
  layoutStore.initUi();
  //element-plus 报错 ResizeObserver loop limit exceeded 解决 ：https://blog.csdn.net/qq_33733799/article/details/129873731
  const debounce = (fn: any, delay: any) => {
    let timer: any = null;
    return function (...args: any) {
      //@ts-ignore
      const context: any = this;
      clearTimeout(timer);
      // console.warn('ResizeObserver start')
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  };
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback: any) {
      callback = debounce(callback, 16);
      super(callback);
    }
  };
})();
