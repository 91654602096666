<script setup lang="ts">
import { ref, computed } from 'vue';
import CrawlerApi from '@/apis/crawler';
import { ElMessage } from 'element-plus';
import type { CrawlerType, CrawlerPostType } from '@/apis/crawler';
const emit = defineEmits(['success']);
const visible = ref(false);
const title = ref('标题');
const item = ref(null) as any;
const desc = ref('');
const id = ref(0) as any;
const content = computed(() => {
  if (!item.value) return '';
  const coverStr = item.value.cover
    ? `\n[upl-image-preview url=${item.value.cover}]\n`
    : ``;
  const footStr = `\n**链接：**[reply]${item.value.share_url}[/reply]`;
  return `${coverStr}${desc.value}${footStr}`;
});
const loading = ref(false);
const init = async (itemParams: CrawlerType) => {
  visible.value = true;
  item.value = itemParams;
  loading.value = false;
  if (itemParams.id !== id.value) {
    id.value = itemParams.id;
    title.value = itemParams.title;
    const descStr = `\n**名称：**${itemParams.title}\n\n**描述：**${itemParams.desc}\n`;
    desc.value = descStr;
    // filterData(itemParams);
  }
};
const toPublish = async () => {
  const params: CrawlerPostType = {
    id: id.value,
    title: title.value,
    publish: 1,
    content: content.value,
  };
  loading.value = true;
  const res = await CrawlerApi.publish(params);
  loading.value = false;
  visible.value = false;
  ElMessage.success('发布成功');
  emit('success');
};
defineExpose({
  init,
});
</script>

<template>
  <el-dialog width="70%" :title="title" v-model="visible">
    <div v-loading="loading" v-if="id">
      <h3>标题</h3>
      <el-input v-model="title" />
      <h3>描述</h3>
      <el-input :rows="7" v-model="desc" type="textarea" />
      <div class="html-content">
        <h3>生成的flarum内容</h3>
        <br />
        <code>{{ content }}</code>
      </div>
      <el-button @click="toPublish" round type="primary"
        >确定内容，并发送到flarum</el-button
      >
      <el-button round type="danger" @click="() => (visible = false)"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.t {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    padding: 0 20px 0 0;
    color: #999;
  }
}
.tips {
  color: #999;
  text-align: center;
  padding: 10px;
}
.cover {
  max-width: 200px;
  margin: 10px auto;
}
</style>
