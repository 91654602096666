import { promisefy } from '@/utils/req';

export default <any>{
  get(data = {}) {
    return promisefy(data, '/ajax/roleConf');
  },
  list(data = {}) {
    return promisefy(data, '/ajax/role/list');
  },
  update(data = {}) {
    return promisefy(data, '/ajax/role/update');
  },
};
export const initRoleItem = {
  id: 0,
  type: 0,
  notapis: '',
  apis: '',
  okpages: '',
  search: 0,
};
//接口返回的数据字段类型
export type RoleType = {
  okpages?: string;
  intro?: string;
  id?: string | number;
  menus?: string | Array<any>;
  apis?: number | string | null;
  notapis?: number | string | null;
  search: number | string;
  type?: number | string;
  updatetime?: number | string;
  createtime?: number | string;
};
