<script lang="ts">
export default {
  meta: {
    path: '/post/:id',
    title: '文章详情',
    enTitle: 'Post Detail',
    auth: false,
    cache: false,
  },
  name: 'postDetail',
};
</script>
<script setup lang="ts">
import {
  ref,
  nextTick,
  watch,
  inject,
  onMounted,
  onUnmounted,
  type Ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import Clipboard from 'clipboard';
import hl from 'highlight.js'; // 导入代码高亮文件
import 'highlight.js/styles/vs2015.css'; // 导入代码高亮样式
import 'viewerjs/dist/viewer.css'; //预览图片样式
import { routeStoreInject } from '@/contants';
import ArticleApi from '@/apis/article';
import type { ArticleType } from '@/apis/article';
import Utils from '@/utils';
import PostComment from '@/components/PostComment.vue';
import Share from './components/Share.vue';
import {
  getTitleTree,
  scrollTo,
  setPostScroll,
  rmPostScroll,
  viewPic,
} from './postHelper';
import CkIcon from '@/components/CkIcon.vue';

const id = ref(0) as any;
let scrollFn: any;
const detail: Ref<ArticleType> = ref({
  faceimg: '',
  subcon: '',
  content: '',
  pageview: 0,
  catename: '',
  updatetime: '',
  createtime: '',
  id: '',
  title: '',
  cateid: '',
});
const directory = ref([]) as any;
const dirActiveIndex = ref(-1);
const $share = ref(null) as any;
const routeStore = inject(routeStoreInject) as any;
const $route = useRoute();
let picViewer: any;
const fetchData = async () => {
  rmPostScroll(window, scrollFn);
  const res = await ArticleApi.frontGet({
    id: id.value,
  });
  ArticleApi.frontPageview({ id: id.value });
  detail.value = res;
  routeStore.value.updateCache({ title: `文章:${res.title}` });
  Utils.setTitle(res.title);
  await nextTick();
  picViewer && picViewer.destroy();
  picViewer = viewPic();
  document.querySelectorAll('.html-content pre>code').forEach((el: Element) => {
    //格式化代码内容
    let lang = 'html';
    el.classList.forEach((className: string) => {
      const langClass = className.match(/^language-(\S+)$/) as any;
      lang = langClass.length > 0 ? langClass[1] : 'html';
    });
    const div = document.createElement('div');
    div.className = 'code-btn t-pointer';
    div.innerText = '复制代码';
    let clipboard: any;
    //解决clipboard第一次点击不生效问题，那就在enter时实例化Clipboard
    div.addEventListener('mouseenter', () => {
      clipboard = new Clipboard(div, {
        text: () => (el as HTMLElement).innerText,
      });
    });
    div.addEventListener('click', () => {
      clipboard.on('success', () => {
        ElMessage({
          message: '复制成功',
          type: 'success',
        });
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        ElMessage({
          message: '复制失败',
          type: 'error',
        });
        clipboard.destroy();
      });
    });
    el.parentElement?.appendChild(div);
    hl.configure({
      languages: [lang],
    });
    hl.highlightElement(el as HTMLElement);
  });
  await Utils.sleep(0.5); //加个定时器，等dom渲染稳定
  directory.value = getTitleTree(document.querySelector('.html-content'));
  if (directory.value.length > 0) {
    const className = directory.value[0].className;
    const debouncedHandleInput = (id: string) => {
      dirActiveIndex.value = Number(id.replace(`${className}-`, ''));
    };
    scrollFn = setPostScroll(
      window,
      document.querySelectorAll(`.${className}`) as any,
      debouncedHandleInput
    );
  }
};
const scrollToTop = () => {
  scrollTo('j-post');
};
const openShare = () => {
  $share.value.init();
};
const init = async () => {
  if (!$route.params.id) {
    return;
  }
  if ($route.params.id !== id.value) {
    id.value = $route.params.id;
    fetchData();
  }
};
onUnmounted(() => {
  rmPostScroll(window, scrollFn);
  // rmDomObserver(observer);
});
onMounted(init);
watch(
  () => $route.params,
  async () => {
    if ($route.name !== 'postDetail') {
      return;
    }
    init();
    // rmDomObserver(observer);
  }
);
onMounted(init);
watch(
  () => $route.params,
  async () => {
    if ($route.name !== 'postDetail') {
      return;
    }
    init();
  }
);
</script>

<template>
  <ck-page footer>
    <div class="post-page" id="j-post">
      <main class="post-container" v-loading="!detail.id" v-if="detail">
        <!-- <template v-if="!detail.id"> -->
        <div v-if="!detail.content" class="tips">空无内容</div>
        <div class="post-cell ck-blur-card-bg" v-else>
          <h2 class="title">{{ detail.title }}</h2>

          <p class="t">
            <span
              ><ck-icon size="12" class="icon-view" />&nbsp;{{
                detail.pageview
              }}</span
            >
            <span
              ><ck-icon size="12" class="icon-time" />&nbsp;{{
                Utils.dateFormat(detail.createtime, 'yyyy年MM月dd日 hh:mm')
              }}</span
            >
            <span
              >最后更新于：{{
                Utils.dateFormat(detail.updatetime, 'yyyy年MM月dd日 hh:mm')
              }}</span
            >
          </p>
          <el-image
            v-if="detail.faceimg"
            class="cover"
            :src="detail.faceimg"
          ></el-image>
          <p class="subcon" v-if="detail.subcon">简介：{{ detail.subcon }}</p>
          <div
            class="html-content tracking-[0.1em]"
            style="border: none"
            v-html="detail.content"
          ></div>
        </div>
        <PostComment :articleId="id" />
        <!-- </template> -->
      </main>
      <main class="post-widget ck-blur-card-bg">
        <div class="post-widget-item sticky">
          <h3>目录</h3>
          <ul class="directory" v-if="directory.length">
            <li
              class="t-pointer"
              :class="{ active: index <= dirActiveIndex }"
              :title="item.titleText"
              :style="{ paddingLeft: (item.level - 1) * 10 + 'px' }"
              v-for="(item, index) in directory"
              :key="item.id"
              @click="scrollTo(item.id)"
            >
              {{ item.titleText }}
            </li>
          </ul>
          <el-empty v-else description="暂无目录" />
        </div>
      </main>
      <section
        class="side fixed bottom-[50px] lg:right-[10px] right-[3px] flex flex-col justify-center"
      >
        <div class="side-btn v2" @click="openShare">
          <CkIcon class="icon-share" size="20" />
        </div>
        <div class="side-btn" @click="scrollToTop">
          <CkIcon class="icon-top" size="20" />
        </div>
      </section>
    </div>
    <Share :post="detail" ref="$share" />
  </ck-page>
</template>

<style lang="scss" scoped>
.dark {
  .post-cell {
    background-color: var(--el-card-bg-color, #333);
  }
}
.post-cell {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #fff;
}
.subcon {
  margin-bottom: 20px;
  border: 1px solid #c9c8c8;
  border-left-width: 10px;
  display: block;
  font-size: 100%;
  line-height: 1.5;
  word-break: break-all;
  margin: 10px 0;
  opacity: 0.7;
  padding: 10px;
}
.title {
  font-size: 24px;
  margin: 20px auto;
  font-weight: 700;
}
.t {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  font-size: 13px;
  > span {
    padding: 0 20px 0 0;
    color: #999;
  }
}
.tips {
  color: #999;
  text-align: center;
  padding: 10px;
}
.post-page {
  width: 100%;
  @apply mx-auto flex max-w-[1400px];
  justify-content: space-between;
}
.post-container {
  width: 77%;
  @media (max-width: theme('screens.lg')) {
    width: 100%;
  }
}
.post-widget {
  width: 23%;
  max-width: 300px;
  margin-left: 10px;
  @media (max-width: theme('screens.lg')) {
    display: none;
  }
  &-item {
    padding: 15px;
    border-radius: 10px;
    background-color: var(--el-card-bg-color, #fff);
    &.sticky {
      position: sticky;
      top: 100px;
      z-index: 111;
    }
  }
  .directory {
    li {
      @include clamp(1);
      font-size: 14px;
      line-height: 1.7;
      text-indent: 0.5em;
      border-left: 3px solid transparent;
      &:hover {
        color: var(--el-color-primary);
      }
      &.active {
        border-color: var(--el-color-primary);
        color: var(--el-color-primary);
      }
    }
  }
}
.cover {
  max-width: 200px;
  margin: 10px auto;
}
.side {
  &-btn {
    background-color: var(--el-color-warning);
    @apply rounded-[50%] w-[40px] h-[40px] mb-[10px] text-[#fff] cursor-pointer flex items-center justify-center;
    &.v2 {
      background-color: var(--el-color-danger);
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
