<template>
  <ck-page class="chat-page">
    <AiChat />
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/chat',
    title: 'AI聊天',
    enTitle: 'AI Chat',
    auth: true,
    cache: true,
  },
  name: 'aiChat',
};
</script>
<script lang="ts" setup>
import AiChat from '@/components/AiChat.vue';
</script>

<style lang="scss" scoped></style>
