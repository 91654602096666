<template>
  <div class="material-page">
    <h4>ACG素材库</h4>
    <div class="t-space-10"></div>
    <el-card
      :body-style="{ padding: '15px' }"
      shadow="never"
      class="box-card"
      v-if="loading || list.length > 0"
    >
      <div class="item-warp" v-loading="loading">
        <div class="item" v-for="(item, index) in list" :key="index">
          <ImageItem :preview="preview" :item="item" @choose="choose(item)" />
        </div>
      </div>
    </el-card>
    <div class="t-tips" v-else>无相应素材</div>
    <CkPagination ref="pagination" :api-handler="fetchList" />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import MaterialApi from '@/apis/material';
import ImageItem from './ImageItem.vue';
import type { MaterialType } from '@/apis/material';
let $pagination: any;
export default defineComponent({
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageItem,
  },
  setup() {
    const list: Array<MaterialType> = [];
    return {
      list: ref(list),
      loading: ref(false),
    };
  },
  mounted() {
    $pagination = this.$refs['pagination'] as any;
    $pagination.fetch(1);
  },
  methods: {
    reset() {
      this.search();
    },
    search(index = 1) {
      $pagination.fetch(index);
    },
    async updateName(item: { id: string | number; name: string }) {
      await MaterialApi.update(item);
      this.$message.success('更新成功！');
      $pagination.fetch();
    },
    choose(item: MaterialType) {
      // console.log('item', item);
    },
    async fetchList(index = 0, size = 0): Promise<any> {
      this.loading = true;
      const res = await MaterialApi.getAcgList({
        offset: (index - 1) * size,
        limit: size,
      });
      this.list = res.list;
      this.loading = false;
      return {
        ...res,
      };
    },
  },
});
</script>
<style lang="scss" scoped>
.item-warp {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 10px;
  .upload-img-cell {
    margin-bottom: 5px;
  }
}
.upload-btn {
  padding-bottom: 10px;
}
</style>
