<template>
  <section class="resource-edit">
    <el-form label-width="60px">
      <el-form-item label="id" v-if="detail.id">
        <el-input disabled clearable v-model="detail.id" />
      </el-form-item>
      <el-form-item label="code">
        <el-input
          clearable
          placeholder="便于记住的英文或数字的组合"
          v-model="detail.code"
          :disabled="detail.id > 0"
        >
          <template #append v-if="detail.code">
            <span v-copy="detail.code"
              ><ck-icon class="icon-publish t-pointer"
            /></span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="说明">
        <el-input clearable placeholder="该数据的用途" v-model="detail.desc" />
      </el-form-item>
      <el-form-item label="状态">
        <el-radio v-model="detail.useable" :label="1">启用</el-radio>
        <el-radio v-model="detail.useable" :label="0">不启用</el-radio>
      </el-form-item>
      <el-form-item required label="数据">
        <JsonModal
          ref="jsonModal"
          v-if="detail"
          :json-text="detail.data || ''"
        />
      </el-form-item>
    </el-form>

    <div class="t-space-20"></div>
    <div class="t-right">
      <el-button round size="large" type="primary" @click="save"
        >保存</el-button
      >
    </div>
  </section>
</template>
<script lang="ts" setup>
import JsonModal from '@/components/JsonModal.vue';
import { ref, watch, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import ResourceApi from '@/apis/resource';
import type { ResourceType } from '@/apis/resource';
const props: any = defineProps({
  item: Object,
});
const emit = defineEmits(['success']);
const resourceDetail: ResourceType = {
  data: '{}',
  code: '',
  id: 0,
  useable: 1,
  desc: '',
};
const detail = ref(resourceDetail);
const jsonModal = ref(null) as any;
const save = async () => {
  const saveData: ResourceType = {
    createtime: Date.now(),
    ...detail.value,
    data: JSON.stringify(jsonModal.value.getJsonFromEditor()),
    updatetime: Date.now(),
  };
  if (detail.value.id) {
    // saveData.id = detail.value.id;
    await ResourceApi.edit(saveData);
  } else {
    await ResourceApi.add(saveData);
  }
  emit('success', saveData);
  ElMessage.success('保存成功');
};
const init = async (item: { id: number | string } | null) => {
  if (!item) {
    detail.value = resourceDetail;
    return;
  }
  const res = await ResourceApi.get(item);
  detail.value = res;
};
onMounted(() => {
  init(props.item);
});
watch(props, (val) => {
  init(val.item);
});
</script>
<style lang="scss" scope></style>
