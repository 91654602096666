<template>
  <ck-page class="posts-page">
    <div style="padding-bottom: 20px">
      <el-radio v-model="params.view" @change="search" :label="-1"
        >全部</el-radio
      >
      <el-radio v-model="params.view" @change="search" :label="1"
        >公开文章</el-radio
      >
      <el-radio v-model="params.view" @change="search" :label="0"
        >私密文章</el-radio
      >
    </div>
    <el-form :inline="true" :model="params">
      <el-form-item label="文章分类">
        <el-select v-model="params.cateid" placeholder="请选择">
          <el-option
            v-for="item in cates"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文章标题">
        <el-input v-model="params.keyword" @keyup.enter="search" clearable />
      </el-form-item>
      <el-form-item>
        <el-button round type="success" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="list" border>
      <el-table-column label="id" prop="id" width="70px"></el-table-column>
      <el-table-column label="封面" width="120px">
        <template v-slot="scope">
          <el-image
            v-if="scope.row.faceimg"
            fit="contain"
            :src="scope.row.faceimg"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="标题" width="200px">
        <template v-slot="scope">
          <div
            @click="toRead(scope.row)"
            style="text-align: center; cursor: pointer"
          >
            {{ decodeURIComponent(scope.row.title) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="所属分类"
        width="150px"
        prop="catename"
      ></el-table-column>
      <el-table-column label="可见" width="150px">
        <template v-slot="scope">
          {{ scope.row.view ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="浏览量" prop="pageview"></el-table-column>
      <el-table-column label="操作" width="260px">
        <template v-slot="scope">
          <el-button size="small" @click="toComment(scope.row)"
            >文章评论</el-button
          >
          <el-button size="small" @click="toEdit(scope.row)">编辑</el-button>
          <el-popconfirm
            v-if="scope.row.id > 0"
            @confirm="toDel(scope.row)"
            title="此操作将永久删除文章?"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="200px">
        <template v-slot="scope">
          {{ dateFormat(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间" width="200px">
        <template v-slot="scope">
          {{ dateFormat(scope.row.updatetime) }}
        </template>
      </el-table-column>
    </el-table>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
    <PostContent ref="$postContent" />
  </ck-page>
</template>

<script lang="ts">
export default {
  meta: {
    path: '/posts',
    title: '文章列表',
    enTitle: 'Posts',
    auth: true,
    cache: true,
  },
  name: 'posts',
};
</script>
<script lang="ts" setup>
import { ref, reactive, onMounted, onActivated } from 'vue';
import PostContent from '@/components/PostContent.vue';
import ArticleApi from '@/apis/article';
import type { ArticleType } from '@/apis/article';
import { useCate } from '@/services/cate';
import { ElMessage } from 'element-plus';
import Utils from '@/utils';
import Bus from '@/utils/bus';
const { dateFormat } = Utils;
const $pagination = ref(null) as any;
const $postContent = ref(null) as any;
const loading = ref(false);
const params = reactive({
  view: -1,
  keyword: '',
  cateid: '-1',
  offset: 0,
  limit: 12,
});
const { selectList: cates, fetchList: fetchCateList } = useCate();
const list = ref([]);
const search = () => {
  $pagination.value.fetch(1);
};
const toEdit = (item: ArticleType) => {
  const { id } = item;
  Utils.goPage(`/editpost?id=${id}`);
};
const toComment = (item: ArticleType) => {
  const { id, title } = item;
  Utils.goPage(`/comment?postid=${id}&title=${title}`);
};
const toRead = (item: ArticleType) => {
  $postContent.value.init(item);
};
const toDel = (item: ArticleType) => {
  ArticleApi.del({ id: item.id })
    .then(() => {
      ElMessage.success('删除成功');
      $pagination.value.fetch();
      Bus.emit('updateMonent');
    })
    .catch((err: any) => {
      ElMessage.error(err.msg);
    });
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const res = await ArticleApi.list({
    offset: (index - 1) * size,
    limit: size,
    cateid: params.cateid,
    view: params.view,
    keyword: params.keyword,
  });
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
onActivated(() => {
  $pagination.value.fetch();
  fetchCateList();
});
</script>
<style lang="scss" scoped>
.posts-page {
  padding: 0 10px;
}
</style>
