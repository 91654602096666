<script setup lang="ts">
import { ref, nextTick } from 'vue';
import hl from 'highlight.js'; // 导入代码高亮文件
import 'highlight.js/styles/vs2015.css'; // 导入代码高亮样式
import { useLayoutStore } from '@/stores/layout';
import ArticleApi from '@/apis/article';
import type { ArticleType } from '@/apis/article';
const visible = ref(false);
const title = ref('文章');
const id = ref(0) as any;
const detail = ref({
  faceimg: '',
  subcon: '',
  content: '',
  view: '',
  weight: 0,
  catename: '',
});
const layoutStore = useLayoutStore();
const fetchData = async () => {
  const res = await ArticleApi.get({
    id: id.value,
  });
  detail.value = res;
  await nextTick();
  document.querySelectorAll('.html-content pre>code').forEach((el: Element) => {
    let lang = 'html';
    el.classList.forEach((className: string) => {
      const langClass = className.match(/^language-(\S+)$/) as any;
      lang = langClass.length > 0 ? langClass[1] : 'html';
    });
    hl.configure({
      languages: [lang],
    });
    hl.highlightElement(el as HTMLElement);
  });
};
const init = async (item: ArticleType) => {
  visible.value = true;
  if (item.id !== id.value) {
    id.value = item.id;
    title.value = item.title;
    fetchData();
  }
};
defineExpose({
  init,
});
</script>

<template>
  <el-dialog
    :width="layoutStore.isMiniScreen ? '100%' : '70%'"
    :title="title"
    v-model="visible"
  >
    <div v-if="detail">
      <p class="t">
        <span>浏览量：{{ detail.view }}</span>
        <span>分类：{{ detail.catename }}</span>
        <span>权重：{{ detail.weight }}</span>
      </p>
      <el-image
        v-if="detail.faceimg"
        class="cover"
        :src="detail.faceimg"
      ></el-image>
      <p v-if="detail.subcon" style="margin-bottom: 10px">
        简介：{{ detail.subcon }}
      </p>
      <div v-if="!detail.content" class="tips">空无内容</div>
      <div v-else class="html-content" v-html="detail.content"></div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.t {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    padding: 0 20px 0 0;
    color: #999;
  }
}
.tips {
  color: #999;
  text-align: center;
  padding: 10px;
}
.cover {
  max-width: 200px;
  margin: 10px auto;
}
</style>
