<template>
  <ck-page>
    <span @click="dialogVisible = true" class="tip-icon">
      <ck-icon class="icon-info"></ck-icon>说明
    </span>
    <p class="t-space-10"></p>
    <el-card class="ck-blur-card-bg">
      <template #header>加密</template>
      <div class="form-group">
        <el-input
          v-model="code"
          class="form-control"
          id="exampleInputPassword1"
          placeholder="输入短语"
        />
      </div>
      <p class="t-space-10"></p>
      <div class="card">
        <div class="card-block">
          <h4 class="card-title">结果</h4>
          <p class="card-text" v-html="parseCode"></p>
        </div>
      </div>
    </el-card>
    <p class="t-space-10"></p>
    <el-card class="ck-blur-card-bg">
      <template #header>解密</template>
      <div class="form-group">
        <el-input
          v-model="word"
          class="form-control"
          id="exampleInputPassword1"
          placeholder="输入摩斯密码"
        />
      </div>
      <div class="card">
        <div class="card-block">
          <p class="t-space-10"></p>
          <h4 class="card-title">结果</h4>
          <p class="card-text" v-html="parseWord"></p>
        </div>
      </div>
    </el-card>
    <p class="t-space-10"></p>
    <el-dialog title="提示" v-model="dialogVisible" width="50%">
      <div class="tips">
        <section
          class="tips-item"
          v-for="(item, index) in mosiArr"
          :key="index"
        >
          <b>{{ item.key }}</b>
          <p>{{ item.val }}</p>
        </section>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button round type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </ck-page>
</template>
<script lang="ts">
const mosiMap: any = {
  a: '.-',
  b: '-...',
  c: '-.-.',
  d: '-..',
  e: '.',
  f: '..-.',
  g: '--.',
  h: '....',
  i: '..',
  j: '.---',
  k: '-.-',
  l: '.-..',
  m: '--',
  n: '-.',
  o: '---',
  p: '.--.',
  q: '--.-',
  r: '.-.',
  s: '...',
  t: '-',
  u: '..-',
  v: '...-',
  w: '.--',
  x: '-..-',
  y: '-.--',
  z: '--..',
  0: '-----',
  1: '.----',
  2: '..---',
  3: '...--',
  4: '....-',
  5: '.....',
  6: '-....',
  7: '--...',
  8: '---..',
  9: '----.',
  '.': '.-.-.-',
  '?': '..--..',
  '!': '-.-.--',
  '(': '-.--.',
  '@': '.--.-.',
  ':': '---...',
  '=': '-...-',
  '-': '-....-',
  ')': '-.--.-',
  $: '...-..-',
  ',': '--..--',
  "'": '.----.',
  _: '..--.-',
  ';': '-.-.-.',
  '/': '-..-.',
  '"': '.-..-.',
  '&': '.-...',
};
const mosiMapR: any = {};
const mosiArr: any = [];
for (const prop in mosiMap) {
  mosiMapR[mosiMap[prop]] = prop;
  mosiArr.push({
    key: prop,
    val: mosiMap[prop],
  });
}
export default {
  name: 'mosi',
  meta: {
    path: '/tool_mosi',
    auth: false,
    cache: true,
    title: '摩斯密码翻译器',
    enTitle: 'Mosi Tool',
  },
  data() {
    return {
      dialogVisible: false,
      word: '',
      mosiArr,
      code: '',
    };
  },
  computed: {
    parseCode() {
      const codes = this.code.toLowerCase().split('');
      let parseCode = '';
      let parseCode2 = '';
      codes.forEach(function (el, index) {
        if (el === ' ') {
          parseCode += '&nbsp;&nbsp;';
          parseCode2 += '&nbsp;&nbsp;';
        } else {
          parseCode2 +=
            typeof mosiMap[el] !== 'undefined' ? mosiMap[el] + '&nbsp;' : '?';
          parseCode +=
            typeof mosiMap[el] !== 'undefined'
              ? `<div style="margin:5px;padding:5px;text-align:center; border:1px solid #999;"><div style="color: var(--el-color-success)">${el}</div><div>${mosiMap[el]}</div></div>`
              : '?';
        }
      });
      return (
        '<b>' +
        parseCode2 +
        '</b><section class="t-flex" style="flex-wrap:wrap;align-items:center;">' +
        parseCode +
        '</section>'
      );
    },
    parseWord() {
      const codes = this.word.toLowerCase().split(' ');
      let parseCode = '';
      codes.forEach(function (el, index) {
        if (el === '') {
          parseCode += '&nbsp;&nbsp;';
        } else {
          parseCode +=
            typeof mosiMapR[el] !== 'undefined'
              ? mosiMapR[el] + '&nbsp;'
              : '🙅🏻‍♀️';
        }
      });
      return parseCode;
    },
  },
};
</script>
<style lang="scss" scoped>
.tip-icon {
  cursor: pointer;
  font-size: 14px;
}
.card-text {
  word-break: break-word;
  font-size: 20px;
}
.tips {
  display: flex;
  flex-wrap: wrap;
}
.tips-item {
  padding: 5px;
  font-size: 18px;
  border: 1px solid #999;
  align-items: center;
  text-align: center;
  b {
    display: block;
    padding: 0 5px;
    font-weight: bold;
    color: var(--el-color-success);
  }
}
</style>
