<template>
  <div class="user-info">
    <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="userInfo"
      label-width="80px"
      show-message
      status-icon
    >
      <el-form-item prop="account" label="账号">
        <el-input
          disabled
          style="max-width: 300px"
          v-model="userInfo.account"
        />
      </el-form-item>
      <el-form-item prop="intro" label="角色">
        <el-input style="max-width: 100px" disabled v-model="userInfo.intro" />
      </el-form-item>
      <el-form-item prop="account" label="ID">
        <el-input disabled style="max-width: 300px" v-model="userInfo.id" />
        <ck-icon
          title="复制"
          style="margin-left: 10px"
          class="icon-finish t-pointer"
          v-copy="userInfo.id"
        ></ck-icon>
      </el-form-item>
      <el-form-item prop="name" label="昵称">
        <el-input
          style="max-width: 300px"
          placeholder="请填写昵称"
          v-model="userInfo.name"
        />
      </el-form-item>
      <el-form-item v-if="!isEdit" prop="psw" label="初始密码">
        <el-input v-model="psw" />
      </el-form-item>
      <el-form-item label="头像" prop="avatar">
        <el-avatar
          shape="circle"
          size="large"
          fit="cover"
          :src="userInfo.avatar"
        ></el-avatar>
        <el-button link @click="openImgModal('avatar')">更换</el-button>
      </el-form-item>
      <!-- <el-form-item label="背景" prop="bg">
        <div @click="openImgModal('bg')" class="t-upload-img-cell">
          <template v-if="userInfo.bg">
            <div class="upload-img-btns">
              <ck-icon
                @click.stop="delBg"
                class="icon-delete t-pointer"
                title="删除背景"
                color="#fff"
                size="30"
              ></ck-icon>
            </div>
            <el-image fit="contain" :src="userInfo.bg">
              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </div>
      </el-form-item> -->
      <el-form-item v-if="!mine" prop="type" label="权限值">
        <el-input number v-model="userInfo.type" />
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input style="width: 100px" type="number" v-model="userInfo.age" />
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="userInfo.sex" placeholder="请选择">
          <el-option
            v-for="item in sexs"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button round type="success" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      append-to-body
      width="80%"
      v-model="materialDialogVisible"
      title="素材库"
    >
      <Material @choose="onSelectedImg" />
    </el-dialog>
    <!-- <upload-modal
      @selected="selectedImg"
      ref="uploadModal"
      class="upload-img"
    /> -->
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, type Ref, onMounted, computed } from 'vue';
import Utils from '@/utils';
import Material from '@/components/Material/index.vue';
import { useLayoutStore } from '@/stores/layout';
import { ElMessage } from 'element-plus';
import UserApi, { initUserItem, type UserType } from '@/apis/user';
import ResourceApi from '@/apis/resource';
import type { MaterialType } from '@/apis/material';
import type { FormInstance, FormRules } from 'element-plus';

export interface Props {
  mine?: boolean;
  item?: UserType;
}
const emit = defineEmits(['submit']);
const ruleFormRef = ref<FormInstance | null>(null);
const rules = reactive<FormRules>({
  name: [{ required: true, message: '请填入昵称', trigger: 'blur' }],
  type: [{ required: true, message: '请填入权限值', trigger: 'blur' }],
});
const layoutStore = useLayoutStore();
const $tree = ref(null) as any;
const props = withDefaults(defineProps<Props>(), {
  mine: false,
  item: Utils.clone(initUserItem),
});

const materialDialogVisible = ref(false);
const openImgType = ref('avatar');
const psw = ref('d12345'); //初始密码;
const sexs = ['男', '女', '未知'];
const treeProps = {
  children: 'children',
  label: 'title',
};
const isEdit = computed(() => !!userInfo.value.id);
const userInfo: Ref<UserType> = ref(Utils.clone(initUserItem));
const apis: Ref<{ name: string; val: string }[]> = ref([]);
const apisOk: Ref<string[]> = ref([]);
const apisNot: Ref<string[]> = ref([]);
const checkAllApisNot = ref(false);
watch(
  () => props.item,
  () => {
    setUserInfo();
  }
);
onMounted(async () => {
  try {
    const res = await ResourceApi.frontGet({ code: 'api-list' });
    apis.value = JSON.parse(res.data);
  } catch (error) {}
  setUserInfo();
});
const handleCheckAllChange = (val: boolean) => {
  checkAllApisNot.value = val;
  if (val) {
    apisNot.value = apis.value.map((item) => item.val);
  } else {
    apisNot.value = [];
  }
};
const handleCheckedChange = () => {
  checkAllApisNot.value = apisNot.value.length >= apis.value.length;
};
const setUserInfo = () => {
  ruleFormRef.value?.clearValidate();
  const newVal = Utils.clone(props.item);
  try {
    newVal.menus = JSON.parse(newVal.menus);
  } catch (e) {
    newVal.menus = [];
  }
  const keys: any = [];
  function getKey(items: any) {
    items.map((item: any): void => {
      if (item.hasOwnProperty('children')) {
        getKey(item.children);
      } else {
        item.hasOwnProperty('id') && keys.push(item.id);
      }
    });
  }
  userInfo.value = newVal;
  getKey(newVal.menus);
  // $tree.value.setCheckedKeys([]);
  $tree.value && $tree.value.setCheckedKeys(keys);
  setApis();
};
const setApis = () => {
  handleCheckAllChange(userInfo.value.notapis === '1');
  if (userInfo.value.apis) {
    apisOk.value = `${userInfo.value.apis}`.split(';');
  } else {
    apisOk.value = [];
  }
  if (userInfo.value.notapis !== '1' && !!userInfo.value.notapis) {
    apisNot.value = `${userInfo.value.notapis}`.split(';');
  }
};
const onSubmit = async () => {
  const submitData: UserType = {
    ...userInfo.value,
  };

  if (props.mine) {
    delete submitData.menus;
    delete submitData.type;
    delete submitData.updatetime;
  } else {
    if (!submitData.name || !submitData.type) {
      return ElMessage.warning('请完整信息');
    }
  }
  if (isEdit.value) {
    let res;
    if (props.mine) {
      res = await UserApi.edit(submitData);
    } else {
      res = await UserApi.update(submitData);
    }
    if (res) {
      ElMessage.success('更新成功');
      emit('submit');
    }
  } else {
    submitData.password = psw.value;
    const res = await UserApi.add(submitData);
    if (res) {
      ElMessage.success('添加成功');
      emit('submit');
    }
  }
};

const openImgModal = (type = 'bg') => {
  openImgType.value = type;
  materialDialogVisible.value = true;
};
const onSelectedImg = (item: MaterialType) => {
  materialDialogVisible.value = false;
  if (openImgType.value === 'avatar') {
    userInfo.value.avatar = Utils.getQnImg(item.link, ['thumbnail/300x']);
  } else {
    userInfo.value.bg = item.link;
  }
};
const delBg = () => {
  userInfo.value.bg = '';
};
</script>
<style lang="scss" scoped>
.user-info .el-form-item__label {
  font-weight: bold;
}
</style>
