//cate因为用的地方比较多，故抽出来一个函数式导出文件
import { ref } from 'vue';
import type { Ref } from 'vue';
import CateApi from '@/apis/cate';
import { ElMessage } from 'element-plus';
import type { CateType } from '@/apis/cate';
export function useCate() {
  const list: Ref<CateType[]> = ref([]);
  const selectList: Ref<CateType[]> = ref([]);
  const isLoadingList = ref(false);
  const fetchList = function () {
    isLoadingList.value = true;
    CateApi.get()
      .then((res: any) => {
        list.value = res;
        selectList.value = [{ id: '-1', name: '全部' }].concat(res);
      })
      .finally(() => {
        isLoadingList.value = false;
      });
  };
  const remove = function (item: { id: number | string }) {
    return new Promise(async (resolve, reject) => {
      if (!item.id) {
        return reject({ msg: 'without id' });
      }
      const res = await CateApi.del(item).catch(reject);
      resolve(res);
    });
  };
  const update = function (item: { id: number | string }) {
    return new Promise(async (resolve, reject) => {
      if (!item.id) {
        return reject({ msg: 'without id' });
      }
      const res = await CateApi.edit(item).catch(reject);
      resolve(res);
    });
  };
  const insert = function (item: { name: string }) {
    return new Promise(async (resolve, reject) => {
      if (!item.name) {
        reject();
        return ElMessage('请填入name');
      }
      const res = await CateApi.add(item).catch(reject);
      resolve(res);
    });
  };
  return {
    list,
    selectList,
    isLoadingList,
    fetchList,
    remove,
    update,
    insert,
  };
}
