<template>
  <main>
    <ck-water-mark :text="userStore.userName" v-if="waterMark" />
    <div class="ck-page">
      <header class="ck-page-header" v-if="$slots.header || header">
        <slot name="header"></slot>
      </header>
      <template v-if="!errMsg">
        <slot>现在请开始编写你的页面吧</slot>
      </template>
      <main v-else class="ck-page-err">
        {{ errMsg }}
        <el-image
          class="ck-page-err-img"
          src="https://cdn.zcxnb.cn/cloud/2022/11/09/6czGQKwR_undraw_bug_fixing_oc-7-a.svg"
        />
      </main>
      <footer class="ck-page-footer t-footer" v-if="$slots.footer || footer">
        <slot name="footer">
          <p>Copyright©2022-至今 qtcat.cn All rights reserved.</p>
          <p>最新构建时间: {{ publishTime }}</p>
          <p>
            <a href="https://icp.gov.moe/?keyword=20230257" target="_blank"
              >萌ICP备20230257号</a
            >
          </p>
        </slot>
      </footer>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { useUserStore } from '@/stores/user';
import pkgJson from 'root/package.json';
import Utils from '@/utils';
export default defineComponent({
  setup() {
    return {
      publishTime: Utils.dateFormat(
        window.PUBLISH_TIME,
        'yyyy年MM月dd日 hh:mm:ss'
      ),
      version: pkgJson.version || '0.0.0',
      userStore: useUserStore() as any,
    };
  },
  props: {
    errMsg: {
      type: String,
      default: '',
    },
    waterMark: {
      //是否需要水印
      type: Boolean,
      default: false,
    },
    footer: {
      //自动填充默认底部
      type: Boolean,
      default: true,
    },
    header: {
      //自动填充默认顶部
      type: Boolean,
      default: false,
    },
  },
  name: 'ck-page',
  mounted() {
    if (this.$route?.meta?.auth) {
      if (!this.userStore.isLogin) {
        this.$notify({
          showClose: true,
          type: 'warning',
          message: '请先登录',
        });
        return Utils.goLogin(true);
      }
    }
  },
});
</script>

<style lang="scss">
.ck-page {
  position: relative;
  height: 100%;
  .el-card {
    --el-card-border-color: transparent;
  }
}
.ck-page-err {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  padding: 50px 0 30px 0;
  &-img {
    width: 60%;
    margin-top: 20px;
  }
}
</style>
