<script lang="ts">
export default {
  meta: {
    path: '/geolite',
    title: 'geolite数据库管理',
    enTitle: 'GeoLite',
    auth: true,
    cache: false,
  },
  name: 'geolite',
};
</script>
<script lang="ts" setup>
import { ElMessage } from 'element-plus';
import Utils from '@/utils';
import { CookieEnum } from '@/contants';

const token = Utils.cookies.get(CookieEnum.token);
let controller: AbortController | null = null;
let evtSource: EventSource | null = null;
let host = `${import.meta.env.VITE_APP_API_HOST}sse/geolite`;
// let scrollToTimer: any = null;
const scrollToEnd = () => {
  const sseOutputCell: any = document.getElementById('sseOutputCell');
  const sseOutput: any = document.getElementById('sseOutput');
  sseOutputCell.scrollTop = sseOutput?.offsetHeight - 10;
  // if (scrollToTimer) {
  //   clearTimeout(scrollToTimer);
  // } else {
  //   scrollToTimer = setTimeout(() => {
  //     const sseOutputCell: any = document.getElementById('sseOutputCell');
  //     const sseOutput: any = document.getElementById('sseOutput');
  //     sseOutputCell.scrollTop = sseOutput?.offsetHeight - 20;
  //   }, 2);
  // }
};
const update2 = () => {
  ElMessage.info('开始更新，请留意输出信息');
  host += `?token=${token}`;
  const sseOutput: any = document.getElementById('sseOutput');
  evtSource = new EventSource(host, { withCredentials: false });
  evtSource.onopen = () => {
    // console.log('Connection opened');
    sseOutput.innerHTML = 'Connection opened\n';
  };

  evtSource.addEventListener('process', (event) => {
    const eventData = JSON.parse(event.data);
    // console.log('Received event:', eventData);
    sseOutput?.appendChild(
      document.createTextNode(`${eventData.time}:${eventData.msg}\n`)
    );
    if (eventData.status !== '200') {
      evtSource?.close();
    }
    // 在这里处理接收到的事件，更新页面等操作
  });

  evtSource.onerror = function (error) {
    sseOutput?.appendChild(document.createTextNode(`source is closed\n`));
    scrollToEnd();
    evtSource?.close();
  };
};

const update = () => {
  ElMessage.info('开始更新，请留意输出信息');
  //使用fetch请求
  controller = new AbortController();
  const sseOutput: any = document.getElementById('sseOutput');
  const request = new Request(host, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  });

  fetchEventSource(request, {
    onopen() {
      // console.log('Connection opened');
      sseOutput.innerHTML = 'Connection opened\n';
    },
    onclose() {
      // console.log('Connection close\n');
      sseOutput?.appendChild(document.createTextNode(`Connection is closed\n`));
      scrollToEnd();
    },
    onmessage(e: any) {
      sseOutput?.appendChild(document.createTextNode(`${e}`));
      scrollToEnd();
    },
    error(e: any) {
      console.log('error', e);
      closeFetch();
    },
  });
};
function closeFetch() {
  if (controller) {
    controller.abort();
    controller = null;
    console.log('fetch closed');
  }
}
function fetchEventSource(req: Request, options: any) {
  return fetch(req)
    .then((res: Response) => {
      console.log(res);
      if (res.status !== 200) {
        options.onclose && options.onclose();
        return null;
      }
      if (res.status === 200) {
        options.onopen && options.onopen();
        return res.body;
      }
    })
    .then((rb: any) => {
      const reader = rb.getReader();
      const push = () => {
        // done 为数据流是否接收完成，boolean
        // value 为返回数据，Uint8Array
        return reader.read().then((res: any) => {
          const { done, value } = res;
          if (done) {
            options.onclose && options.onclose();
            return;
          }
          options.onmessage &&
            options.onmessage(new TextDecoder().decode(value));
          // 持续读取流信息
          return push();
        });
      };
      // 开始读取流信息
      return push();
    })
    .catch((e) => {
      options.error && options.error(e);
    });
}
function stopEvent() {
  closeFetch();
  evtSource?.close();
}
</script>

<template>
  <ck-page class="page" footer header>
    <el-button style="margin-top: 10px" @click="update" type="primary"
      >更新GEO数据库(Fetch)</el-button
    >
    <el-button style="margin-top: 10px" @click="update2" type="success"
      >更新GEO数据库(EventSource)</el-button
    >
    <el-button style="margin-top: 10px" @click="stopEvent" type="danger"
      >停止(EventSource)</el-button
    >
    <section style="margin-top: 20px">
      <pre id="sseOutputCell">
        <code id="sseOutput"></code>
      </pre>
      <ul id="list"></ul>
    </section>
  </ck-page>
</template>

<style lang="scss" scoped>
#sseOutputCell {
  border-radius: 5px;
  width: 100%;
  overflow: auto;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  height: 300px;
}
.page {
  max-width: 600px;
  margin: 0 auto;
}
.link {
  padding: 10px 0;
  word-break: break-all;
}
</style>
