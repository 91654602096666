<template>
  <ck-page class="iot-page">
    <div class="list t-pointer">
      <el-form :inline="true" @submit.prevent>
        <el-form-item>
          <el-input
            @keyup.enter="getIpInfo"
            v-model="sip"
            placeholder="请输入IP地址"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getIpInfo">查询</el-button>
        </el-form-item>
      </el-form>
      <el-descriptions
        :title="isSelf ? '您的IP信息' : '查询的IP信息'"
        direction="vertical"
        :column="2"
        size="large"
        :border="true"
      >
        <el-descriptions-item label="IP">{{ ip }}</el-descriptions-item>
        <el-descriptions-item label="位置">{{ area }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/ip',
    title: '我的IP地址',
    enTitle: 'My Ip',
    auth: false,
    cache: true,
  },
  name: 'myIp',
};
</script>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { getMyIp } from '@/apis/sys';
const ip = ref('');
const sip = ref('');
const area = ref('');
const isSelf = ref(true);
const getIpInfo = async () => {
  const res = await getMyIp({ ip: sip.value });
  if (sip.value !== '') {
    isSelf.value = false;
  }
  ip.value = res.ip;
  area.value = res.area;
};
onMounted(() => {
  getIpInfo();
});
</script>

<style lang="scss" scoped>
.list {
  padding-top: 30px;
  max-width: 500px;
}
@media (max-width: theme('screens.md')) {
  .list {
    justify-content: center;
  }
}
.image {
  max-width: 100%;
}
</style>
