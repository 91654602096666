<template>
  <div class="user-info">
    <el-form
      ref="ruleFormRef"
      :model="item"
      label-width="80px"
      show-message
      status-icon
    >
      <el-form-item v-if="!mine" prop="type" label="权限值">
        <el-input disabled number v-model="roleInfo.type" />
      </el-form-item>
      <el-form-item v-if="!mine" prop="type" label="说明">
        <el-input disabled number v-model="roleInfo.intro" />
      </el-form-item>
      <el-form-item v-if="!mine" props="menu" label="菜单">
        <el-tree
          :data="layoutStore.asideMenu"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="$tree"
          highlight-current
          :props="treeProps"
        >
        </el-tree>
      </el-form-item>
      <template v-if="!mine">
        <el-form-item label="可访问路径">
          <p style="font-size: 12px; opacity: 0.7">
            支持正则，以‘;’分隔，空表示任何路径都可访问
          </p>
          <el-input
            type="textarea"
            :rows="5"
            v-model="roleInfo.okpages"
          ></el-input>
        </el-form-item>
        <el-form-item label="限制api">
          <el-checkbox v-model="checkAllApisNot" @change="handleCheckAllChange"
            >限制全部</el-checkbox
          >
          <el-checkbox-group v-model="apisNot">
            <el-checkbox
              @change="handleCheckedChange"
              v-for="api in apis"
              :key="api.val"
              :label="api.val"
            >
              <span :title="api.val">{{ api.name }}</span>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-show="apisNot.length || checkAllApisNot"
          label="可访问api"
        >
          <el-checkbox-group v-model="apisOk">
            <el-checkbox v-for="api in apis" :key="api.val" :label="api.val">
              <span :title="api.val">{{ api.name }}</span>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button round type="success" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, type Ref, onMounted, computed } from 'vue';
import Utils from '@/utils';
import { useLayoutStore } from '@/stores/layout';
import { ElMessage } from 'element-plus';
import RoleApi, { initRoleItem } from '@/apis/role';
import type { RoleType } from '@/apis/role';
import ResourceApi from '@/apis/resource';

export interface Props {
  mine?: boolean;
  item?: RoleType;
}
const emit = defineEmits(['submit']);
const layoutStore = useLayoutStore();
const $tree = ref(null) as any;
const props = withDefaults(defineProps<Props>(), {
  mine: false,
  item: Utils.clone(initRoleItem),
});

const treeProps = {
  children: 'children',
  label: 'title',
};
const isEdit = computed(() => !!roleInfo.value.id);
const roleInfo: Ref<RoleType> = ref(Utils.clone(initRoleItem));
const apis: Ref<{ name: string; val: string }[]> = ref([]);
const apisOk: Ref<string[]> = ref([]);
const apisNot: Ref<string[]> = ref([]);
const checkAllApisNot = ref(false);
watch(
  () => props.item,
  () => {
    setRoleInfo();
  }
);
onMounted(async () => {
  try {
    const res = await ResourceApi.frontGet({ code: 'api-list' });
    apis.value = JSON.parse(res.data);
  } catch (error) {}
  setRoleInfo();
});
const handleCheckAllChange = (val: boolean) => {
  checkAllApisNot.value = val;
  if (val) {
    apisNot.value = apis.value.map((item) => item.val);
  } else {
    apisNot.value = [];
  }
};
const handleCheckedChange = () => {
  checkAllApisNot.value = apisNot.value.length >= apis.value.length;
};
const setRoleInfo = () => {
  const newVal = Utils.clone(props.item);
  try {
    newVal.menus = JSON.parse(newVal.menus);
  } catch (e) {
    newVal.menus = [];
  }
  const keys: any = [];
  function getKey(items: any) {
    items.map((item: any): void => {
      if (item.hasOwnProperty('children')) {
        getKey(item.children);
      } else {
        item.hasOwnProperty('id') && keys.push(item.id);
      }
    });
  }
  roleInfo.value = newVal;
  getKey(newVal.menus);
  // $tree.value.setCheckedKeys([]);
  $tree.value && $tree.value.setCheckedKeys(keys);
  setApis();
};
const setApis = () => {
  handleCheckAllChange(roleInfo.value.notapis === '1');
  if (roleInfo.value.apis) {
    apisOk.value = `${roleInfo.value.apis}`.split(';');
  } else {
    apisOk.value = [];
  }
  if (roleInfo.value.notapis !== '1' && !!roleInfo.value.notapis) {
    apisNot.value = `${roleInfo.value.notapis}`.split(';');
  }
};
const getSelectedMenus = () => {
  // 解析需要传给后台的菜单字段
  const selectedKeys = $tree.value.getCheckedKeys();
  console.warn('selectedKeys', selectedKeys);
  const selectedMenus = [] as any;
  function scanTreeMenus(items: any, parent = null) {
    items.map((item: any) => {
      if (item.hasOwnProperty('children')) {
        scanTreeMenus(item.children, item);
      } else {
        if (item.hasOwnProperty('id') && selectedKeys.includes(item.id)) {
          if (parent) {
            const parentCopy: any = Utils.clone(parent);
            parentCopy.children = [];
            if (!selectedMenus.length) {
              // 如果只有children被选中，那就赋予parent
              selectedMenus.push(parentCopy);
            }
            let lastMenu = selectedMenus[selectedMenus.length - 1];
            if (lastMenu.id !== parentCopy.id) {
              selectedMenus.push(parentCopy);
              lastMenu = selectedMenus[selectedMenus.length - 1]; // lastMenu重新赋值
            }
            lastMenu.hasOwnProperty('children')
              ? lastMenu.children.push(item)
              : (lastMenu.children = [item]);
          } else {
            selectedMenus.push(item);
          }
        }
      }
    });
  }
  scanTreeMenus(layoutStore.asideMenu);
  return JSON.stringify(selectedMenus);
};
const onSubmit = async () => {
  const submitData: RoleType = {
    ...roleInfo.value,
  };

  if (props.mine) {
    delete submitData.menus;
    delete submitData.type;
    delete submitData.updatetime;
    delete submitData.okpages;
    delete submitData.apis;
    delete submitData.notapis;
  } else {
    submitData.menus = getSelectedMenus();
    submitData.notapis =
      apisNot.value.length === apis.value.length
        ? '1'
        : apisNot.value.join(';');
    submitData.apis = apisOk.value.join(';');
  }
  if (isEdit.value) {
    const res = await RoleApi.update(submitData);
    if (res) {
      ElMessage.success('更新成功');
      emit('submit');
    }
  }
};
</script>
<style lang="scss" scoped>
.user-info .el-form-item__label {
  font-weight: bold;
}
</style>
