/**
 * 用户信息
 * */
import { defineStore } from 'pinia';
import Utils from '@/utils';
import { CookieEnum } from '@/contants';
import { getUserConf, loginOut } from '@/apis/sys';
import { useLayoutStore } from '@/stores/layout';
const defaultUser = {
  id: '-1',
  userName: `游客_${Utils.randStr(4)}`,
  okpages: '',
  bg: '',
  account: '',
  isFetch: false,
  token: '',
  avatar: 'https://cravatar.cn/avatar/',
};
type StateType = {
  account?: string;
  id?: string | number;
  token?: string;
  okpages?: string | null;
  bg?: string;
  userName: string;
  avatar: string;
  isFetch?: boolean;
};
export const useUserStore = defineStore({
  id: 'user',
  state(): StateType {
    let initialState = JSON.parse(JSON.stringify(defaultUser));
    try {
      if (Utils.cookies.get(CookieEnum.userInfo)) {
        initialState = JSON.parse(
          Utils.cookies.get(CookieEnum.userInfo) || JSON.stringify(initialState)
        );
      }
    } catch (error) {}
    return Object.assign({}, defaultUser, initialState, {
      token: Utils.cookies.get(CookieEnum.token) || '',
    });
  },
  getters: {
    okPages: (state) => state.okpages,
    isLogin: (state) => !!state.token,
  },
  actions: {
    async loginOut(
      cb = () => {
        return false;
      } //退出后的回调
    ) {
      await loginOut();
      Utils.cookies.remove(CookieEnum.token);
      this.token = '';
      this.setUser(defaultUser);
      Utils.storage.clearStorage();
      cb && cb();
    },
    updateToken() {
      this.token = Utils.cookies.get(CookieEnum.token) || '';
    },
    async fetchUser(): Promise<any> {
      this.updateToken();
      if (this.isLogin) {
        let res;
        try {
          res = await getUserConf();
        } catch (err) {
          return null;
        }
        // console.warn('fetchUser', res);
        this.setUser({
          account: res.account,
          bg: res.bg,
          id: res.id,
          okpages: res.okpages,
          userName: res.name,
          avatar: res.avatar,
          isFetch: true,
        });
        const layoutStore = useLayoutStore();
        layoutStore.setAsideMenu(JSON.parse(res.menus));
        if (res.bg && document.querySelector('#layout')) {
          (
            document.querySelector('#layout') as any
          ).style.backgroundImage = `url(${res.bg})`;
        }
        return res;
      }
      return null;
    },
    setUser(val: StateType): void {
      const {
        userName = '',
        avatar = '',
        id = '-1',
        bg = '',
        okpages = '',
        account = '',
        isFetch = false,
      } = val;
      this.userName = userName;
      this.avatar = avatar;
      this.id = id;
      this.okpages = okpages;
      this.bg = bg;
      this.isFetch = isFetch;
      this.account = account;
      Utils.cookies.set(
        CookieEnum.userInfo,
        JSON.stringify({ userName, avatar, id }),
        { expires: 10 }
      );
    },
    setToken(val: string | undefined): void {
      this.token = val || '';
      Utils.cookies.set(CookieEnum.token, val || '', { expires: 10 });
    },
  },
});
