<template>
  <el-dialog title="分享" v-model="dialogVisible" width="350px">
    <el-form>
      <el-form-item label="链接">
        {{ shareLink
        }}<ck-icon
          class="icon-publish cursor-pointer ml-[5px]"
          v-copy="shareLink"
        />
      </el-form-item>
    </el-form>
    <div
      ref="$posterCanvas"
      id="poster-canvas"
      class="w-[300px] mx-auto mb-[10px] shadow-md"
    ></div>
    <div class="flex justify-center">
      <el-button type="primary" @click="downloadPoster">下载海报</el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ref, nextTick } from 'vue';
import Utils from '@/utils';
import Konva from 'konva';
import QRCode from 'qrcode';
import type { ArticleType } from '@/apis/article';
defineOptions({
  name: 'Share',
});
const dialogVisible = ref(false);
export interface Props {
  post: ArticleType;
}
const shareLink = ref('');
let stage: any = null;
const $posterCanvas = ref(null) as any;
const init = async () => {
  dialogVisible.value = true;
  shareLink.value = `${location.href}`;
  await nextTick();
  setPoster();
};
const downloadPoster = () => {
  Utils.saveFile(
    stage.toDataURL({
      mimeType: 'image/png',
      quality: 1,
      x: 0,
      y: 0,
      width: 300,
      height: 400,
      pixelRatio: 2,
    }),
    `poster_${Utils.randStr(5)}.png`
  );
};
const setPoster = () => {
  stage = new Konva.Stage({
    container: 'poster-canvas',
    width: 300,
    height: 400,
  });
  const layer = new Konva.Layer();
  const imageObj = new Image();
  layer.add(
    new Konva.Rect({
      x: 0,
      y: 0,
      width: 300,
      height: 400,
      fill: '#fff',
    })
  );
  imageObj.onload = function () {
    // console.warn('imageObj', imageObj.width, imageObj.height);
    const h = 300 * (imageObj.height / imageObj.width);
    const image = new Konva.Image({
      x: 0,
      y: 0,
      image: imageObj,
      width: 300,
      height: h,
    });
    layer.add(image);
    layer.add(
      new Konva.Rect({
        x: 0,
        y: 280,
        width: 300,
        height: 120,
        fill: '#fff',
      })
    );
    const titleText = new Konva.Text({
      x: 0,
      y: 280,
      text: props.post.title,
      fontSize: 18,
      lineHeight: 1.2,
      fill: '#f70',
      fontStyle: 'bold',
      width: 190,
      padding: 10,
      align: 'left',
    });
    layer.add(titleText);
    const subConText = new Konva.Text({
      x: 0,
      y: 325,
      text: props.post.subcon,
      fontSize: 13,
      lineHeight: 1.5,
      fill: '#999',
      width: 200,
      padding: 10,
      align: 'left',
    });
    layer.add(subConText);
    QRCode.toDataURL(shareLink.value) //添加链接二维码
      .then((img) => {
        const qrcodeImage = new Image();
        qrcodeImage.src = img;
        qrcodeImage.onload = function () {
          const qrcode = new Konva.Image({
            x: 200,
            y: 280,
            image: qrcodeImage,
            width: 100,
            height: 100,
          });
          layer.add(qrcode);
          const tipText = new Konva.Text({
            x: 200,
            y: 370,
            text: '扫码查看内容',
            fontSize: 10,
            lineHeight: 1.2,
            fill: '#aaa',
            fontStyle: 'bold',
            width: 100,
            padding: 5,
            align: 'center',
          });
          layer.add(tipText);
        };
      })
      .catch((err) => {
        console.error(err);
      });
  };
  imageObj.crossOrigin = 'anonymous';
  imageObj.src =
    props.post.faceimg || 'https://cdn.zcxnb.cn/upload/Z0vv66FmeD.png';
  stage.add(layer);
};
const props = defineProps<Props>();
defineExpose({
  init,
});
</script>

<style lang="scss" scoped></style>
