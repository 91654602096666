<template>
  <section class="item">
    <div class="image-wrap" v-if="item" :title="item.link">
      <div class="t-upload-img-cell el-image" v-if="isOfficeFile">
        <div class="t-image-slot" @click="openOfficeFile">
          <CkIcon size="25" class="icon-publish" />
        </div>
      </div>
      <div class="t-upload-img-cell el-image" v-else-if="!isImage">
        <div class="t-image-slot">
          <div class="t-image-video" v-if="isVideo" @click="openVideo">
            <video :src="item.link"></video>
          </div>
          <div class="t-image-slot" v-else @click="openLink">
            <CkIcon size="25" class="icon-doc" />
          </div>
        </div>
      </div>
      <el-image
        v-else
        fit="contain"
        :preview-src-list="preview ? [item.link] : []"
        @click="choose"
        :src="Utils.getQnImg(item.link, ['thumbnail/300x'])"
        class="t-upload-img-cell"
      >
        <template #error>
          <div class="t-image-slot" @click="openLink">
            <CkIcon size="25" class="icon-warn" />
          </div>
        </template>
      </el-image>
    </div>
    <p v-if="!edit" class="name" :title="item.name">{{ item.name }}</p>
    <el-input
      @keyup.enter="toEdit"
      @blur="updateName"
      style="margin: 5px 0"
      v-else
      v-model="itemName"
    ></el-input>
    <p>{{ item.size }}</p>
    <p>
      <span
        title="复制"
        copy-success-tip="图片链接复制成功"
        v-copy="item.link"
        class="operate-btn copy-btn"
      >
        <CkIcon class="icon-publish" size="16" />
      </span>
      <template v-if="editMode">
        <span @click="toEdit" title="编辑" class="operate-btn edit-btn">
          <CkIcon class="icon-setting" size="16" />
        </span>
        <span @click="deleteItem" title="删除" class="operate-btn">
          <CkIcon class="icon-delete" size="16" />
        </span>
        <span
          v-if="isVideo && !preview"
          @click="() => (videoVisible = true)"
          title="预览视频"
          class="operate-btn"
        >
          <CkIcon class="icon-view" size="16" />
        </span>
      </template>
    </p>
    <el-dialog
      v-if="isVideo"
      append-to-body
      v-model="videoVisible"
      :title="`视频预览:${item.name}`"
    >
      <video
        style="width: 100%; max-height: 70vh"
        v-if="videoVisible"
        controls
        :src="item.link"
      ></video>
    </el-dialog>
  </section>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import Utils from '@/utils';
export default defineComponent({
  props: {
    item: null,
    editMode: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    item(val) {
      this.itemName = val.name;
    },
  },
  setup() {
    return {
      edit: ref(false),
      videoVisible: ref(false),
      itemName: ref(''),
      Utils,
    };
  },
  computed: {
    isImage() {
      return [
        'jpeg',
        'jpg',
        'png',
        'apng',
        'svg',
        'webp',
        'heic',
        'bmp',
        'gif',
        'ico',
        'tif',
        'tiff',
      ].includes(this.item.link.split('.').pop().toLowerCase());
    },
    isOfficeFile() {
      return ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'].includes(
        this.item.link.split('.').pop().toLowerCase()
      );
    },
    isVideo() {
      const videoSuffix = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'rmvb', '3gp'];
      return videoSuffix.includes(
        this.item.link.split('.').pop().toLowerCase()
      );
    },
  },
  mounted() {
    this.itemName = this.item.name;
  },
  methods: {
    openVideo() {
      if (this.preview) {
        this.videoVisible = true;
      } else {
        this.choose();
      }
    },
    updateName() {
      this.edit = false;
      if (!this.itemName || this.itemName === this.item.name) {
        this.itemName = this.item.name;
        return;
      }
      this.$emit('update', { name: this.itemName, id: this.item.id });
    },
    toEdit() {
      this.edit = !this.edit;
    },
    deleteItem() {
      this.$emit('delete', this.item);
    },
    choose() {
      !this.preview && this.$emit('choose', this.item);
    },
    openLink() {
      this.preview ? window.open(this.item.link) : this.choose();
    },
    openOfficeFile() {
      this.preview
        ? window.open(
            `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
              this.item.link
            )}`
          )
        : this.choose();
    },
  },
});
</script>
<style lang="scss" scoped>
.item {
  margin: 0 0 10px 0;
  font-size: 12px;
  color: #777;
  text-align: center;
  p {
    width: 100px;
    @include clamp(2);
  }
  .operate-btn {
    cursor: pointer;
    margin: 0 5px;
  }
  .name {
    height: 40px;
    margin-top: 10px;
  }
}
.image-wrap {
  position: relative;
  width: 100px;
  &:hover {
    .image-wh {
      opacity: 1;
    }
  }
  .image-wh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
}
</style>
