import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import LayoutIndex from '@/layout/LayoutIndex.vue';

async function setRouter() {
  const generateRoutes: any = [];
  //批量导入vue组件，懒人操作
  const AllPages = import.meta.glob(['../pages/**/*-page.vue'], {
    eager: true,
  });
  // const Pages = import.meta.glob(['../pages/**/*-page.vue']);
  // console.warn('Pages', AllPages);
  for (const path in AllPages) {
    const res: any = AllPages[path];
    const module = res.default;
    generateRoutes.push({
      path: module.meta.path,
      meta: { ...module.meta },
      name: module.name,
      component: module,
    });
  }
  const routerMode = () => {
    if (import.meta.env.MODE === 'development') {
      return createWebHashHistory('/');
    }
    return createWebHistory('/');
  };
  const router = createRouter({
    history: routerMode(),
    routes: [
      {
        path: '/',
        name: 'index',
        component: LayoutIndex,
        children: [
          {
            path: '/changePsw',
            name: 'changePsw',
            meta: {
              title: '修改密码',
              auth: true, //是否要登录访问，默认false
            },
            component: () => import('../views/ResetPsw.vue'),
          },
        ].concat(generateRoutes),
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          title: '登录',
          top: true,
        },
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        meta: {
          title: '注册',
          top: true,
        },
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Register.vue'),
      },
      {
        path: '/resetPsw',
        name: 'resetPsw',
        meta: {
          title: '重置密码',
          top: true,
        },
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/ResetPsw.vue'),
      },
      {
        path: '/mycar',
        name: 'mycar',
        meta: {
          title: '我的小车车',
          auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/MyCar.vue'),
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          title: '关于',
        },
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/AboutView.vue'),
      },
      {
        path: '/emoji_venture',
        name: 'emojiVenture',
        meta: {
          title: 'emoji冒险记',
          auth: true, //是否要登录访问，默认false
        },
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/EmojiVenture/GameView.vue'),
      },
      {
        path: '/:w+',
        component: () => import('../views/NotFound.vue'),
      },
    ],
  });
  return router;
}
export default setRouter;
