<template>
  <ck-page>
    <el-input
      clearable
      style="width: 200px; margin-right: 10px"
      v-model="params.keyword"
      placeholder="标题"
      @keyup.enter="search"
    ></el-input>
    <el-button round type="primary" @click="search">查询</el-button>
    <el-table
      center
      v-loading="loading"
      :data="list"
      style="width: 100%; margin: 10px 0"
    >
      <el-table-column label="id" prop="id" width="50px"></el-table-column>
      <el-table-column
        label="排序值"
        prop="weight"
        width="70px"
      ></el-table-column>
      <el-table-column label="可见性" prop="visible" width="70px">
        <template v-slot="scope">
          {{ scope.row.visible === 1 ? '可见' : '不可见' }}
        </template>
      </el-table-column>
      <el-table-column label="封面">
        <template v-slot="scope">
          <el-image
            v-if="scope.row.cover"
            :src="scope.row.cover"
            :preview-src-list="[scope.row.cover]"
            style="width: 100px; height: 100px"
            fit="contain"
          >
          </el-image>
          <div v-else style="padding: 10px">
            <ck-icon size="30" class="icon-warn"></ck-icon>无封面
          </div>
        </template>
      </el-table-column>
      <el-table-column label="标题">
        <template v-slot="scope">
          <div>
            {{ decodeURIComponent(scope.row.name) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button size="small" @click="toEdit(scope.row)">编辑</el-button>
          <el-popconfirm
            v-if="scope.row.id > 0"
            @confirm="toDel(scope.row)"
            title="确定删除该类别?"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-button round block class="btn" type="success" @click="toAdd"
      >新增</el-button
    >
    <el-dialog
      width="350px"
      v-model="editVisible"
      :title="nowItem && nowItem.id ? '编辑' : '新增'"
    >
      <div class="content">
        <el-form :inline="true">
          <el-form-item label="名称">
            <el-input v-model="nowItem.name"></el-input>
          </el-form-item>
          <el-form-item label="排序值">
            <el-input v-model="nowItem.weight"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button round @click="editVisible = false">取消</el-button>
          <el-button round type="primary" @click="saveChange">保存</el-button>
        </div>
      </template>
    </el-dialog>
    <CkPagination :size="10" ref="$pagination" :api-handler="fetchList" />
  </ck-page>
</template>
<script lang="ts">
export default {
  meta: {
    path: '/wallpaper/cate',
    title: '壁纸分类',
    enTitle: 'Wallpaper Cate',
    auth: true,
    cache: true,
  },
  name: 'wallpaperCate',
};
</script>
<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import type { CateType } from '@/apis/wallpaper';
import WallpaperApi from '@/apis/wallpaper';
import Utils from '@/utils';

const loading = ref(false);
const editVisible = ref(false);
const nowItem = ref<any>(null);
const list = ref<any[]>([]);
const params = reactive({
  keyword: '',
});
const $pagination = ref(null) as any;
function toEdit(item: CateType): void {
  editVisible.value = true;
  nowItem.value = Utils.clone(item);
}
function toDel(item: CateType): void {
  WallpaperApi.cateDel(item)
    .then(() => {
      ElMessage.success('删除成功');
      search();
    })
    .catch((err) => {
      ElMessage.error(err.msg);
    });
}
function toAdd(): void {
  nowItem.value = { name: '', weight: 1 };
  editVisible.value = true;
}
const search = () => {
  $pagination.value.fetch(1);
};
const fetchList = async (index = 1, size = 20) => {
  loading.value = true;
  const searchParams: any = {
    offset: (index - 1) * size,
    limit: size,
  };
  if (params.keyword) {
    searchParams.keyword = params.keyword;
  }
  const res: any = await WallpaperApi.cateList(searchParams);
  list.value = res.list;
  loading.value = false;
  return {
    ...res,
  };
};
const editCate = function (item: { id: number | string }) {
  return new Promise(async (resolve, reject) => {
    if (!item.id) {
      return reject({ msg: 'without id' });
    }
    const res = await WallpaperApi.cateEdit(item).catch(reject);
    resolve(res);
  });
};
const insertCate = function (item: { name: string }) {
  return new Promise(async (resolve, reject) => {
    if (!item.name) {
      reject();
      return ElMessage('请填入name');
    }
    const res = await WallpaperApi.cateAdd(item).catch(reject);
    resolve(res);
  });
};
function saveChange() {
  let saveFunc: any = editCate;
  if (!nowItem.value.hasOwnProperty('id')) {
    saveFunc = insertCate;
  }
  saveFunc(nowItem.value)
    .then(() => {
      ElMessage.success('保存成功');
      editVisible.value = false;
      search();
    })
    .catch((err: any) => {
      ElMessage.error(err.msg);
    });
}
onMounted(() => {
  search();
});
</script>
