import { ref, type Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import TopicApi from '@/apis/topic';
import Utils from '@/utils';
import Bridge from './bridge';
import { ElMessage } from 'element-plus';
import {
  initTopicConf,
  topicMap,
  topicCateMap,
  type TopicLayoutConfType,
} from './conf';

export function useTopic(page_id?: string | number | undefined) {
  const $route = useRoute();
  const loading = ref(false);
  const topicLayoutList: Ref<TopicLayoutConfType[]> = ref([]);
  const topicConf = ref(Utils.clone(initTopicConf));
  const fetchTopicData = async () => {
    const res = await TopicApi.get({ pageid: page_id || $route.query.pageid });
    if (typeof res.config === 'string') {
      res.config = JSON.parse(res.config);
    }
    const topicLayoutListRes = JSON.parse(res.layout[0]?.content || '[]') || [];
    const topicConfRes = Object.assign(Utils.clone(initTopicConf), res.config);
    topicLayoutListRes.map((item: TopicLayoutConfType): void => {
      // 赋予默认值
      item.content = Object.assign(
        {},
        topicMap[item.name].content,
        item.content
      );
    });
    topicLayoutList.value = topicLayoutListRes;
    topicConf.value = topicConfRes;
    // Bridge.post({ key: 'topicList', value: topicLayoutListRes });
  };
  const saveTopic = async () => {
    // 保存专题布局
    loading.value = true;
    const data: any = {};
    const pageid = page_id || $route.query.pageid;
    if (pageid) {
      data.pageid = pageid;
    }
    data.config = JSON.stringify(topicConf.value);
    // data.modifier = this.$store.getters.userInfo.name
    data.content = JSON.stringify(topicLayoutList.value);
    try {
      await TopicApi.edit(data);
      ElMessage.success('保存成功');
    } catch (err) {
      // ElMessage.error('保存失败');
      console.warn('保存失败', err);
    }
    loading.value = false;
    // data.version=this.topicData.version
  };
  watch(
    topicConf,
    () => {
      Bridge.post({ key: 'topicConf', value: Utils.clone2(topicConf.value) });
    },
    { deep: true }
  );
  watch(
    topicLayoutList,
    () => {
      Bridge.post({
        key: 'topicList',
        value: Utils.clone2(topicLayoutList.value),
      });
    },
    { deep: true }
  );
  return {
    topicConf,
    loading,
    topicMap,
    topicCateMap,
    topicLayoutList,
    Bridge,
    saveTopic,
    fetchTopicData,
  };
}
