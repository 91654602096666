<script setup lang="ts">
import { ref, shallowRef, onUnmounted, computed } from 'vue';
import '@wangeditor/editor/dist/css/style.css';
import { Boot } from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import Material from '@/components/Material/index.vue';
import type { MaterialType } from '@/apis/material';
import type { NewIDomEditor } from './RichEditorConf';
import {
  codeSourceConf,
  materailConf,
  toolbarConfig,
  editorConfig,
} from './RichEditorConf';
const props = defineProps<{
  modelValue: string;
  height?: number;
}>();

const emit = defineEmits(['update:modelValue']);
const editorRef = shallowRef();
const strValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
const codeSourceDialogVisible = ref(false);
const materialDialogVisible = ref(false);
const onEditorCreated = (editor: NewIDomEditor) => {
  editor.onOpenCodeSource = () => {
    codeSourceDialogVisible.value = true;
  };
  editor.onOpenMaterial = () => {
    materialDialogVisible.value = true;
  };
  editorRef.value = editor;
  const allMenus = editor.getAllMenuKeys();
  if (allMenus.indexOf(codeSourceConf.key) < 0) {
    Boot.registerMenu(codeSourceConf as any);
  }
  if (allMenus.indexOf(materailConf.key) < 0) {
    Boot.registerMenu(materailConf as any);
  }
};
const onChooseMaterial = (item: MaterialType) => {
  materialDialogVisible.value = false;
  editorRef.value.dangerouslyInsertHtml(
    `<img class="material-pic" src="${item.link}" title="${item.name}" data-href="${item.link}" alt="${item.name}"/>`
  );
};
onUnmounted(() => {
  editorRef.value.destroy();
});
</script>
<template>
  <section class="ck-editor">
    <div>
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        mode="default"
      />
      <Editor
        :style="{ height: height || 500 + 'px', 'overflow-y': 'hidden' }"
        v-model="strValue"
        :defaultConfig="editorConfig"
        mode="default"
        @onCreated="onEditorCreated"
      />
    </div>
    <el-dialog width="80%" v-model="codeSourceDialogVisible" title="源内容">
      <el-input type="textarea" :rows="15" v-model="strValue" />
    </el-dialog>
    <el-dialog width="80%" v-model="materialDialogVisible" title="素材库">
      <Material @choose="onChooseMaterial" />
    </el-dialog>
  </section>
</template>

<style lang="scss">
.ck-editor {
  border: 1px solid #ddd;
}
.dark {
  --w-e-toolbar-disabled-color: #aaa;
  --w-e-toolbar-bg-color: #444;
  --w-e-toolbar-border-color: #444;
  --w-e-toolbar-color: #ddd;
  --w-e-toolbar-active-bg-color: #777;
  --w-e-textarea-color: #ddd;
  --w-e-textarea-bg-color: #555;
  --w-e-modal-button-bg-color: #777;
  --w-e-textarea-slight-bg-color: #666;
  .w-e-menu-tooltip-v5 {
    --w-e-toolbar-bg-color: #fff;
  }
}
</style>
