<template>
  <div class="material-page">
    <h4>{{ title }}</h4>
    <div class="t-space-10"></div>
    <el-upload
      class="upload-btn"
      :data="uploadOpts"
      :action="uploadUrl"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleFailure"
    >
      <el-button round type="primary">上传</el-button>
      <template #tip>
        <div class="el-upload__tip">
          一次只能上传一个文件，且不超过50MB
          <el-popover
            placement="top-start"
            title="可上传的文件后缀"
            :width="200"
            trigger="hover"
            :content="suffixWhitelist.join('、')"
          >
            <template #reference>
              <ck-icon class="icon-info t-pointer" />
            </template>
          </el-popover>
        </div>
      </template>
    </el-upload>
    <p style="margin-top: 0" class="el-upload__tip">
      通过剪贴板上传（仅支持图片），点击下面区域，并把文件黏贴
    </p>
    <section class="paste-area" ref="pasteArea" contenteditable></section>
    <el-form :inline="true" @submit.prevent>
      <el-form-item label-width="60px" label="关键字">
        <el-input @keyup.enter="() => search()" clearable v-model="keyword">
          <template #append
            ><el-button type="primary" @click="() => search()"
              >搜索</el-button
            ></template
          >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-card
      :body-style="{ padding: '15px' }"
      shadow="never"
      class="box-card"
      v-if="loading || list.length > 0"
    >
      <div class="item-warp" v-loading="loading">
        <div class="item" v-for="(item, index) in list" :key="index">
          <ImageItem
            editMode
            @update="updateName"
            @delete="deleteItem"
            :preview="preview"
            :item="item"
            @choose="choose(item)"
          />
        </div>
      </div>
    </el-card>
    <div class="t-tips" v-else>无相应素材,赶紧上传几张图片试试</div>
    <CkPagination :mini="mini" ref="pagination" :api-handler="fetchList" />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';
import MaterialApi from '@/apis/material';
import ImageItem from './ImageItem.vue';
import Utils from '@/utils';
import { CookieEnum } from '@/contants';
import type { MaterialType } from '@/apis/material';

let $pagination: any;
export default defineComponent({
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '素材库',
    },
  },
  components: {
    ImageItem,
  },
  setup() {
    const uploadUrl = `${import.meta.env.VITE_APP_API_HOST}ajax/qnupload`;
    const uploadOpts = {
      token: Utils.cookies.get(CookieEnum.token) || '',
    };
    const list: Array<MaterialType> = [];
    return {
      uploadUrl,
      form: reactive({
        key: '',
      }),
      // uploadLoading: ref(false),
      keyword: ref(''),
      list: ref(list),
      loading: ref(false),
      suffixWhitelist: ref([]),
      uploadOpts: reactive(uploadOpts),
    };
  },
  mounted() {
    // this.fetchQiniuToken();
    $pagination = this.$refs['pagination'] as any;
    $pagination.fetch(1);
    this.watchPasted();
  },
  methods: {
    watchPasted() {
      console.log('pasted');
      (this.$refs['pasteArea'] as any).addEventListener(
        'paste',
        (e: ClipboardEvent) => {
          if (!e.clipboardData) {
            return;
          }
          const items = e.clipboardData.items;
          let file: any = null;
          if (items && items.length > 0) {
            for (let i = 0; i < items.length; i++) {
              const item = items[i];
              if (item.type.indexOf('image') !== -1) {
                file = item.getAsFile();
                break;
              }
            }
          }
          if (!file) {
            this.$message.error('请黏贴图片');
            return;
          }
          if (this.beforeUpload(file)) {
            const $el: any = this.$refs['pasteArea'];
            this.loading = true;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('token', this.uploadOpts['token']);
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this.uploadUrl);
            xhr.send(formData);
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                this.$message.info('上传成功');
                this.loading = false;
                setTimeout(() => ($el.innerHTML = ''), 1000);
                const res = JSON.parse(xhr.response);
                this.handleSuccess(res, file);
              }
            };
          }
        }
      );
    },
    reset() {
      this.keyword = '';
      this.search();
    },
    search(index = 1) {
      $pagination.fetch(index);
    },
    async updateName(item: { id: string | number; name: string }) {
      await MaterialApi.update(item);
      this.$message.success('更新成功！');
      $pagination.fetch();
    },
    choose(item: MaterialType) {
      this.$emit('choose', item);
    },
    async deleteItem(item: MaterialType) {
      await MaterialApi.delete(item);
      this.$message.success('删除成功！');
      $pagination.fetch();
    },
    beforeUpload(file: File) {
      if (!this.uploadOpts.token) {
        this.$message.error('请稍后再试，正在初始化');
        return false;
      }
      // console.warn('beforeUpload', file);
      const limitSize = file.size / 1024 / 1024 < 50; // 最大上传100M
      if (!limitSize) {
        this.$message.error('不得上传超过50M的文件');
        return false;
      }
      return true;
    },
    async handleSuccess(res: any, file: File) {
      if (res.rcode !== 200) {
        this.$message.error('上传失败', res.msg);
        return;
      }
      await MaterialApi.add({
        size: Utils.byteConvert(file.size),
        name: file.name,
        link: `${
          Utils.testUrl(res.data.url)
            ? ''
            : import.meta.env.VITE_APP_UPLOAD_PREFIX
        }${res.data.url}`,
      });
      $pagination.fetch(1);
    },
    async handleFailure(error: any) {
      console.log('error', error.status);
      this.$message.error('上传失败,status:' + error.status);
    },
    async fetchList(index = 0, size = 0): Promise<any> {
      this.loading = true;
      const res = await MaterialApi.get({
        offset: (index - 1) * size,
        limit: size,
        keyword: this.keyword,
      });
      this.list = res.list;
      this.suffixWhitelist = res.suffixWhitelist;
      this.loading = false;
      return {
        ...res,
      };
    },
  },
});
</script>
<style lang="scss" scoped>
.item-warp {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 10px;
  .upload-img-cell {
    margin-bottom: 5px;
  }
}
</style>
<style lang="scss">
.paste-area {
  padding: 10px;
  border: 1px solid #ccc;
  max-width: 600px;
  margin-bottom: 10px;
  img {
    display: inline-block;
    max-width: 100%;
  }
}
</style>
