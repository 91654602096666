export default {
  message: {
    hello: '你好, 世界',
  },
  copySuccess: '复制成功',
  default: '默认',
  noImage: '无图',
  close: '关闭',
  clear: '清空',
  changePsw: '修改密码',
  login: '登录',
  logout: '退出登录',
  about: '关于',
  confirm: '确认',
  confirmToLogout: '确定退出登录？',
  cancel: '取消',
  header: {
    toHome: '跳到首页',
    closeOther: '关闭其他',
    toLight: '切换到浅色模式',
    toDark: '切换到暗黑模式',
    checkLog: '查看调试日志',
    home: '首页',
    delCache: '清除系统本地存储',
    delCacheConfirm: '删除本地存储并重载当前页面?',
    theme: '主题',
    fullScreen: '全屏',
    exitFullScreen: '退出全屏',
  },
};
