<template>
  <section class="ck-load-more">
    <el-button v-if="!loadAll" @click="fetchNext" class="ck-load-more-btn">{{
      loading ? props.loadingTip : props.tip
    }}</el-button>
    <el-button v-else class="ck-load-more-btn" link>{{
      props.noMoreTip
    }}</el-button>
  </section>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
export interface Props {
  loadingTip?: string;
  apiHandler?: any;
  size?: number;
  noMoreTip?: string;
  tip?: string;
}

const loading = ref(false);
let page = 1;
// const total = ref(0);
const loadAll = ref(false);

const props = withDefaults(defineProps<Props>(), {
  tip: '加载更多',
  noMoreTip: '加载完毕',
  size: 10,
  apiHandler: async () => null,
  loadingTip: '正在加载...',
});
const fetch = async (idx = 1) => {
  //idx参数表示指定第几页，0：拿pages的
  if (idx) {
    page = idx;
  }
  // const { index, pageSize } = this;
  const res = await props.apiHandler(page, props.size);
  loadAll.value = res.list.length < props.size;
  // total.value = res.total;
};
const fetchNext = () => {
  page++;
  fetch(page);
};
defineExpose({
  fetch,
});
</script>
<style lang="scss" scoped>
.ck-load-more {
  padding: 10px;
  width: 100%;
  &-btn {
    display: block;
    width: 100%;
  }
}
</style>
